import Web3 from 'web3'

/*
* 1. Check for injected web3 (mist/metamask)
* 2. If metamask/mist create a new web3 instance and pass on result
* 3. Get networkId - Now we can check the user is connected to the right network to use our dApp
* 4. Get user account from metamask
* 5. Get user balance
*/

let getWeb3 = new Promise(function (resolve, reject) {
    // Check for injected web3 (mist/metamask)
    var web3js = window.web3
    console.log(1)
    if (typeof web3js !== 'undefined') {
        var web3 = new Web3(web3js.currentProvider)
        // web3.eth.net.isListening()
        //     .then(() => console.log('is connected'))
        //     .catch(e => console.log('Wow. Something went wrong: '+ e));
        resolve({
            web3 () {
                return web3
            }
        })
    } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        reject(new Error('Unable to connect to Metamask'))
    }
})
    .then(result => {
        return new Promise(function (resolve, reject) {
            // Retrieve network ID
            result.web3().eth.getAccounts((err, coinbase) => {
                if (err) {
                    // If we can't find a networkId keep result the same and reject the promise
                    reject(new Error('Unable to retrieve network ID'))
                } else {
                    // Assign the networkId property to our result and resolve promise
                    result = Object.assign({}, result, {coinbase})
                    resolve(result)
                }

            })
        })
    })
    .then(result => {
        return new Promise(function (resolve, reject) {
            // Retrieve network ID]
            result.web3().eth.getBalance(result.coinbase[0], (err, balance) => {
                if (err) {
                    reject(new Error('Unable to retrieve balance for address: ' + result.coinbase))
                } else {
                    const formatCash = n => {
                        if (n < 1e3) return n;
                        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                        if (n >= 1e18) return +(n / 1e18).toFixed(2);
                    };
                    // let amt = new BN(1e18, response)
                    // console.log(amt,BigInt(response))


                    // let decimals = web3.utils.toBN(18);
                    // let amount = web3.utils.toBN(obj.value);


                    const balanceWeiString = balance
                    const balanceWeiBN = result.web3().utils.toBN(balanceWeiString)

                    const decimalsBN = result.web3().utils.toBN(18)
                    const divisor =  result.web3().utils.toBN(10).pow(decimalsBN)

                    const beforeDecimal = balanceWeiBN.div(divisor)
                    const afterDecimal  = balanceWeiBN.mod(divisor)


                    var balance = beforeDecimal.toString()+"."+afterDecimal.toString().slice(0, 4);

                    result = Object.assign({}, result, {balance})
                    resolve(result)
                }
            })
        })
    })

export default getWeb3
