import {
  cib500px5,
  cib500px,
  cibAboutMe,
  cibAbstract,
  cibAcm,
  cibAddthis,
  cibAdguard,
  cibAdobeAcrobatReader,
  cibAdobeAfterEffects,
  cibAdobeAudition,
  cibAdobeCreativeCloud,
  cibAdobeDreamweaver,
  cibAdobeIllustrator,
  cibAdobeIndesign,
  cibAdobeLightroomClassic,
  cibAdobeLightroom,
  cibAdobePhotoshop,
  cibAdobePremiere,
  cibAdobeTypekit,
  cibAdobeXd,
  cibAdobe,
  cibAirbnb,
  cibAlgolia,
  cibAlipay,
  cibAllocine,
  cibAmazonAws,
  cibAmazonPay,
  cibAmazon,
  cibAmd,
  cibAmericanExpress,
  cibAnaconda,
  cibAnalogue,
  cibAndroidAlt,
  cibAndroid,
  cibAngellist,
  cibAngularUniversal,
  cibAngular,
  cibAnsible,
  cibApacheAirflow,
  cibApacheFlink,
  cibApacheSpark,
  cibApache,
  cibAppStoreIos,
  cibAppStore,
  cibAppleMusic,
  cibApplePay,
  cibApplePodcasts,
  cibApple,
  cibAppveyor,
  cibAral,
  cibArchLinux,
  cibArchiveOfOurOwn,
  cibArduino,
  cibArtstation,
  cibArxiv,
  cibAsana,
  cibAtAndT,
  cibAtlassian,
  cibAtom,
  cibAudible,
  cibAurelia,
  cibAuth0,
  cibAutomatic,
  cibAutotask,
  cibAventrix,
  cibAzureArtifacts,
  cibAzureDevops,
  cibAzurePipelines,
  cibBabel,
  cibBaidu,
  cibBamboo,
  cibBancontact,
  cibBandcamp,
  cibBasecamp,
  cibBathasu,
  cibBehance,
  cibBigCartel,
  cibBing,
  cibBit,
  cibBitbucket,
  cibBitcoin,
  cibBitdefender,
  cibBitly,
  cibBlackberry,
  cibBlender,
  cibBloggerB,
  cibBlogger,
  cibBluetoothB,
  cibBluetooth,
  cibBoeing,
  cibBoost,
  cibBootstrap,
  cibBower,
  cibBrandAi,
  cibBrave,
  cibBtc,
  cibBuddy,
  cibBuffer,
  cibBuyMeACoffee,
  cibBuysellads,
  cibBuzzfeed,
  cibC,
  cibCakephp,
  cibCampaignMonitor,
  cibCanva,
  cibCashapp,
  cibCassandra,
  cibCastro,
  cibCcAmazonPay,
  cibCcAmex,
  cibCcApplePay,
  cibCcDinersClub,
  cibCcDiscover,
  cibCcJcb,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cibCentos,
  cibCevo,
  cibChase,
  cibChef,
  cibChromecast,
  cibCircle,
  cibCircleci,
  cibCirrusci,
  cibCisco,
  cibCivicrm,
  cibClockify,
  cibClojure,
  cibCloudbees,
  cibCloudflare,
  cibCmake,
  cibCoOp,
  cibCodacy,
  cibCodeClimate,
  cibCodecademy,
  cibCodecov,
  cibCodeigniter,
  cibCodepen,
  cibCoderwall,
  cibCodesandbox,
  cibCodeship,
  cibCodewars,
  cibCodio,
  cibCoffeescript,
  cibCommonWorkflowLanguage,
  cibComposer,
  cibCondaForge,
  cibConekta,
  cibConfluence,
  cibCoreuiC,
  cibCoreui,
  cibCoursera,
  cibCoveralls,
  cibCpanel,
  cibCplusplus,
  cibCreativeCommonsBy,
  cibCreativeCommonsNcEu,
  cibCreativeCommonsNcJp,
  cibCreativeCommonsNc,
  cibCreativeCommonsNd,
  cibCreativeCommonsPdAlt,
  cibCreativeCommonsPd,
  cibCreativeCommonsRemix,
  cibCreativeCommonsSa,
  cibCreativeCommonsSamplingPlus,
  cibCreativeCommonsSampling,
  cibCreativeCommonsShare,
  cibCreativeCommonsZero,
  cibCreativeCommons,
  cibCrunchbase,
  cibCrunchyroll,
  cibCss3Shiled,
  cibCss3,
  cibCsswizardry,
  cibD3Js,
  cibDailymotion,
  cibDashlane,
  cibDazn,
  cibDblp,
  cibDebian,
  cibDeepin,
  cibDeezer,
  cibDelicious,
  cibDell,
  cibDeno,
  cibDependabot,
  cibDesignerNews,
  cibDevTo,
  cibDeviantart,
  cibDevrant,
  cibDiaspora,
  cibDigg,
  cibDigitalOcean,
  cibDiscord,
  cibDiscourse,
  cibDiscover,
  cibDisqus,
  cibDisroot,
  cibDjango,
  cibDocker,
  cibDocusign,
  cibDotNet,
  cibDraugiemLv,
  cibDribbble,
  cibDrone,
  cibDropbox,
  cibDrupal,
  cibDtube,
  cibDuckduckgo,
  cibDynatrace,
  cibEbay,
  cibEclipseide,
  cibElasticCloud,
  cibElasticSearch,
  cibElasticStack,
  cibElastic,
  cibElectron,
  cibElementary,
  cibEleventy,
  cibEllo,
  cibElsevier,
  cibEmlakjet,
  cibEmpirekred,
  cibEnvato,
  cibEpicGames,
  cibEpson,
  cibEsea,
  cibEslint,
  cibEthereum,
  cibEtsy,
  cibEventStore,
  cibEventbrite,
  cibEvernote,
  cibEverplaces,
  cibEvry,
  cibExercism,
  cibExpertsExchange,
  cibExpo,
  cibEyeem,
  cibFSecure,
  cibFacebookF,
  cibFacebook,
  cibFaceit,
  cibFandango,
  cibFavro,
  cibFeathub,
  cibFedex,
  cibFedora,
  cibFeedly,
  cibFidoAlliance,
  cibFigma,
  cibFilezilla,
  cibFirebase,
  cibFitbit,
  cibFlask,
  cibFlattr,
  cibFlickr,
  cibFlipboard,
  cibFlutter,
  cibFnac,
  cibFoursquare,
  cibFramer,
  cibFreebsd,
  cibFreecodecamp,
  cibFurAffinity,
  cibFurryNetwork,
  cibGarmin,
  cibGatsby,
  cibGauges,
  cibGenius,
  cibGentoo,
  cibGeocaching,
  cibGerrit,
  cibGg,
  cibGhost,
  cibGimp,
  cibGit,
  cibGitea,
  cibGithub,
  cibGitkraken,
  cibGitlab,
  cibGitpod,
  cibGitter,
  cibGlassdoor,
  cibGlitch,
  cibGmail,
  cibGnuPrivacyGuard,
  cibGnuSocial,
  cibGnu,
  cibGo,
  cibGodotEngine,
  cibGogCom,
  cibGoldenline,
  cibGoodreads,
  cibGoogleAds,
  cibGoogleAllo,
  cibGoogleAnalytics,
  cibGoogleChrome,
  cibGoogleCloud,
  cibGoogleKeep,
  cibGooglePay,
  cibGooglePlay,
  cibGooglePodcasts,
  cibGoogle,
  cibGooglesCholar,
  cibGovUk,
  cibGradle,
  cibGrafana,
  cibGraphcool,
  cibGraphql,
  cibGrav,
  cibGravatar,
  cibGreenkeeper,
  cibGreensock,
  cibGroovy,
  cibGroupon,
  cibGrunt,
  cibGulp,
  cibGumroad,
  cibGumtree,
  cibHabr,
  cibHackaday,
  cibHackerearth,
  cibHackerone,
  cibHackerrank,
  cibHackhands,
  cibHackster,
  cibHappycow,
  cibHashnode,
  cibHaskell,
  cibHatenaBookmark,
  cibHaxe,
  cibHelm,
  cibHere,
  cibHeroku,
  cibHexo,
  cibHighly,
  cibHipchat,
  cibHitachi,
  cibHockeyapp,
  cibHomify,
  cibHootsuite,
  cibHotjar,
  cibHouzz,
  cibHp,
  cibHtml5Shield,
  cibHtml5,
  cibHtmlacademy,
  cibHuawei,
  cibHubspot,
  cibHulu,
  cibHumbleBundle,
  cibIata,
  cibIbm,
  cibIcloud,
  cibIconjar,
  cibIcq,
  cibIdeal,
  cibIfixit,
  cibImdb,
  cibIndeed,
  cibInkscape,
  cibInstacart,
  cibInstagram,
  cibInstapaper,
  cibIntel,
  cibIntellijidea,
  cibIntercom,
  cibInternetExplorer,
  cibInvision,
  cibIonic,
  cibIssuu,
  cibItchIo,
  cibJabber,
  cibJava,
  cibJavascript,
  cibJekyll,
  cibJenkins,
  cibJest,
  cibJet,
  cibJetbrains,
  cibJira,
  cibJoomla,
  cibJquery,
  cibJs,
  cibJsdelivr,
  cibJsfiddle,
  cibJson,
  cibJupyter,
  cibJustgiving,
  cibKaggle,
  cibKaios,
  cibKaspersky,
  cibKentico,
  cibKeras,
  cibKeybase,
  cibKeycdn,
  cibKhanAcademy,
  cibKibana,
  cibKickstarter,
  cibKik,
  cibKirby,
  cibKlout,
  cibKnown,
  cibKoFi,
  cibKodi,
  cibKoding,
  cibKotlin,
  cibKrita,
  cibKubernetes,
  cibLanyrd,
  cibLaravelHorizon,
  cibLaravelNova,
  cibLaravel,
  cibLastFm,
  cibLatex,
  cibLaunchpad,
  cibLeetcode,
  cibLenovo,
  cibLess,
  cibLetsEncrypt,
  cibLetterboxd,
  cibLgtm,
  cibLiberapay,
  cibLibrarything,
  cibLibreoffice,
  cibLine,
  cibLinkedinIn,
  cibLinkedin,
  cibLinuxFoundation,
  cibLinuxMint,
  cibLinux,
  cibLivejournal,
  cibLivestream,
  cibLogstash,
  cibLua,
  cibLumen,
  cibLyft,
  cibMacys,
  cibMagento,
  cibMagisk,
  cibMailRu,
  cibMailchimp,
  cibMakerbot,
  cibManjaro,
  cibMarkdown,
  cibMarketo,
  cibMastercard,
  cibMastodon,
  cibMaterialDesign,
  cibMathworks,
  cibMatrix,
  cibMattermost,
  cibMatternet,
  cibMaxcdn,
  cibMcafee,
  cibMediaTemple,
  cibMediafire,
  cibMediumM,
  cibMedium,
  cibMeetup,
  cibMega,
  cibMendeley,
  cibMessenger,
  cibMeteor,
  cibMicroBlog,
  cibMicrogenetics,
  cibMicrosoftEdge,
  cibMicrosoft,
  cibMinetest,
  cibMinutemailer,
  cibMix,
  cibMixcloud,
  cibMixer,
  cibMojang,
  cibMonero,
  cibMongodb,
  cibMonkeytie,
  cibMonogram,
  cibMonzo,
  cibMoo,
  cibMozillaFirefox,
  cibMozilla,
  cibMusescore,
  cibMxlinux,
  cibMyspace,
  cibMysql,
  cibNativescript,
  cibNec,
  cibNeo4j,
  cibNetflix,
  cibNetlify,
  cibNextJs,
  cibNextcloud,
  cibNextdoor,
  cibNginx,
  cibNim,
  cibNintendo3ds,
  cibNintendoGamecube,
  cibNintendoSwitch,
  cibNintendo,
  cibNodeJs,
  cibNodeRed,
  cibNodemon,
  cibNokia,
  cibNotion,
  cibNpm,
  cibNucleo,
  cibNuget,
  cibNuxtJs,
  cibNvidia,
  cibOcaml,
  cibOctave,
  cibOctopusDeploy,
  cibOculus,
  cibOdnoklassniki,
  cibOpenAccess,
  cibOpenCollective,
  cibOpenId,
  cibOpenSourceInitiative,
  cibOpenstreetmap,
  cibOpensuse,
  cibOpenvpn,
  cibOpera,
  cibOpsgenie,
  cibOracleNetsuite,
  cibOracle,
  cibOrcid,
  cibOrigin,
  cibOsi,
  cibOsmc,
  cibOvercast,
  cibOverleaf,
  cibOvh,
  cibPagekit,
  cibPalantir,
  cibPandora,
  cibPantheon,
  cibPatreon,
  cibPaypal,
  cibPeriscope,
  cibPhp,
  cibPicartoTv,
  cibPinboard,
  cibPingdom,
  cibPingup,
  cibPinterestP,
  cibPinterest,
  cibPivotaltracker,
  cibPlangrid,
  cibPlayerMe,
  cibPlayerfm,
  cibPlaystation,
  cibPlaystation3,
  cibPlaystation4,
  cibPlesk,
  cibPlex,
  cibPluralsight,
  cibPlurk,
  cibPocket,
  cibPostgresql,
  cibPostman,
  cibPostwoman,
  cibPowershell,
  cibPrettier,
  cibPrismic,
  cibProbot,
  cibProcesswire,
  cibProductHunt,
  cibProtoIo,
  cibProtonmail,
  cibProxmox,
  cibPypi,
  cibPython,
  cibPytorch,
  cibQgis,
  cibQiita,
  cibQq,
  cibQualcomm,
  cibQuantcast,
  cibQuantopian,
  cibQuarkus,
  cibQuora,
  cibQwiklabs,
  cibQzone,
  cibR,
  cibRadiopublic,
  cibRails,
  cibRaspberryPi,
  cibReact,
  cibReadTheDocs,
  cibReadme,
  cibRealm,
  cibReason,
  cibRedbubble,
  cibRedditAlt,
  cibReddit,
  cibRedhat,
  cibRedis,
  cibRedux,
  cibRenren,
  cibReverbnation,
  cibRiot,
  cibRipple,
  cibRiseup,
  cibRollupJs,
  cibRoots,
  cibRoundcube,
  cibRss,
  cibRstudio,
  cibRuby,
  cibRubygems,
  cibRunkeeper,
  cibRust,
  cibSafari,
  cibSahibinden,
  cibSalesforce,
  cibSaltstack,
  cibSamsungPay,
  cibSamsung,
  cibSap,
  cibSassAlt,
  cibSass,
  cibSaucelabs,
  cibScala,
  cibScaleway,
  cibScribd,
  cibScrutinizerci,
  cibSeagate,
  cibSega,
  cibSellfy,
  cibSemaphoreci,
  cibSensu,
  cibSentry,
  cibServerFault,
  cibShazam,
  cibShell,
  cibShopify,
  cibShowpad,
  cibSiemens,
  cibSignal,
  cibSinaWeibo,
  cibSitepoint,
  cibSketch,
  cibSkillshare,
  cibSkyliner,
  cibSkype,
  cibSlack,
  cibSlashdot,
  cibSlickpic,
  cibSlides,
  cibSlideshare,
  cibSmashingmagazine,
  cibSnapchat,
  cibSnapcraft,
  cibSnyk,
  cibSociety6,
  cibSocketIo,
  cibSogou,
  cibSolus,
  cibSongkick,
  cibSonos,
  cibSoundcloud,
  cibSourceforge,
  cibSourcegraph,
  cibSpacemacs,
  cibSpacex,
  cibSparkfun,
  cibSparkpost,
  cibSpdx,
  cibSpeakerDeck,
  cibSpectrum,
  cibSpotify,
  cibSpotlight,
  cibSpreaker,
  cibSpring,
  cibSprint,
  cibSquarespace,
  cibStackbit,
  cibStackexchange,
  // cibStackoverflow,
  cibStackpath,
  cibStackshare,
  cibStadia,
  cibStatamic,
  cibStaticman,
  cibStatuspage,
  cibSteam,
  cibSteem,
  cibSteemit,
  cibStitcher,
  cibStorify,
  cibStorybook,
  cibStrapi,
  cibStrava,
  cibStripeS,
  cibStripe,
  cibStubhub,
  cibStumbleupon,
  cibStyleshare,
  cibStylus,
  cibSublimeText,
  cibSubversion,
  cibSuperuser,
  cibSvelte,
  cibSvg,
  cibSwagger,
  cibSwarm,
  cibSwift,
  cibSymantec,
  cibSymfony,
  cibSynology,
  cibTMobile,
  cibTableau,
  cibTails,
  cibTapas,
  cibTeamviewer,
  cibTed,
  cibTeespring,
  cibTelegramPlane,
  cibTelegram,
  cibTencentQq,
  cibTencentWeibo,
  cibTensorflow,
  cibTerraform,
  cibTesla,
  cibTheMighty,
  cibTheMovieDatabase,
  cibTidal,
  cibTiktok,
  cibTinder,
  cibTodoist,
  cibToggl,
  cibTopcoder,
  cibToptal,
  cibTor,
  cibToshiba,
  cibTrainerroad,
  cibTrakt,
  cibTravisci,
  cibTreehouse,
  cibTrello,
  cibTripadvisor,
  cibTrulia,
  cibTumblr,
  cibTwilio,
  cibTwitch,
  cibTwitter,
  cibTwoo,
  cibTypescript,
  cibTypo3,
  cibUber,
  cibUbisoft,
  cibUblockOrigin,
  cibUbuntu,
  cibUdacity,
  cibUdemy,
  cibUikit,
  cibUmbraco,
  cibUnity,
  cibUnrealEngine,
  cibUnsplash,
  cibUntappd,
  cibUpwork,
  cibUsb,
  cibV8,
  cibVagrant,
  cibVenmo,
  cibVerizon,
  cibViadeo,
  cibViber,
  cibVim,
  cibVimeoV,
  cibVimeo,
  cibVine,
  cibVirb,
  cibVisa,
  cibVisualStudioCode,
  cibVisualStudio,
  cibVk,
  cibVlc,
  cibVsco,
  cibVueJs,
  cibWattpad,
  cibWeasyl,
  cibWebcomponentsOrg,
  cibWebpack,
  cibWebstorm,
  cibWechat,
  cibWhatsapp,
  cibWhenIWork,
  cibWii,
  cibWiiu,
  cibWikipedia,
  cibWindows,
  cibWire,
  cibWireguard,
  cibWix,
  cibWolframLanguage,
  cibWolframMathematica,
  cibWolfram,
  cibWordpress,
  cibWpengine,
  cibXPack,
  cibXbox,
  cibXcode,
  cibXero,
  cibXiaomi,
  cibXing,
  cibXrp,
  cibXsplit,
  cibYCombinator,
  cibYahoo,
  cibYammer,
  cibYandex,
  cibYarn,
  cibYelp,
  cibYoutube,
  cibZalando,
  cibZapier,
  cibZeit,
  cibZendesk,
  cibZerply,
  cibZillow,
  cibZingat,
  cibZoom,
  cibZorin,
  cibZulip
} from '@coreui/icons'
import {

  cifAe,
  cifAg,
  cifAo,
  cifAm,
  cifAt,
  cifAu,
  cifAz,
  cifBa,
  cifBe,
  cifBb,
  cifBf,
  cifBd,
  cifBg,
  cifBh,
  cifBi,
  cifBo,
  cifBj,
  cifBr,
  cifBs,
  cifBw,
  cifCa,
  cifCd,
  cifCf,
  cifCg,
  cifCh,
  cifCi,
  cifCm,
  cifCn,
  cifCo,
  cifCr,
  cifCv,
  cifCu,
  cifCz,
  cifCl,
  cifDe,
  cifDj,
  cifDk,
  cifDz,
  cifEr,
  cifEe,
  cifEt,
  cifFi,
  cifFm,
  cifGa,
  cifGb,
  cifGd,
  cifGe,
  cifGh,
  cifGm,
  cifGr,
  cifFr,
  cifGy,
  cifGw,
  cifHk,
  cifHn,
  cifHu,
  cifIe,
  cifId,
  cifIl,
  cifIq,
  cifIr,
  cifIn,
  cifIs,
  cifIt,
  cifJm,
  cifJp,
  cifKe,
  cifJo,
  cifKm,
  cifKi,
  cifKn,
  cifKp,
  cifKr,
  cifKw,
  cifLa,
  cifLc,
  cifLs,
  cifLr,
  cifLv,
  cifLt,
  cifMa,
  cifLy,
  cifMc,
  cifLu,
  cifMg,
  cifMh,
  cifMl,
  cifMk,
  cifMn,
  cifMr,
  cifMu,
  cifMv,
  cifMm,
  cifMw,
  cifMy,
  cifNa,
  cifNe,
  cifNg,
  cifNl,
  cifMz,
  cifNo,
  cifNp,
  cifNr,
  cifNu,
  cifNz,
  cifPa,
  cifPe,
  cifPg,
  cifPh,
  cifPk,
  cifPl,
  cifPw,
  cifRo,
  cifRu,
  cifRw,
  cifSb,
  cifSc,
  cifSd,
  cifQa,
  cifSe,
  cifSi,
  cifSk,
  cifSl,
  cifSn,
  cifSo,
  cifSr,
  cifSs,
  cifSg,
  cifSt,
  cifSy,
  cifSz,
  cifTd,
  cifTg,
  cifTh,
  cifTj,
  cifTl,
  cifTn,
  cifTr,
  cifTo,
  cifTt,
  cifTv,
  cifUa,
  cifUg,
  cifTw,
  cifUs,
  cifUz,
  cifTz,
  cifVc,
  cifUy,
  cifVe,
  cifWs,
  cifVn,
  cifYe,
  cifXk,
  cifZa,
  cifZm,
  cifZw,
  cifAl,
  cifAr,
  cifBy,
  cifCy,
  cifDm,
  cifGn,
  cifGq,
  cifKg,
  cifLb,
  cifMt,
  cifKh,
  cifLi,
  cifNi,
  cifOm,
  cifSa,
  cifBn,
  cifEg,
  cifKz,
  cifLk,
  cifMd,
  cifBt,
  cifHt,
  cifPy,
  cifPt,
  cifAf,
  cifTm,
  cifAd,
  cifVa,
  cifMe,
  cifBz,
  cifGt,
  cifFj,
  cifHr,
  cifEs,
  cifSm,
  cifDo,
  cifSv,
  cifMx,
  cifEc,
  cifRs
} from '@coreui/icons'
import {
  cil3d,
  cil4k,
  cilAccountLogout,
  cilActionRedo,
  cilAddressBook,
  cilActionUndo,
  cilAirplaneModeOff,
  cilAirplaneMode,
  cilAirplay,
  cilAlarm,
  cilAlbum,
  cilAlignCenter,
  cilAlignLeft,
  cilAmericanFootball,
  cilAperture,
  cilApple,
  cilApplicationsSettings,
  cilApplications,
  cilArrowBottom,
  cilAlignRight,
  cilArrowCircleBottom,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowLeft,
  cilArrowRight,
  cilArrowCircleTop,
  cilArrowThickFromBottom,
  cilArrowThickFromLeft,
  cilArrowThickBottom,
  cilArrowThickFromRight,
  cilArrowThickFromTop,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilArrowThickToTop,
  cilArrowThickToBottom,
  cilArrowThickTop,
  cilArrowTop,
  cilAssistiveListeningSystem,
  cilAsteriskCircle,
  cilAt,
  cilAsterisk,
  cilAudioDescription,
  cilAudioSpectrum,
  cilAvTimer,
  cilAudio,
  cilBadge,
  cilBan,
  cilBank,
  cilBalanceScale,
  cilBarChart,
  cilBarcode,
  cilBaseball,
  cilBasket,
  cilBath,
  cilBattery0,
  cilBattery3,
  cilBattery5,
  cilBatteryAlert,
  cilBatterySlash,
  cilBeachAccess,
  cilBeaker,
  cilBed,
  cilBell,
  cilBike,
  cilBirthdayCake,
  cilBlind,
  cilBasketball,
  cilBlurCircular,
  cilBlurLinear,
  cilBlur,
  cilBold,
  cilBoatAlt,
  cilBolt,
  cilBookmark,
  cilBook,
  cilBluetooth,
  cilBorderBottom,
  cilBorderClear,
  cilBorderHorizontal,
  cilBorderInner,
  cilBorderLeft,
  cilBorderOuter,
  cilBorderRight,
  cilBorderAll,
  cilBorderTop,
  cilBorderVertical,
  cilBowling,
  cilBraille,
  cilBriefcase,
  cilBrightness,
  cilBritishPound,
  cilBrowser,
  cilBrushAlt,
  cilBrush,
  cilBorderStyle,
  cilBug,
  cilBuilding,
  cilBullhorn,
  cilCalculator,
  cilBurger,
  cilCalendarCheck,
  cilCameraControl,
  cilBusAlt,
  cilCamera,
  cilCameraRoll,
  cilCalendar,
  cilCarAlt,
  cilCaretBottom,
  cilCaretLeft,
  cilCaretRight,
  cilCaretTop,
  cilCart,
  cilCasino,
  cilCast,
  cilCat,
  cilChartLine,
  cilChartPie,
  cilChart,
  cilCenterFocus,
  cilChatBubble,
  cilCheckAlt,
  cilCheckCircle,
  cilCheck,
  cilChevronCircleDownAlt,
  cilChevronCircleRightAlt,
  cilChevronCircleUpAlt,
  cilChevronBottom,
  cilChevronCircleLeftAlt,
  cilChevronDoubleLeft,
  cilChevronDoubleDown,
  // cilChevronDoubleUpAlt,
  cilChevronDoubleRight,
  cilChevronLeft,
  cilChevronDoubleUp,
  cilChevronRight,
  cilChevronTop,
  cilChild,
  cilChildFriendly,
  cilCircle,
  cilClearAll,
  cilClipboard,
  cilClock,
  cilClone,
  cilClosedCaptioning,
  cilCloudUpload,
  cilCloud,
  cilCloudy,
  cilCoffee,
  cilCode,
  cilColorBorder,
  cilColorFill,
  cilColorPalette,
  cilCloudDownload,
  cilCommentBubble,
  cilCommentSquare,
  cilCompass,
  cilCompress,
  cilContrast,
  cilContact,
  cilCopy,
  cilCouch,
  cilColumns,
  cilCreditCard,
  cilCropRotate,
  cilCrop,
  cilCut,
  cilCursor,
  cilDataTransferDown,
  cilCursorMove,
  cilDeaf,
  cilDescription,
  cilDialpad,
  cilDevices,
  cilDinner,
  cilDataTransferUp,
  cilDog,
  cilDollar,
  cilDoubleQuoteSansLeft,
  cilDoor,
  cilDoubleQuoteSansRight,
  cilDrinkAlcohol,
  cilDrink,
  cilDrop,
  cilElevator,
  // cilDrop1,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEqualizer,
  cilEthernet,
  cilExcerpt,
  cilExitToApp,
  cilExpandDown,
  cilExpandLeft,
  cilExpandRight,
  cilExpandUp,
  cilExposure,
  cilExternalLink,
  cilFaceDead,
  cilEyedropper,
  cilFace,
  cilFastfood,
  cilFax,
  cilFeaturedPlaylist,
  cilFile,
  cilFilterPhoto,
  cilEuro,
  cilFilter,
  cilFindInPage,
  cilFingerprint,
  cilFire,
  cilFilterFrames,
  cilFlightTakeoff,
  cilFlipToBack,
  cilFlipToFront,
  cilFlip,
  cilFlower,
  cilFlagAlt,
  cilFolder,
  cilFont,
  cilFolderOpen,
  cilFootball,
  cilFridge,
  cilFrown,
  cilFullscreenExit,
  cilFullscreen,
  cilFunctions,
  cilFunctionsAlt,
  cilGamepad,
  cilGarage,
  cilGem,
  cilFork,
  cilGif,
  cilGift,
  cilGlobeAlt,
  cilGolfAlt,
  cilGolf,
  cilGradient,
  cilGrain,
  cilGraph,
  cilGridSlash,
  cilGrid,
  cilHandPointDown,
  cilHandPointLeft,
  cilHd,
  cilHandPointUp,
  cilHeader,
  cilHdr,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cilHandPointRight,
  cilHome,
  cilHistory,
  cilHospital,
  cilHotTub,
  cilHouse,
  cilHttps,
  cilImageBroken,
  cilHeart,
  cilImagePlus,
  cilInbox,
  // cilImage1,
  cilIndentIncrease,
  cilIndentDecrease,
  cilIndustrySlash,
  cilIndustry,
  cilInfinity,
  cilInfo,
  cilInputHdmi,
  cilInputPower,
  cilInstitution,
  cilItalic,
  cilInput,
  cilJustifyCenter,
  cilJustifyLeft,
  cilJustifyRight,
  cilKeyboard,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLeaf,
  cilLemon,
  cilLevelDown,
  cilLevelUp,
  cilLibraryAdd,
  cilLifeRing,
  cilLanguage,
  cilLibrary,
  cilLightbulb,
  cilLineWeight,
  cilLineStyle,
  cilLineSpacing,
  cilLink,
  cilLinkBroken,
  cilListFilter,
  cilListHighPriority,
  cilListNumbered,
  cilListLowPriority,
  cilLinkAlt,
  cilListRich,
  cilLockLocked,
  cilLockUnlocked,
  cilLocationPin,
  cilList,
  cilLoop,
  cilLoop1,
  cilLoopCircular,
  cilLowVision,
  cilMap,
  cilLocomotive,
  cilMediaEject,
  cilMediaPause,
  cilMediaRecord,
  cilMediaPlay,
  cilMediaSkipBackward,
  cilMagnifyingGlass,
  cilMediaStepBackward,
  cilMediaStepForward,
  cilMediaStop,
  cilMedicalCross,
  cilMediaSkipForward,
  cilMeh,
  cilMemory,
  cilMicrophone,
  cilMinus,
  cilMenu,
  cilMobileLandscape,
  cilMobile,
  cilMoney,
  cilMonitor,
  cilMoodBad,
  cilMoodGood,
  cilMoodVeryBad,
  cilMoodVeryGood,
  cilMoon,
  cilMouse,
  cilMouthSlash,
  cilMove,
  cilMovie,
  cilMugTea,
  cilMusicNote,
  cilNotes,
  cilNewspaper,
  cilObjectGroup,
  cilObjectUngroup,
  cilOpacity,
  // cilOptionsHorizontal,
  cilMug,
  cilPaint,
  cilPaintBucket,
  cilPaperPlane,
  cilPaperclip,
  cilParagraph,
  cilPenAlt,
  cilPaw,
  cilPenNib,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPizza,
  cilPin,
  cilPlaylistAdd,
  cilPlus,
  cilPool,
  cilPowerStandby,
  cilPregnant,
  cilPrint,
  cilQrCode,
  cilRain,
  cilRectangle,
  cilPuzzle,
  // cilRemove,
  cilResizeHeight,
  cilReload,
  cilResizeWidth,
  cilRestaurant,
  cilRoom,
  cilRowing,
  cilRss,
  cilSatelite,
  cilSave,
  cilRunning,
  cilSchool,
  cilResizeBoth,
  cilScreenDesktop,
  cilScrubber,
  cilShareAll,
  cilSettings,
  cilShareAlt,
  cilShare,
  cilScreenSmartphone,
  cilShieldAlt,
  cilShareBoxed,
  cilShortText,
  cilShower,
  cilSignalCellular0,
  cilSignalCellular4,
  cilSignLanguage,
  cilSignalCellular3,
  cilSim,
  cilSitemap,
  cilSmilePlus,
  cilSmokeFree,
  cilSnowflake,
  cilSmokingRoom,
  cilSortAlphaDown,
  cilSortAlphaUp,
  cilSortAscending,
  cilSortDescending,
  cilSortNumericDown,
  cilSmile,
  cilSortNumericUp,
  cilSpa,
  cilSpeaker,
  cilSpaceBar,
  cilSpeech,
  cilSpreadsheet,
  cilSquare,
  cilStarHalf,
  cilStar,
  cilSpeedometer,
  cilStream,
  cilSun,
  cilStorage,
  cilSwapHorizontal,
  cilSwapVertical,
  cilSwimming,
  cilSync,
  cilTag,
  cilTablet,
  cilTags,
  cilTask,
  cilTaxi,
  cilTennisBall,
  cilTennis,
  cilTerminal,
  cilTerrain,
  cilTextShapes,
  cilTextSize,
  cilTextStrike,
  cilText,
  cilThumbUp,
  cilThumbDown,
  cilToggleOff,
  cilToilet,
  cilTextSquare,
  cilTransfer,
  cilTranslate,
  cilTrash,
  cilTouchApp,
  cilTriangle,
  cilTruck,
  cilTv,
  cilUnderline,
  cilUserFemale,
  cilUser,
  cilVector,
  cilUserUnfollow,
  cilUserFollow,
  cilVerticalAlignBottom,
  // cilVerticalAlignBottom1,
  // cilVerticalAlignCenter1,
  cilVerticalAlignTop,
  // cilVerticalAlignTop1,
  cilVideo,
  cilViewColumn,
  cilViewModule,
  cilViewQuilt,
  cilViewStream,
  cilVolumeHigh,
  cilVoiceOverRecord,
  cilVolumeLow,
  cilVolumeOff,
  cilWallet,
  cilWallpaper,
  cilWalk,
  cilWarning,
  cilWatch,
  cilVerticalAlignCenter,
  cilWc,
  cilWeightlifitng,
  cilWheelchair,
  cilWifiSignal0,
  cilWifiSignal1,
  cilWifiSignal4,
  cilWifiSignalOff,
  cilWifiSignal2,
  cilWindowMinimize,
  cilWindowMaximize,
  cilWindowRestore,
  cilWindow,
  cilXCircle,
  cilX,
  cilYen,
  cilWrapText,
  cilZoomIn,
  cilZoomOut,
  cilSearch
} from '@coreui/icons'
import { logo } from './logo'


export const iconsSet = Object.assign({}, { logo }, {cil3d,
  cil4k,
  cilAccountLogout,
  cilActionRedo,
  cilAddressBook,
  cilActionUndo,
  cilAirplaneModeOff,
  cilAirplaneMode,
  cilAirplay,
  cilAlarm,
  cilAlbum,
  cilAlignCenter,
  cilAlignLeft,
  cilAmericanFootball,
  cilAperture,
  cilApple,
  cilApplicationsSettings,
  cilApplications,
  cilArrowBottom,
  cilAlignRight,
  cilArrowCircleBottom,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowLeft,
  cilArrowRight,
  cilArrowCircleTop,
  cilArrowThickFromBottom,
  cilArrowThickFromLeft,
  cilArrowThickBottom,
  cilArrowThickFromRight,
  cilArrowThickFromTop,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilArrowThickToTop,
  cilArrowThickToBottom,
  cilArrowThickTop,
  cilArrowTop,
  cilAssistiveListeningSystem,
  cilAsteriskCircle,
  cilAt,
  cilAsterisk,
  cilAudioDescription,
  cilAudioSpectrum,
  cilAvTimer,
  cilAudio,
  cilBadge,
  cilBan,
  cilBank,
  cilBalanceScale,
  cilBarChart,
  cilBarcode,
  cilBaseball,
  cilBasket,
  cilBath,
  cilBattery0,
  cilBattery3,
  cilBattery5,
  cilBatteryAlert,
  cilBatterySlash,
  cilBeachAccess,
  cilBeaker,
  cilBed,
  cilBell,
  cilBike,
  cilBirthdayCake,
  cilBlind,
  cilBasketball,
  cilBlurCircular,
  cilBlurLinear,
  cilBlur,
  cilBold,
  cilBoatAlt,
  cilBolt,
  cilBookmark,
  cilBook,
  cilBluetooth,
  cilBorderBottom,
  cilBorderClear,
  cilBorderHorizontal,
  cilBorderInner,
  cilBorderLeft,
  cilBorderOuter,
  cilBorderRight,
  cilBorderAll,
  cilBorderTop,
  cilBorderVertical,
  cilBowling,
  cilBraille,
  cilBriefcase,
  cilBrightness,
  cilBritishPound,
  cilBrowser,
  cilBrushAlt,
  cilBrush,
  cilBorderStyle,
  cilBug,
  cilBuilding,
  cilBullhorn,
  cilCalculator,
  cilBurger,
  cilCalendarCheck,
  cilCameraControl,
  cilBusAlt,
  cilCamera,
  cilCameraRoll,
  cilCalendar,
  cilCarAlt,
  cilCaretBottom,
  cilCaretLeft,
  cilCaretRight,
  cilCaretTop,
  cilCart,
  cilCasino,
  cilCast,
  cilCat,
  cilChartLine,
  cilChartPie,
  cilChart,
  cilCenterFocus,
  cilChatBubble,
  cilCheckAlt,
  cilCheckCircle,
  cilCheck,
  cilChevronCircleDownAlt,
  cilChevronCircleRightAlt,
  cilChevronCircleUpAlt,
  cilChevronBottom,
  cilChevronCircleLeftAlt,
  cilChevronDoubleLeft,
  cilChevronDoubleDown,
  // cilChevronDoubleUpAlt,
  cilChevronDoubleRight,
  cilChevronLeft,
  cilChevronDoubleUp,
  cilChevronRight,
  cilChevronTop,
  cilChild,
  cilChildFriendly,
  cilCircle,
  cilClearAll,
  cilClipboard,
  cilClock,
  cilClone,
  cilClosedCaptioning,
  cilCloudUpload,
  cilCloud,
  cilCloudy,
  cilCoffee,
  cilCode,
  cilColorBorder,
  cilColorFill,
  cilColorPalette,
  cilCloudDownload,
  cilCommentBubble,
  cilCommentSquare,
  cilCompass,
  cilCompress,
  cilContrast,
  cilContact,
  cilCopy,
  cilCouch,
  cilColumns,
  cilCreditCard,
  cilCropRotate,
  cilCrop,
  cilCut,
  cilCursor,
  cilDataTransferDown,
  cilCursorMove,
  cilDeaf,
  cilDescription,
  cilDialpad,
  cilDevices,
  cilDinner,
  cilDataTransferUp,
  cilDog,
  cilDollar,
  cilDoubleQuoteSansLeft,
  cilDoor,
  cilDoubleQuoteSansRight,
  cilDrinkAlcohol,
  cilDrink,
  cilDrop,
  cilElevator,
  // cilDrop1,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEqualizer,
  cilEthernet,
  cilExcerpt,
  cilExitToApp,
  cilExpandDown,
  cilExpandLeft,
  cilExpandRight,
  cilExpandUp,
  cilExposure,
  cilExternalLink,
  cilFaceDead,
  cilEyedropper,
  cilFace,
  cilFastfood,
  cilFax,
  cilFeaturedPlaylist,
  cilFile,
  cilFilterPhoto,
  cilEuro,
  cilFilter,
  cilFindInPage,
  cilFingerprint,
  cilFire,
  cilFilterFrames,
  cilFlightTakeoff,
  cilFlipToBack,
  cilFlipToFront,
  cilFlip,
  cilFlower,
  cilFlagAlt,
  cilFolder,
  cilFont,
  cilFolderOpen,
  cilFootball,
  cilFridge,
  cilFrown,
  cilFullscreenExit,
  cilFullscreen,
  cilFunctions,
  cilFunctionsAlt,
  cilGamepad,
  cilGarage,
  cilGem,
  cilFork,
  cilGif,
  cilGift,
  cilGlobeAlt,
  cilGolfAlt,
  cilGolf,
  cilGradient,
  cilGrain,
  cilGraph,
  cilGridSlash,
  cilGrid,
  cilHandPointDown,
  cilHandPointLeft,
  cilHd,
  cilHandPointUp,
  cilHeader,
  cilHdr,
  cilHeadphones,
  cilHealing,
  cilHighlighter,
  cilHighligt,
  cilHandPointRight,
  cilHome,
  cilHistory,
  cilHospital,
  cilHotTub,
  cilHouse,
  cilHttps,
  cilImageBroken,
  cilHeart,
  cilImagePlus,
  cilInbox,
  // cilImage1,
  cilIndentIncrease,
  cilIndentDecrease,
  cilIndustrySlash,
  cilIndustry,
  cilInfinity,
  cilInfo,
  cilInputHdmi,
  cilInputPower,
  cilInstitution,
  cilItalic,
  cilInput,
  cilJustifyCenter,
  cilJustifyLeft,
  cilJustifyRight,
  cilKeyboard,
  cilLan,
  cilLaptop,
  cilLayers,
  cilLeaf,
  cilLemon,
  cilLevelDown,
  cilLevelUp,
  cilLibraryAdd,
  cilLifeRing,
  cilLanguage,
  cilLibrary,
  cilLightbulb,
  cilLineWeight,
  cilLineStyle,
  cilLineSpacing,
  cilLink,
  cilLinkBroken,
  cilListFilter,
  cilListHighPriority,
  cilListNumbered,
  cilListLowPriority,
  cilLinkAlt,
  cilListRich,
  cilLockLocked,
  cilLockUnlocked,
  cilLocationPin,
  cilList,
  cilLoop,
  cilLoop1,
  cilLoopCircular,
  cilLowVision,
  cilMap,
  cilLocomotive,
  cilMediaEject,
  cilMediaPause,
  cilMediaRecord,
  cilMediaPlay,
  cilMediaSkipBackward,
  cilMagnifyingGlass,
  cilMediaStepBackward,
  cilMediaStepForward,
  cilMediaStop,
  cilMedicalCross,
  cilMediaSkipForward,
  cilMeh,
  cilMemory,
  cilMicrophone,
  cilMinus,
  cilMenu,
  cilMobileLandscape,
  cilMobile,
  cilMoney,
  cilMonitor,
  cilMoodBad,
  cilMoodGood,
  cilMoodVeryBad,
  cilMoodVeryGood,
  cilMoon,
  cilMouse,
  cilMouthSlash,
  cilMove,
  cilMovie,
  cilMugTea,
  cilMusicNote,
  cilNotes,
  cilNewspaper,
  cilObjectGroup,
  cilObjectUngroup,
  cilOpacity,
  // cilOptionsHorizontal,
  cilMug,
  cilPaint,
  cilPaintBucket,
  cilPaperPlane,
  cilPaperclip,
  cilParagraph,
  cilPenAlt,
  cilPaw,
  cilPenNib,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPizza,
  cilPin,
  cilPlaylistAdd,
  cilPlus,
  cilPool,
  cilPowerStandby,
  cilPregnant,
  cilPrint,
  cilQrCode,
  cilRain,
  cilRectangle,
  cilPuzzle,
  // cilRemove,
  cilResizeHeight,
  cilReload,
  cilResizeWidth,
  cilRestaurant,
  cilRoom,
  cilRowing,
  cilRss,
  cilSatelite,
  cilSave,
  cilRunning,
  cilSchool,
  cilResizeBoth,
  cilScreenDesktop,
  cilScrubber,
  cilShareAll,
  cilSettings,
  cilShareAlt,
  cilShare,
  cilScreenSmartphone,
  cilShieldAlt,
  cilShareBoxed,
  cilShortText,
  cilShower,
  cilSignalCellular0,
  cilSignalCellular4,
  cilSignLanguage,
  cilSignalCellular3,
  cilSim,
  cilSitemap,
  cilSmilePlus,
  cilSmokeFree,
  cilSnowflake,
  cilSmokingRoom,
  cilSortAlphaDown,
  cilSortAlphaUp,
  cilSortAscending,
  cilSortDescending,
  cilSortNumericDown,
  cilSmile,
  cilSortNumericUp,
  cilSpa,
  cilSpeaker,
  cilSpaceBar,
  cilSpeech,
  cilSpreadsheet,
  cilSquare,
  cilStarHalf,
  cilStar,
  cilSpeedometer,
  cilStream,
  cilSun,
  cilStorage,
  cilSwapHorizontal,
  cilSwapVertical,
  cilSwimming,
  cilSync,
  cilTag,
  cilTablet,
  cilTags,
  cilTask,
  cilTaxi,
  cilTennisBall,
  cilTennis,
  cilTerminal,
  cilTerrain,
  cilTextShapes,
  cilTextSize,
  cilTextStrike,
  cilText,
  cilThumbUp,
  cilThumbDown,
  cilToggleOff,
  cilToilet,
  cilTextSquare,
  cilTransfer,
  cilTranslate,
  cilTrash,
  cilTouchApp,
  cilTriangle,
  cilTruck,
  cilTv,
  cilUnderline,
  cilUserFemale,
  cilUser,
  cilVector,
  cilUserUnfollow,
  cilUserFollow,
  cilVerticalAlignBottom,
  // cilVerticalAlignBottom1,
  // cilVerticalAlignCenter1,
  cilVerticalAlignTop,
  // cilVerticalAlignTop1,
  cilVideo,
  cilViewColumn,
  cilViewModule,
  cilViewQuilt,
  cilViewStream,
  cilVolumeHigh,
  cilVoiceOverRecord,
  cilVolumeLow,
  cilVolumeOff,
  cilWallet,
  cilWallpaper,
  cilWalk,
  cilWarning,
  cilWatch,
  cilVerticalAlignCenter,
  cilWc,
  cilWeightlifitng,
  cilWheelchair,
  cilWifiSignal0,
  cilWifiSignal1,
  cilWifiSignal4,
  cilWifiSignalOff,
  cilWifiSignal2,
  cilWindowMinimize,
  cilWindowMaximize,
  cilWindowRestore,
  cilWindow,
  cilXCircle,
  cilX,
  cilYen,
  cilWrapText,
  cilZoomIn,
  cilZoomOut,
  cilSearch
}, {cifAe,
  cifAg,
  cifAo,
  cifAm,
  cifAt,
  cifAu,
  cifAz,
  cifBa,
  cifBe,
  cifBb,
  cifBf,
  cifBd,
  cifBg,
  cifBh,
  cifBi,
  cifBo,
  cifBj,
  cifBr,
  cifBs,
  cifBw,
  cifCa,
  cifCd,
  cifCf,
  cifCg,
  cifCh,
  cifCi,
  cifCm,
  cifCn,
  cifCo,
  cifCr,
  cifCv,
  cifCu,
  cifCz,
  cifCl,
  cifDe,
  cifDj,
  cifDk,
  cifDz,
  cifEr,
  cifEe,
  cifEt,
  cifFi,
  cifFm,
  cifGa,
  cifGb,
  cifGd,
  cifGe,
  cifGh,
  cifGm,
  cifGr,
  cifFr,
  cifGy,
  cifGw,
  cifHk,
  cifHn,
  cifHu,
  cifIe,
  cifId,
  cifIl,
  cifIq,
  cifIr,
  cifIn,
  cifIs,
  cifIt,
  cifJm,
  cifJp,
  cifKe,
  cifJo,
  cifKm,
  cifKi,
  cifKn,
  cifKp,
  cifKr,
  cifKw,
  cifLa,
  cifLc,
  cifLs,
  cifLr,
  cifLv,
  cifLt,
  cifMa,
  cifLy,
  cifMc,
  cifLu,
  cifMg,
  cifMh,
  cifMl,
  cifMk,
  cifMn,
  cifMr,
  cifMu,
  cifMv,
  cifMm,
  cifMw,
  cifMy,
  cifNa,
  cifNe,
  cifNg,
  cifNl,
  cifMz,
  cifNo,
  cifNp,
  cifNr,
  cifNu,
  cifNz,
  cifPa,
  cifPe,
  cifPg,
  cifPh,
  cifPk,
  cifPl,
  cifPw,
  cifRo,
  cifRu,
  cifRw,
  cifSb,
  cifSc,
  cifSd,
  cifQa,
  cifSe,
  cifSi,
  cifSk,
  cifSl,
  cifSn,
  cifSo,
  cifSr,
  cifSs,
  cifSg,
  cifSt,
  cifSy,
  cifSz,
  cifTd,
  cifTg,
  cifTh,
  cifTj,
  cifTl,
  cifTn,
  cifTr,
  cifTo,
  cifTt,
  cifTv,
  cifUa,
  cifUg,
  cifTw,
  cifUs,
  cifUz,
  cifTz,
  cifVc,
  cifUy,
  cifVe,
  cifWs,
  cifVn,
  cifYe,
  cifXk,
  cifZa,
  cifZm,
  cifZw,
  cifAl,
  cifAr,
  cifBy,
  cifCy,
  cifDm,
  cifGn,
  cifGq,
  cifKg,
  cifLb,
  cifMt,
  cifKh,
  cifLi,
  cifNi,
  cifOm,
  cifSa,
  cifBn,
  cifEg,
  cifKz,
  cifLk,
  cifMd,
  cifBt,
  cifHt,
  cifPy,
  cifPt,
  cifAf,
  cifTm,
  cifAd,
  cifVa,
  cifMe,
  cifBz,
  cifGt,
  cifFj,
  cifHr,
  cifEs,
  cifSm,
  cifDo,
  cifSv,
  cifMx,
  cifEc,
  cifRs
}, {
  cib500px5,
  cib500px,
  cibAboutMe,
  cibAcm,
  cibAbstract,
  cibAdguard,
  cibAdobeAfterEffects,
  cibAdobeAcrobatReader,
  cibAdobeAudition,
  cibAdobeCreativeCloud,
  cibAddthis,
  cibAdobeDreamweaver,
  cibAdobeIllustrator,
  cibAdobeIndesign,
  cibAdobeLightroomClassic,
  cibAdobeLightroom,
  cibAdobePhotoshop,
  cibAdobePremiere,
  cibAdobeTypekit,
  cibAdobeXd,
  cibAirbnb,
  cibAdobe,
  cibAlgolia,
  cibAlipay,
  cibAllocine,
  cibAmazonAws,
  cibAmazonPay,
  cibAmazon,
  cibAmd,
  cibAmericanExpress,
  cibAnalogue,
  cibAndroidAlt,
  cibAndroid,
  cibAnaconda,
  cibAngellist,
  cibAngular,
  cibApacheAirflow,
  cibAngularUniversal,
  cibAnsible,
  cibApacheSpark,
  cibApache,
  cibAppStoreIos,
  cibAppStore,
  cibAppleMusic,
  cibAppveyor,
  cibApplePodcasts,
  cibAral,
  cibArduino,
  cibArchiveOfOurOwn,
  cibArchLinux,
  cibArtstation,
  cibApple,
  cibAsana,
  cibArxiv,
  cibApplePay,
  cibAtAndT,
  cibAtlassian,
  cibAtom,
  cibAurelia,
  cibAuth0,
  cibAudible,
  cibAutomatic,
  cibAutotask,
  cibAventrix,
  cibAzureArtifacts,
  cibAzurePipelines,
  cibBaidu,
  cibBandcamp,
  cibAzureDevops,
  cibBamboo,
  cibBasecamp,
  cibBathasu,
  cibBehance,
  cibBancontact,
  cibBigCartel,
  cibBing,
  cibBitbucket,
  cibBitcoin,
  cibBitdefender,
  cibBit,
  cibBitly,
  cibBlackberry,
  cibBlender,
  cibBlogger,
  cibBluetooth,
  cibBoeing,
  cibBoost,
  cibBootstrap,
  cibBluetoothB,
  cibBower,
  cibBrandAi,
  cibBrave,
  cibBloggerB,
  cibBtc,
  cibBuddy,
  cibBuffer,
  cibBuyMeACoffee,
  cibBuysellads,
  cibC,
  cibCampaignMonitor,
  cibBuzzfeed,
  cibCakephp,
  cibCashapp,
  cibCanva,
  cibCassandra,
  cibCcAmazonPay,
  cibCcAmex,
  cibCcApplePay,
  cibCcDinersClub,
  cibCcDiscover,
  cibCastro,
  cibCcJcb,
  cibCcMastercard,
  cibCcVisa,
  cibCcPaypal,
  cibCcStripe,
  cibCentos,
  cibCevo,
  cibChase,
  cibChef,
  cibChromecast,
  cibCircle,
  cibCircleci,
  cibCirrusci,
  cibCisco,
  cibCivicrm,
  cibClockify,
  cibClojure,
  cibCloudbees,
  cibCloudflare,
  cibCodeClimate,
  cibCmake,
  cibCodacy,
  cibCoOp,
  cibCodecov,
  cibCodeigniter,
  cibCodepen,
  cibCodesandbox,
  cibCodeship,
  cibCoderwall,
  cibCoffeescript,
  cibCodio,
  cibCommonWorkflowLanguage,
  cibCondaForge,
  cibConekta,
  cibCodecademy,
  cibCoreui,
  cibCoreuiC,
  cibCoveralls,
  cibCoursera,
  cibCplusplus,
  cibCpanel,
  cibCreativeCommonsBy,
  cibCreativeCommonsNcEu,
  cibCreativeCommonsNcJp,
  cibConfluence,
  cibCreativeCommonsNc,
  cibCreativeCommonsNd,
  cibCreativeCommonsPdAlt,
  cibCreativeCommonsPd,
  cibCreativeCommonsRemix,
  cibCreativeCommonsSamplingPlus,
  cibCreativeCommonsSampling,
  cibCreativeCommonsSa,
  cibCreativeCommonsShare,
  cibCreativeCommonsZero,
  cibCreativeCommons,
  cibCrunchbase,
  cibCrunchyroll,
  cibCss3Shiled,
  cibCss3,
  cibD3Js,
  cibCsswizardry,
  cibDailymotion,
  cibDazn,
  cibDashlane,
  cibDblp,
  cibDebian,
  cibDeezer,
  cibDelicious,
  cibDeepin,
  cibDell,
  cibDependabot,
  cibDevTo,
  cibDesignerNews,
  cibDeviantart,
  cibDigg,
  cibDevrant,
  cibDigitalOcean,
  cibDiaspora,
  cibDiscord,
  cibDiscourse,
  cibDiscover,
  cibDisqus,
  cibDisroot,
  cibDjango,
  cibDocker,
  cibDocusign,
  cibDotNet,
  cibDraugiemLv,
  cibDribbble,
  cibDrone,
  cibDropbox,
  cibDrupal,
  cibDtube,
  cibDuckduckgo,
  cibDynatrace,
  cibEbay,
  cibEclipseide,
  cibElasticCloud,
  cibElasticSearch,
  cibElastic,
  cibElasticStack,
  cibElectron,
  cibElementary,
  cibEleventy,
  cibEllo,
  cibEmpirekred,
  cibEnvato,
  cibEmlakjet,
  cibEpson,
  cibEsea,
  cibEslint,
  cibEthereum,
  cibEtsy,
  cibEventStore,
  cibEvernote,
  cibEverplaces,
  cibEventbrite,
  cibExercism,
  cibEvry,
  cibExpertsExchange,
  cibExpo,
  cibEyeem,
  cibFacebookF,
  cibFSecure,
  cibFacebook,
  cibFaceit,
  cibFandango,
  cibFavro,
  cibFedex,
  cibFeathub,
  cibFedora,
  cibFeedly,
  cibFidoAlliance,
  cibFilezilla,
  cibFigma,
  cibFitbit,
  cibFirebase,
  cibFlattr,
  cibFlickr,
  cibFlipboard,
  cibFnac,
  cibFlutter,
  cibFreebsd,
  cibFoursquare,
  cibFreecodecamp,
  cibFurAffinity,
  cibFramer,
  cibFurryNetwork,
  cibGatsby,
  cibGarmin,
  cibGauges,
  cibGentoo,
  cibGenius,
  cibGeocaching,
  cibGg,
  cibGitea,
  cibGhost,
  cibGit,
  cibGimp,
  cibGitkraken,
  cibGithub,
  cibGitpod,
  cibGitter,
  cibGitlab,
  cibGlassdoor,
  cibGnuPrivacyGuard,
  cibGlitch,
  cibGmail,
  cibGnuSocial,
  cibGo,
  cibGodotEngine,
  cibGogCom,
  cibGoldenline,
  cibGoogleAds,
  cibGoogleAllo,
  cibGoogleAnalytics,
  cibGoogleChrome,
  cibGoodreads,
  cibGoogleCloud,
  cibGooglePay,
  cibGooglePlay,
  cibGooglePodcasts,
  cibGoogleKeep,
  cibGoogle,
  cibGooglesCholar,
  cibGradle,
  cibGrafana,
  cibGovUk,
  cibGraphcool,
  cibGraphql,
  cibGrav,
  cibGravatar,
  cibGroovy,
  cibGreenkeeper,
  cibGroupon,
  cibGrunt,
  cibGulp,
  cibGumroad,
  cibGumtree,
  cibHabr,
  cibHackaday,
  cibHackerone,
  cibHackerearth,
  cibHackerrank,
  cibHackhands,
  cibHackster,
  cibHappycow,
  cibHashnode,
  cibHaskell,
  cibHelm,
  cibHatenaBookmark,
  cibHaxe,
  cibHere,
  cibHeroku,
  cibHexo,
  cibHipchat,
  cibHitachi,
  cibHomify,
  cibHootsuite,
  cibHighly,
  cibHotjar,
  cibHouzz,
  cibHockeyapp,
  cibHp,
  cibHtml5Shield,
  cibHtml5,
  cibHtmlacademy,
  cibHuawei,
  cibHubspot,
  cibHulu,
  cibHumbleBundle,
  cibIata,
  cibIbm,
  cibIcloud,
  cibIconjar,
  cibIcq,
  cibIdeal,
  cibIfixit,
  cibImdb,
  cibIndeed,
  cibInkscape,
  cibInstacart,
  cibInstagram,
  cibInstapaper,
  cibIntel,
  cibIntercom,
  cibIntellijidea,
  cibInternetExplorer,
  cibInvision,
  cibIssuu,
  cibIonic,
  cibItchIo,
  cibJabber,
  cibJava,
  cibJavascript,
  cibJekyll,
  cibJenkins,
  cibJest,
  cibJet,
  cibJetbrains,
  cibJira,
  cibJoomla,
  cibJquery,
  cibJs,
  cibJsfiddle,
  cibJsdelivr,
  cibJson,
  cibJupyter,
  cibJustgiving,
  cibKaggle,
  cibKaios,
  cibKentico,
  cibKaspersky,
  cibKeybase,
  cibKeras,
  cibKeycdn,
  cibKhanAcademy,
  cibKickstarter,
  cibKibana,
  cibKik,
  cibKlout,
  cibKirby,
  cibKnown,
  cibKodi,
  cibKoFi,
  cibKoding,
  cibKotlin,
  cibKrita,
  cibKubernetes,
  cibLanyrd,
  cibLaravelHorizon,
  cibLaravelNova,
  cibLaravel,
  cibLastFm,
  cibLatex,
  cibLaunchpad,
  cibLeetcode,
  cibLenovo,
  cibLess,
  cibLetsEncrypt,
  cibLetterboxd,
  cibLgtm,
  cibLibrarything,
  cibLiberapay,
  cibLibreoffice,
  cibLine,
  cibLinkedinIn,
  cibLinkedin,
  cibLinuxFoundation,
  cibLinuxMint,
  cibLinux,
  cibLivejournal,
  cibLivestream,
  cibLogstash,
  cibLua,
  cibLumen,
  cibLyft,
  cibMacys,
  cibMagento,
  cibMailRu,
  cibMagisk,
  cibMakerbot,
  cibMailchimp,
  cibManjaro,
  cibMarketo,
  cibMarkdown,
  cibMastercard,
  cibMastodon,
  cibMaterialDesign,
  cibMathworks,
  cibMatternet,
  cibMaxcdn,
  cibMatrix,
  cibMcafee,
  cibMediaTemple,
  cibMattermost,
  cibMediumM,
  cibMediafire,
  cibMedium,
  cibMeetup,
  cibMega,
  cibMendeley,
  cibMeteor,
  cibMessenger,
  cibMicroBlog,
  cibMicrogenetics,
  cibMicrosoftEdge,
  cibMicrosoft,
  cibMinetest,
  cibMinutemailer,
  cibMix,
  cibMixcloud,
  cibMixer,
  cibMonero,
  cibMojang,
  cibMongodb,
  cibMonogram,
  cibMonkeytie,
  cibMonzo,
  cibMoo,
  cibMozillaFirefox,
  cibMozilla,
  cibMusescore,
  cibMxlinux,
  cibMyspace,
  cibMysql,
  cibNativescript,
  cibNec,
  cibNeo4j,
  cibNetflix,
  cibNetlify,
  cibNextJs,
  cibNextdoor,
  cibNextcloud,
  cibNginx,
  cibNim,
  cibNintendo3ds,
  cibNintendoGamecube,
  cibNintendoSwitch,
  cibNintendo,
  cibNodeJs,
  cibNodemon,
  cibNodeRed,
  cibNpm,
  cibNotion,
  cibNokia,
  cibNuget,
  cibNucleo,
  cibNuxtJs,
  cibNvidia,
  cibOcaml,
  cibOctave,
  cibOctopusDeploy,
  cibOculus,
  cibOdnoklassniki,
  cibOpenAccess,
  cibOpenCollective,
  cibOpenId,
  cibOpenSourceInitiative,
  cibOpenstreetmap,
  cibOpensuse,
  cibOpenvpn,
  cibOpera,
  cibOracle,
  cibOpsgenie,
  cibOrcid,
  cibOrigin,
  cibOsi,
  cibOsmc,
  cibOvercast,
  cibOverleaf,
  cibOvh,
  cibPagekit,
  cibPalantir,
  cibPandora,
  cibPantheon,
  cibPatreon,
  cibPeriscope,
  cibPaypal,
  cibPhp,
  cibPicartoTv,
  cibPinboard,
  cibPingdom,
  cibPingup,
  cibPinterest,
  cibPinterestP,
  cibPivotaltracker,
  cibPlangrid,
  cibPlayerMe,
  cibPlaystation,
  cibPlayerfm,
  cibPlaystation3,
  cibPlaystation4,
  cibPlesk,
  cibPlex,
  cibPlurk,
  cibPluralsight,
  cibPocket,
  cibPostgresql,
  cibPostman,
  cibPostwoman,
  cibPowershell,
  cibPrettier,
  cibPrismic,
  cibProbot,
  cibProcesswire,
  cibProductHunt,
  cibProtoIo,
  cibProtonmail,
  cibProxmox,
  cibPypi,
  cibPytorch,
  cibPython,
  cibQgis,
  cibQiita,
  cibQq,
  cibQualcomm,
  cibQuantcast,
  cibQuantopian,
  cibQuora,
  cibQuarkus,
  cibQwiklabs,
  cibQzone,
  cibR,
  cibRails,
  cibRadiopublic,
  cibRaspberryPi,
  cibReact,
  cibReadme,
  cibReason,
  cibRealm,
  cibReadTheDocs,
  cibRedbubble,
  cibRedditAlt,
  cibRedhat,
  cibRedis,
  cibRedux,
  cibReddit,
  cibRenren,
  cibReverbnation,
  cibRiot,
  cibRipple,
  cibRiseup,
  cibRollupJs,
  cibRoundcube,
  cibRoots,
  cibRss,
  cibRstudio,
  cibRuby,
  cibRubygems,
  cibRunkeeper,
  cibRust,
  cibSafari,
  cibSahibinden,
  cibSalesforce,
  cibSaltstack,
  cibSamsungPay,
  cibSamsung,
  cibSap,
  cibSassAlt,
  cibSass,
  cibScala,
  cibSaucelabs,
  cibScaleway,
  cibScribd,
  cibScrutinizerci,
  cibSeagate,
  cibSega,
  cibSellfy,
  cibSemaphoreci,
  cibSensu,
  cibSentry,
  cibShell,
  cibServerFault,
  cibShazam,
  cibShopify,
  cibShowpad,
  cibSiemens,
  cibSignal,
  cibSinaWeibo,
  cibSitepoint,
  cibSketch,
  cibSkillshare,
  cibSkyliner,
  cibSlack,
  cibSkype,
  cibSlashdot,
  cibSlickpic,
  cibSlides,
  cibSlideshare,
  cibSmashingmagazine,
  cibSnapchat,
  cibSnapcraft,
  cibSnyk,
  cibSocketIo,
  cibSociety6,
  cibSogou,
  cibSonos,
  cibSolus,
  cibSongkick,
  cibSoundcloud,
  cibSourcegraph,
  cibSourceforge,
  cibSpacemacs,
  cibSpacex,
  cibSparkfun,
  cibSparkpost,
  cibSpdx,
  cibSpeakerDeck,
  cibSpectrum,
  cibSpotify,
  cibSpotlight,
  cibSpreaker,
  cibSpring,
  cibSprint,
  cibSquarespace,
  // cibStackOverflow,
  cibStackbit,
  cibStackexchange,
  cibStackpath,
  cibStackshare,
  cibStadia,
  cibStatamic,
  cibStaticman,
  cibStatuspage,
  cibSteam,
  cibSteem,
  cibSteemit,
  cibStitcher,
  cibStorify,
  cibStorybook,
  cibStrapi,
  cibStrava,
  cibStripeS,
  cibStripe,
  cibStubhub,
  cibStumbleupon,
  cibStyleshare,
  cibStylus,
  cibSublimeText,
  cibSubversion,
  cibSuperuser,
  cibSvelte,
  cibSvg,
  cibSwagger,
  cibSwarm,
  cibSwift,
  cibSymantec,
  cibSymfony,
  cibSynology,
  cibTableau,
  cibTMobile,
  cibTails,
  cibTapas,
  cibTeamviewer,
  cibTed,
  cibTeespring,
  cibTelegramPlane,
  cibTencentQq,
  cibTelegram,
  cibTencentWeibo,
  cibTensorflow,
  cibTesla,
  cibTerraform,
  cibTheMighty,
  cibTheMovieDatabase,
  cibTiktok,
  cibTidal,
  cibTinder,
  cibTodoist,
  cibToggl,
  cibToptal,
  cibTopcoder,
  cibTor,
  cibToshiba,
  cibTrainerroad,
  cibTrakt,
  cibTreehouse,
  cibTrello,
  cibTripadvisor,
  cibTrulia,
  cibTumblr,
  cibTwilio,
  cibTwitch,
  cibTwitter,
  cibTwoo,
  cibTypescript,
  cibTypo3,
  cibUber,
  cibUbisoft,
  cibUbuntu,
  cibUblockOrigin,
  cibUdacity,
  cibUdemy,
  cibUikit,
  cibUmbraco,
  cibUnity,
  cibUnrealEngine,
  cibUnsplash,
  cibUntappd,
  cibUpwork,
  cibUsb,
  cibV8,
  cibVagrant,
  cibVenmo,
  cibVerizon,
  cibViadeo,
  cibViber,
  cibVimeoV,
  cibVim,
  cibVine,
  cibVimeo,
  cibVirb,
  cibVisa,
  cibVisualStudioCode,
  cibVisualStudio,
  cibVk,
  cibVlc,
  cibVsco,
  cibVueJs,
  cibWattpad,
  cibWeasyl,
  cibWebcomponentsOrg,
  cibWebpack,
  cibWebstorm,
  cibWechat,
  cibWhatsapp,
  cibWhenIWork,
  cibWii,
  cibWiiu,
  cibWikipedia,
  cibWindows,
  cibWire,
  cibWireguard,
  cibWix,
  cibWolframLanguage,
  cibWolframMathematica,
  cibWolfram,
  cibWordpress,
  cibWpengine,
  cibXPack,
  cibXcode,
  cibXbox,
  cibXero,
  cibXiaomi,
  cibXing,
  cibXrp,
  cibXsplit,
  cibYahoo,
  cibYammer,
  cibYCombinator,
  cibYandex,
  cibYarn,
  cibYelp,
  cibYoutube,
  cibZalando,
  cibZapier,
  cibZeit,
  cibZendesk,
  cibZerply,
  cibZillow,
  cibZingat,
  cibZoom,
  cibZorin,
  cibZulip,
  cibDeno,
  cibEpicGames,
  cibFlask,
  cibGerrit,
  cibGreensock,
  cibTravisci,
  cibApacheFlink,
  cibBabel,
  cibCodewars,
  cibGnu,
  cibComposer,
  cibElsevier
})
