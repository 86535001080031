import router from "@/router/index.js";
import store from "@/store/store";

import getWeb3 from "@/store/modules/Metamask/getWeb3";
// import getContract from "@/store/modules/Metamask/getContract";
import web3 from "web3";
import { ABI721, address721, addressMaket} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
const ERC721Module = {
    namespaced: true,
    state: {
        isMint:false,
        isApptoveShopAccess:false,
        isApptove:false,
        tokenId:null,
        objNfts: {
            isMint : 0,
            isRoyalty : 0,
            userAddress : ""
        }
    },

    mutations: {
        setIsMint(state, data){
            state.isMint = data
        },

        setTokenId(state, tokenId){
            state.tokenId = tokenId
        },

        setIsApptoveShopAccess(state, data){
            state.isApptoveShopAccess = data
        },

        setIsApptove(state, data){
            state.isApptove = data
        },

        setServiceNfts(state, data){
            state.objNfts = data
        }
    },

    actions: {

        onAllownInfoNft({ commit },obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)
            var contract = new web3.eth.Contract(ABI721, address721);
            contract.methods.allownInfo(obj.address)
                .call()
                .then((response) => {
                    var  obj = {};
                    obj.isMint = response.isMint
                    obj.isRoyalty = response.isRoyalty
                    obj.userAddress = response.userAddress
                    commit("setServiceNfts",obj)
                })
            // ;
        },

        onMintNft({ commit },obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            var contract = new web3.eth.Contract(ABI721, address721);

            contract.methods.mint(obj.address, obj.percentRoyalty)
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        var item = obj;
                        item.tokenId = response.events.Transfer.returnValues.tokenId

                        commit("setTokenId",item.tokenId);
                        commit("setIsMint",true)

                        store.dispatch('gateway/onUpdateNFTs',item)
                    }
                })
            // ;
        },

        onApprove({ commit },obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            var contract = new web3.eth.Contract(ABI721, address721);

            contract.methods.approve(addressMaket, obj.tokenId)
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        store.commit("eRCMarketPlace/setApproveNFT",obj.tokenId)
                        store.commit("eRCMarketPlace/setIsCrateOrdder",true)
                    }
                })
            // ;
        },



    },

    getters: {
        objIsMint(state){
            return state.isMint
        },
        objTokenId(state){
            return state.tokenId;
        },
        objIsApptoveShopAccess(state){
            return state.isApptoveShopAccess
        },
        objIsApptove(state){
            return state.isApptove
        },

        ObjServiceNfts(state){
            return state.objNfts
        }
    },
};

export default ERC721Module;
