<template>
  <div>

    <div v-if="collection">
      <CRow>
        <CCol>
          <CButton @click="onToggle" color="success"  class="pr-4 pl-4 text-white" shape="pill" variant="outline" > Create Collection </CButton>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol md="3">
          <CCard accent-color="success">
            <CCardBody style="padding: 0 0">
              <div>
                <CButton @click="onToggle" color="success" class="pr-1 pl-1 text-white position-absolute " variant="ghost" style="right: 5px; top:5px" >
                  <CIcon name="cil-pencil" />
                </CButton>
                <img class="w-100" :src="image" />
              </div>
              <CRow class="mt-3">
                <CCol class="h5 text-center">Title</CCol>
              </CRow>
              <CRow>
                <CCol class="h6 text-center">Description</CCol>
              </CRow>
              <CRow class="mb-3">
                <CCol class="text-center">item</CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <CCard  v-if="!collection" accent-color="success">
      <CCardHeader>
        <h5>{{ title }} your collection</h5>
      </CCardHeader>
      <CCardBody>
        <div>

          <CRow>
            <CCol>
              <h6>Logo image *</h6>
              <span>This image will also be used for navigation. 350 x 350 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success" style="width: 150px; height: 150px;"></div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Featured image</h6>
              <span>(optional) This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of NextART. 600 x 400 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success" style="width: 350px; height: 233px;"></div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Banner image</h6>
              <span>(optional) This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success" style="width: 550px; height: 157px;"></div>
            </CCol>
          </CRow>

          <CRow>
            <CCol col="12">
              <CInput label="Name" placeholder="Example: Treasures of the Sea" v-model="name" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CSelect label="Category" @update:value="onCategory" :options="categories"/>
            </CCol>
          </CRow>
          <CRow >
            <CCol class="mb-sm-2">
              <h6> Decription</h6>
            </CCol>
          </CRow>
          <CRow >
            <CCol >
              <quill-editor class="mb-3" :content="decription" @change="onEditorDecription"/>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Royalties</h6>
              <span>Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.</span>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol col="12">
              <CInput label="Percentage fee" type="number" placeholder="0.00" v-model="percentage" />
            </CCol>
          </CRow>

          <CRow class="mt-3" v-if="percentage>0">
            <CCol col="12">
              <CInput
                  label="Your payout wallet address"
                  type="text"
                  placeholder="Please enter an address, e.g. 0x1ed3... or destination.eth"
                  v-model="address"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Payment tokens</h6>
              <span>These tokens can be used to buy and sell your items.</span>
            </CCol>
          </CRow>

          <CRow class="mt-3" >
            <CCol col="3">
              <CCard color="light">
                <CCardBody>
                  <div class="float-left mr-4">
                    <img src="https://bscscan.com/token/images/binance_32.png"/>
                  </div>
                  <div class="float-left cil-volume-high">
                    <h6>BNB</h6>
                    <span>Binance</span>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol col="3">
              <CCard color="light" >
                <CCardBody>
                  <div class="float-left mr-4">
                    <img src="https://bscscan.com/token/images/binance_32.png"/>
                  </div>
                  <div class="float-left cil-volume-high">
                    <h6>WBNB</h6>
                    <span>Binance</span>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>

          </CRow>
        </div>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol>
            <CButton class="w-25 mr-3" size="md" color="success" variant="outline">CREATE</CButton>
          </CCol>
          <CCol class="text-right">
            <CButton @click="onToggle" class="w-25 mr-3" size="md" color="light" variant="outline">CANCEL</CButton>
            <CButton class="w-25" size="md" color="danger" variant="outline">DELETE</CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

export default {
  name: 'Collection',
  props:{
    title: {
      type: String,
      default: "Create"
    },
    // sharing: {}
  },
  data: function () {
    return {
      image:"https://image.shutterstock.com/mosaic_250/2389049/1365289022/stock-photo-a-surreal-image-of-an-african-elephant-wearing-black-and-white-zebra-stripes-1365289022.jpg",

      collection:true,
      categories:[],

      name:"",
      decription:"",
      percentage:0,
      address:"",
      selectCategorie:""
    }
  },
  mounted() {

  },

  created() {

    this.$store.dispatch("gateway/onCategorys")

    // this.$store.commit("metamask/setisApptove", false);
    // var body = {};
    // body.id = this.$route.query.id;
    // this.$store.dispatch('gateway/onMarketsById', body);

  },

  beforeDestroy() {

  },

  methods: {

    onCategory(value, e) {
      this.selectCategorie = value;
    },

    onEditorDecription(html, text, quill) {
      this.decription = html.html;
    },

    onToggle(){
      this.collection = !this.collection
    },

  },

  computed: {
    ...mapGetters({
      categorie: 'gateway/objCategorys'
    })
  },

  watch: {
    objMarketsById(value) {
      console.log("objMarketsById", value)

      // document.title = value.name;
      // document.images = value.image;
      // document.type = value.cataigory;
    },

    categorie(value) {

      var itemCategories = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {};
        obj.label = value[i].title;
        obj.value = value[i]._id;
        itemCategories.push(obj)
      }
      this.selectCategorie = value[0]._id;
      this.categories = itemCategories

    },


  }
}
</script>
