<template>
  <div>
    <CModal
      :show.sync="disable"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      color="success"

    >

      <template #header>
        <h6 class="modal-title">{{ (update == true)?'UPDATE ITEM':'CREATE ITEM'}}</h6>
        <CButtonClose @click="onUpdatePlan" class="text-white"/>
      </template>

      <template #body-wrapper>
        <div class="p-2 pl-4 pr-4">
          <div>
            <CRow class="mt-2 mb-2">
              <CCol>
                <h6>Image, Video, Audio, or 3D Model</h6>
                <span>File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 40 MB</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-center">
                <label for="input-vjs" class="border-white">
                  <div class="avatar-site2">
                    <img :src="url" class="img-avatar" alt="" width="100%">
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-vjs" type="file" accept="image/*" @change=uploadImage style="display: none;"/>
              </CCol>
            </CRow>

            <CRow>
              <CCol col="12">
                <CInput label="Name" placeholder="Example: Treasures of the Sea" v-model="name" />
              </CCol>
            </CRow>
            <CRow >
              <CCol class="mb-sm-2">
                <h6> Decription</h6>
              </CCol>
            </CRow>
            <CRow >
              <CCol >
                <quill-editor class="mb-3 bg-white text-dark" :content="description" @change="onEditorDecription"/>
              </CCol>
            </CRow>

            <CRow class="mb-2">
               <CCol>
                 <CButton variant="ghost" @click="onSelect"  class="text-white" >
                   <CIcon :name="(fully == true)?'cil-check-circle':'cil-circle'" class="mr-1 ml-1"/>
                 </CButton>
                 Fully commercial right is included
               </CCol>
            </CRow>

            <CRow class="mb-2">
              <CCol col="5">
                <CButton @click="onCreate" class="text-white" size="md" color="success" >{{ (update == true)?'UPDATE':'CREATE'}}</CButton>
              </CCol>
              <CCol col="7">
                <div class="text-right">
                  <CButton @click="onUpdatePlan" size="md" color="light" >CANCEL</CButton>
                  <CButton @click="onDelete" class="ml-2" v-if="update"  size="md" color="danger" >DELETE</CButton>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
      </template>

      <template #footer-wrapper>
        <div>

        </div>
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import CreateItem from "@/project/views/page/sub/CreateItem";
import {address721} from "@/store/modules/Metamask/constants/escrowContract";

Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'CreateNFT',
  components: {CreateItem},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    }
  },
  data: function () {
    return {
      darkModal: false,
      update:false,
      url: "img/upload/upload.png",
      fully:false,

      name:"",
      description:""


    }
  },
  mounted() {

    this.$store.dispatch('metamask/onMetaMaskAddress')
  },

  created() {
    // this.content = this.onCheck(this.values.fields)

    this.$store.commit('gateway/setComplate', false)
    try {
      console.log(this.info.nfts._id)
      if(this.info.nfts._id != null){
        this.update = true
        this.name = this.info.nfts.name
        this.description = this.info.nfts.description
        this.url = this.info.nfts.image
      }

    }catch (e) {

    }

    try {
      this.fully = this.info.nfts.fullyFommercialRight
    }catch (e) {

    }
  },

  beforeDestroy() {

  },

  methods: {
    uploadImage(e) {
      this.image = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.url = e.target.result;
        this.uploadImageState = true;
      };
    },

    onEditorDecription(html, text, quill) {
      this.description = html.html;
    },


    onCreate(){
      console.log(this.collections)
      if(this.update != true) {

        let data = new FormData();
        data.append("id", this.collections._id);
        data.append("onwer", this.collections.onwer._id);
        data.append("creator", this.collections.onwer._id);
        data.append('image', this.image, this.image.fileName);
        data.append("name", this.name);
        data.append("categorie", this.collections.categorie._id);
        data.append("description", this.description);
        data.append("fullyFommercialRight", this.fully);


        // console.log(this.collections)
        this.$store.dispatch('gateway/onCreateNFTs', data)


      }else{

        let data = new FormData();
        data.append("id", this.info.nfts._id);
        if(this.image != null){
          data.append('image', this.image, this.image.fileName);
        }
        data.append("name", this.name);
        data.append("description", this.description);
        data.append("fullyFommercialRight", this.fully);

        // console.log(this.collections)
        this.$store.dispatch('gateway/onUpdateNFTsItem', data)

      }
      // collection/:id/item

      // var obj = {};
      // obj.name = "";
      // obj.description = "";
      //
      // let data = new FormData();
      // data.append("creator", info._id);
      // data.append("onwer", info._id);
      // data.append('image', this.image, this.image.fileName);
      // data.append("name", this.objMint.name);
      // data.append("categorie", this.objMint.categories);
      // data.append("decription", this.objMint.decription);
      // data.append("contactAddress", address721);
      // data.append("priceInCreator", this.objMint.royalty);

    },


    onUpdatePlan() {
      this.disable = false;
      this.$emit("update:value",false)
    },

    onUpdatePlanSubmit() {

      if(this.update != true){
        this.$emit("onCreate",this.data)
      }else{

        this.info.item = this.data
        this.$emit("onUpdate",this.info)
      }
    },

    onDelete(){

      var data = {};
      data.id = this.info.nfts._id;
      // console.log(data)

      this.$store.dispatch('gateway/onDeleteNFTsItem', data)
    },
    onSelect(){
      this.fully = !this.fully
    }

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      complate: "gateway/objComplate"
      // stateDialog: "dialogCreate/onGetDialogLessonPlan",
      // editinfo : "dialogCreate/onGetDialogLessonPlanEdit"
    })
  },

  watch: {
    // editinfo(value){
    //   console.log("value",value)
    //   if(value != null){
    //     this.edit = true
    //     this.data = value.item
    //
    //
    //   }
    //
    // },
    // 'reserve.topics'(value){
    //   this.onCheckIndex(this.data.topics,value)
    // }

    complate(value){
      if(value == true){
        this.url = "img/upload/upload.png";
        this.fully = false;
        this.name = "";
        this.description = ""

        this.onUpdatePlan()
      }

    }
  }
}
</script>
