<template>
  <CContainer>
    <CRow  v-if="!header" class="justify-content-center  align-items-center min-vh-100">
      <CCol md="8">
        <div class="text-center">
          <p class="pt-3 font-2xl text-white suk">Sign in to your wallet.</p>
          <img src="@/assets/logo/metamask.png" width="30%"/>
          <p class="pt-3 font-lg text-white suk">Originalize your masterpiece</p>
        </div>
        <p class=" pl-3  font-lg text-white suk text-center">NFT Asset ● Copyright ● Figure Art</p>
        <CRow>
          <CCol class="text-center">
            <CButton class="pl-4 pr-4 text-white" color="success" size="md" shape="pill" @click="onConnext" >
              Connect Wallet
            </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <div v-if="header">
      <CButton class="pl-4 pr-4 text-white" color="success" size="md" shape="pill" @click="onConnext" >
        Connect Wallet
      </CButton>
    </div>
  </CContainer>

</template>

<script>

import {mapGetters} from 'vuex'
import Web3 from "web3";
export default {
  name: 'ConnectWallet',
  props : {
    header: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userMessage:"msg",
      web3: null,
      MetaMaskId: "1", // main net netID
      netID: "1", // user metamask id
      MetaMaskAddress: "", // user Address
      Web3Interval: null,
      AccountInterval: null,
      NetworkInterval: null,
      stateLog: null,
      isComplete: false,
      type: "INIT",
      MetamaskMsg: {
        LOAD_MATAMASK_WALLET_ERROR: "Loading metamask error, please try later",
        EMPTY_METAMASK_ACCOUNT: "Please log in to your metamask to continue with this app.",
        NETWORK_ERROR: "The connection is abnormal, please try again",
        METAMASK_NOT_INSTALL: "Please install metamask for this application",
        METAMASK_TEST_NET: "Currently not in the main network.",
        METAMASK_MAIN_NET: "Currently Main network",
        USER_DENIED_ACCOUNT_AUTHORIZATION: "User denied account authorization",
      },
    };
  },
  mounted() {
  },

  created() {

  },

  beforeDestroy() {

  },
  methods: {
    checkWeb3() {
      let web3 = window.web3;
      if (typeof web3 === "undefined") {
        this.web3 = null;
        this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
      }
    },
    checkAccounts() {
      if (this.web3 === null) return;
      this.web3.eth.getAccounts((err, accounts) => {

        // console.log("getAccounts",accounts)
        if (err != null)
          return this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
        if (accounts.length === 0) {
          this.MetaMaskAddress = "";
          this.Log(this.MetamaskMsg.EMPTY_METAMASK_ACCOUNT, "NO_LOGIN");
          return;
        }
        this.MetaMaskAddress = accounts[0]; // user Address
      });
    },
    checkNetWork() {

      this.web3.eth.net.getId().then(netID => {
        switch (netID) {
          case 1:
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "MAINNET");

          case 3:
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "ROPSTEN");

          case 42:
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "LOVAN");

          case 4:
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "RINKEBY");

          case 97:
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "TESTNET");

          default:
            return this.Log(this.MetamaskMsg.METAMASK_MAIN_NET, "MAINNET");

        }
      });

      // this.web3.version.getNetwork((err, netID) => {
      //
      //   console.log("checkNetWork",err,netID)
      //   // Main Network: 1
      //   // Ropsten Test Network: 3
      //   // Kovan Test Network: 42
      //   // Rinkeby Test Network: 4
      //   if (err != null)
      //     return this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
      //   this.netID = netID; //User MetaMask's current status
      //   if (this.MetaMaskAddress !== "" && netID === "1")
      //     return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "MAINNET");
      //   if (this.MetaMaskAddress !== "" && netID === "3")
      //     return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "ROPSTEN");
      //   if (this.MetaMaskAddress !== "" && netID === "42")
      //     return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "LOVAN");
      //   if (this.MetaMaskAddress !== "" && netID === "4")
      //     return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "RINKEBY");
      //   if (this.MetaMaskAddress !== "")
      //     this.Log(this.MetamaskMsg.METAMASK_MAIN_NET, "MAINNET");
      // });
    },
    Log(msg, type = "") {
      const letType = type;
      if (letType === this.type) return;
      const message = this.userMessage === "null" ? msg : this.userMessage;
      this.type = type;
      console.log("sss",{
        web3: this.web3,
        type,
        metaMaskAddress: this.MetaMaskAddress,
        message,
        netID: this.netID,
      })
      if(this.MetaMaskAddress.length > 0){
        var obj = {};
        obj.metamask = this.MetaMaskAddress.toLowerCase();
        this.$store.dispatch('gateway/onRegisterAddress', obj);

        // this.$store.dispatch('metamask/onMetaMaskAddress')
      }
      this.$emit("onComplete", {
        web3: this.web3,
        type,
        metaMaskAddress: this.MetaMaskAddress,
        message,
        netID: this.netID,
      });

    },
    web3TimerCheck(web3) {
      this.web3 = web3;
      this.checkAccounts();
      this.checkNetWork();
      this.Web3Interval = setInterval(() => this.checkWeb3(), 1000);
      this.AccountInterval = setInterval(() => this.checkAccounts(), 1000);
      this.NetworkInterval = setInterval(() => this.checkNetWork(), 1000);
    },

    async onConnext() {
      if (window.ethereum) {
        window.web3 = new Web3(ethereum);
        try {
          await ethereum.enable();
          this.web3TimerCheck(window.web3);
        } catch (error) {
          this.Log(this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION, "USER_DENIED_ACCOUNT_AUTHORIZATION");
        }
      } else if (window.web3) {
        window.web3 = new Web3(web3.currentProvider);
        this.web3TimerCheck(window.web3);
      } else {
        this.web3 = null;
        this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
        console.error("Non-Ethereum browser detected. You should consider trying MetaMask!"
        );
      }
    }


  },


  computed: {
    ...mapGetters({
      profile:'gateway/objProfile',
    })
  },
  watch: {
    profile(value) {

    }
  }
};
</script>
