<template>
  <div>
    <CRow>

    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
export default {
  name: 'CreateTQF',
  data: function () {
    return {
      data: {}

    }
  },
  mounted() {

  },

  created() {

  },

  beforeDestroy() {

  },

  methods: {},

  computed: {
    ...mapGetters({
      lang: "setting/objLang",

    })
  },
  watch: {}
}
</script>
