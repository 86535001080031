import router from "@/router/index.js";
import store from "@/store/store";

import getWeb3 from "@/store/modules/Metamask/getWeb3";
// import getContract from "@/store/modules/Metamask/getContract";
import web3 from "web3";
import {ABI, ABIBid, address, addressBid, addressUSDT} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";

const MetamaskModule = {
    namespaced: true,
    state: {
        MetaMaskAddress: "",
        balance:"",
        isApptove: false,
        tokenNext: 0,
        tokenUSDT: 0,
        complate:false

    },

    mutations: {
        onSetMetaMaskAddress(state, data) {
            state.MetaMaskAddress = data
        },

        setBalance(state, data){
            state.balance = data
        },

        setisApptove(state, data) {
            state.isApptove = data
        },

        setTokenNEXT(state, data) {
            state.tokenNext = data
        },

        setTokenUSDT(state, data) {
            state.tokenUSDT = data
        },

        setComplate(state, data) {
            state.complate = data
        },
    },

    actioncs: {
        onConnect({commit}) {
            getWeb3.then(result => {

                commit('onSetMetaMaskAddress', result.coinbase[0].toLowerCase())
                commit('setBalance', result.balance);

                // var data = {};
                // data.metamask = result.coinbase[0];
                // store.dispatch('gateway/onRegisterAddress', data);


                // var obj = {};
                // obj.metamask = result.coinbase[0].toLowerCase();
                // store.dispatch('gateway/onProfile',obj)


                // var obj = {};
                // obj.contactAddress = address;
                // obj.contact = "setTokenNEXT"
                // store.dispatch('metamask/getBalanceOf', obj)

                // var obj = {};
                // obj.contactAddress = addressUSDT;
                // obj.contact = "setTokenUSDT"
                // store.dispatch('metamask/getBalanceOf', obj)

            }).catch(e => {
                // console.log('error in action registerWeb3', e)
            })

        },
        onMetaMaskAddress({commit}) {
            getWeb3.then(result => {

                commit('onSetMetaMaskAddress', result.coinbase[0].toLowerCase())
                commit('setBalance', result.balance);

                // var data = {};
                // data.metamask = result.coinbase[0];
                // store.dispatch('gateway/onRegisterAddress', data);


                // var obj = {};
                // obj.metamask = result.coinbase[0].toLowerCase();
                // store.dispatch('gateway/onProfile',obj)


                // var obj = {};
                // obj.contactAddress = address;
                // obj.contact = "setTokenNEXT"
                // store.dispatch('metamask/getBalanceOf', obj)

                // var obj = {};
                // obj.contactAddress = addressUSDT;
                // obj.contact = "setTokenUSDT"
                // store.dispatch('metamask/getBalanceOf', obj)

            }).catch(e => {
                // console.log('error in action registerWeb3', e)
            })

        },


        getBalanceOf({commit}, obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)
            var contract = new web3.eth.Contract(ABI, obj.contactAddress);
            contract.methods.balanceOf(store.getters['metamask/onGetMetaMaskAddress'])
                .call()
                .then((response) => {
                    const formatCash = n => {
                        if (n < 1e3) return n;
                        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                        if (n >= 1e18) return +(n / 1e18).toFixed(2);
                    };
                    // let amt = new BN(1e18, response)
                    // console.log(amt,BigInt(response))


                    // let decimals = web3.utils.toBN(18);
                    // let amount = web3.utils.toBN(obj.value);


                    const balanceWeiString = response
                    const balanceWeiBN = new web3.utils.toBN(balanceWeiString)

                    const decimalsBN = web3.utils.toBN(18)
                    const divisor = new web3.utils.toBN(10).pow(decimalsBN)

                    const beforeDecimal = balanceWeiBN.div(divisor)
                    const afterDecimal  = balanceWeiBN.mod(divisor)


                    var value = beforeDecimal.toString()+"."+afterDecimal.toString().slice(0, 4);
                    commit(obj.contact, value)

                    // commit(obj.contact, formatCash(response))
                })
            // ;
        },
        onContractApprove({commit}, obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            let decimals = web3.utils.toBN(18);
            let amount = web3.utils.toBN(obj.value);
            let value = amount.mul(web3.utils.toBN(10).pow(decimals));

            let contract = new web3.eth.Contract(ABI, obj.contactAddress);
            contract.methods.approve(obj.spender,value)
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        commit("setisApptove", true)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            // ;
        },
        onSwapToken({commit}, obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            let decimals = web3.utils.toBN(18);
            let amount = web3.utils.toBN(obj.value);
            let value = amount.mul(web3.utils.toBN(10).pow(decimals));

            console.log("value",value.toString())
            let contract = new web3.eth.Contract(ABI, address);

            contract.methods.swap(obj.contactAddress, value)
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        dispatch('onMetaMaskAddress');
                        commit("setComplate", true);

                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        onBid({commit}, obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            let decimals = web3.utils.toBN(18);
            let amount = web3.utils.toBN(obj.value);
            let value = amount.mul(web3.utils.toBN(10).pow(decimals));

            console.log("value",value.toString())
            let contract = new web3.eth.Contract(ABIBid, addressBid);
            console.log(contract.methods)

            contract.methods.bid()
                .send({from: store.getters['metamask/onGetMetaMaskAddress'],value:value})
                .then((response) => {
                    if(response.status == true){
                        dispatch('onMetaMaskAddress');
                        commit("setComplate", true);

                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },

    getters: {
        onGetMetaMaskAddress(state) {
            return state.MetaMaskAddress
        },

        objBalance(state){
            return state.balance;
        },
        objisApptove(state) {
            return state.isApptove
        },
        objTokenNext(state) {
            return state.tokenNext
        },
        objTokenUSDT(state) {
            return state.tokenUSDT
        },
        objComplete(state) {
            return staate.complate
        }

    },
};

export default MetamaskModule;
