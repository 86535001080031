<template>
  <div>
    <CRow>
      <CCol>
        <div class="bg-warning">

          <div id="demo">
            <vue-metamask
              userMessage="msg"
              @onComplete="onComplete"
            >
            </vue-metamask>
          </div>

        </div>

      </CCol>
    </CRow>
  </div>
</template>

<script>
import VueMetamask from 'vue-metamask';
export default {
  name: 'metamask',
  components: {
    VueMetamask,
  },
  data(){
    return {
      msg: "This is demo net work"
    }
  },
  methods:{
    onComplete(data){
      console.log('data:', data);
      // var obj = {};
      // obj.metaMaskAddress = data.metaMaskAddress;

      var obj = {};
      obj.metamask = data.metaMaskAddress;

      this.$store.dispatch('gateway/onRegisterAddress', obj);

      this.$store.dispatch('metamask/onMetaMaskAddress')


      // this.$emit("data:value",obj)
    }
  }

}
</script>
