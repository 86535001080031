<template>
  <div>
    <CModal
      :show.sync="disable"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      color="success"
    >

      <template #header>
        <h6 class="modal-title"><CIcon name="cil-gift" class="mr-1 ml-1"/>{{ (update == true)?'UPDATE ITEM':'TRANFER ITEM'}}</h6>
        <CButtonClose @click="onCancel" class="text-white"/>
      </template>

      <template #body-wrapper>
        <div class=" mt-2 pl-4 pr-4">
          <CRow>
            <CCol col="12">
              <CInput
                  label="To Address "
                  type="text"
                  v-model="toAddress">
<!--                <template #append>-->
<!--                  <CButton :disabled="false"  size="md" color="success" class="text-white pl-4" >SEND NOW</CButton>-->
<!--                </template>-->
              </CInput>

            </CCol>
          </CRow>
        </div>
      </template>

      <template #footer>
<!--        <CButton @click="onCancel"  size="md" color="light" >CANCEL</CButton>-->
        <CButton @click="onCreateAndUpdate" class="mr-3 text-white" size="md" color="success" >{{ (update == true)?'UPDATE':'SEND NOW'}}</CButton>

<!--        <CRow>-->
<!--          <CCol>-->
<!--            <CButton @click="onCancel"  size="md" color="light" >CANCEL</CButton>-->
<!--          </CCol>-->
<!--          <CCol>-->
<!--            <CButton @click="onCancel" class="mr-3 text-white" size="md" color="success" >{{ (update == true)?'UPDATE':'CREATE'}}</CButton>-->
<!--          </CCol>-->
<!--        </CRow>-->
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import {address721} from "@/store/modules/Metamask/constants/escrowContract";


// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'TranferNfts',
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    collections: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    }
  },
  data: function () {
    return {
      update:false,
      toAddress:""
    }
  },
  mounted() {

  },

  created() {

    try {
      if(this.info.index != null){
        this.update = false;
        this.data.domain = this.info.item.domain
      }

    }catch (e) {

    }
  },

  beforeDestroy() {

  },

  methods: {

    onCancel(){
      this.disable = false;
      this.$emit("cancel",false)
    },

    onCreateAndUpdate(){
      this.disable = false;
      if(this.update == true){
        this.$emit("update:value",this.data)
      }else{
        var obj = {};
        obj.address = this.toAddress.toLowerCase();
        this.$emit("create:value",obj)
      }
    },

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
    })
  },

  watch: {

  }
}
</script>
