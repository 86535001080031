import Vue from "vue";
import Vuex from "vuex";
import Service from "@/service/api.js";
import router from "../../../router/index.js";
import store from "@/store/store";

const SelectCourseModule = {
    namespaced: true,
    state: {
      lang:"th",
      course: {},
      academicYear:'',
      semester:'',
      preRequest:[],
      studyRequest:[],
      reference: []

    },

    mutations: {

      setAcdemicYear(state, obj) {
        state.academicYear = obj.academicYear;
        state.semester = obj.semester;
      },

      setCours(state, obj) {
        state.course = obj;
      },

      setPreRequests(state, obj) {
        state.preRequest = obj;
      },

      setStudyRequests(state, obj) {
        state.studyRequest = obj;
      },

      setReference(state, obj) {
        state.reference = obj;
      },

    },

    actions: {


    },

    getters: {

      objAcdemicYear(state) {
        var obj = {};
        obj.academicYear = state.academicYear
        obj.semester = state.semester

        return obj;
      },


      objSelectCours(state) {
        return state.course;
      },

      objSelectPreRequest(state) {
        return state.preRequest;
      },

      objSelectStudyRequest(state) {
        return state.studyRequest;
      },

      objReference(state) {
        return state.reference;
      },

    },
};

export default SelectCourseModule;
