<template>
  <div>
    <connect-wallet v-if="!(this.$store.getters['gateway/objProfile'].metamask.length > 0)" :disable="!(this.$store.getters['gateway/objProfile'].metamask.length > 0)" />
    <CRow class="mb-3" v-if="(this.$store.getters['gateway/objProfile'].metamask.length > 0)" :disable="(this.$store.getters['gateway/objProfile'].metamask.length > 0)">
      <CCol class="text-right">
        <div>
          <div class="float-right">
            <CButton @click="onCreateCollection" class=" text-white" shape="pill" color="success" >Create Collection</CButton>
          </div>
<!--          <div  class="float-right  mr-4 h2"> You can create it &ndash;&gt;</div>-->
        </div>
      </CCol>
    </CRow>
    <div v-if="show">
      <CRow >
        <CCol sm="6" md="4" lg="4" xl="3" v-for="(item, index) in collection" v-bind:key="index">
          <ItemCollections :item="item" @update:value="onEditCollection" @infomation:value="onInfoMation" />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ItemCollections from "@/project/views/util/ItemCollections";
import CreateNFT from "@/project/views/page/dialog/CreateNFT";
import ItemAssets from "@/project/views/util/ItemAssets";
import ConnectWallet from "@/project/views/page/sub/ConnectWallet";

Vue.use(VueQuillEditor)
export default {
  name: 'Creates',
  components: {ConnectWallet, ItemAssets, CreateNFT, ItemCollections},
  data: function () {
    return {
      show:true,
      addNFT:false,
      tokens:this.$store.getters['gateway/objProfile'].metamask,
      connect:false,
      collections: {
        banner:"",
        logo:"",
        name:"",
        description:"",
        item:[],
        categorie:{
          title:""
        },

      },
      categories:[],
      name:"",
      decription:"",
      percentage:0,
      address:"",
      selectCategorie:""
    }
  },
  mounted() {
    this.$store.dispatch("gateway/onCategorys")
  },

  created() {
    this.$store.dispatch('metamask/onMetaMaskAddress')
  },

  beforeDestroy() {

  },

  methods: {

    onConnectMataMaskAddress(){

    },

    onBlack(value, e) {
      this.show = true;
    },

    onAddNFT(value, e) {
      this.addNFT = true;
      console.log(value)
      // this.$router.push({name:'AddNFT', query: {id:value._id} })

    },

    onEditNFT(value, e){
      this.addNFT = false;
    },



    onCategory(value, e) {
      this.selectCategorie = value;
    },

    onEditorDecription(html, text, quill) {
      this.decription = html.html;
    },

    onGetCollection(){
      var obj = {};
      obj.onwer = this.profile._id;
      this.$store.dispatch('gateway/onCollectionByConnect', obj);
    },

    onGetProfile(){
      var body = {};
      body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('gateway/onProfile',body)
    },

    onEditCollection(value, e){
      this.$router.push({name:'create',  params: value })
    },

    onCreateCollection(){
      this.$router.push({name:'create' })
    },

    onInfoMation(value, e){
      this.$router.push({name:'info',  query: {id:value._id} })
    }
  },

  computed: {
    ...mapGetters({
      categorie: 'gateway/objCategorys',
      profile:'gateway/objProfile',
      collection:'gateway/objCollections',
      // objMarketsById:'gateway/objMarketsById',
      // isApptoveBuyNft: "metamask/objisApptove",
    })
  },

  watch: {
    categorie(value) {

      var itemCategories = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {};
        obj.label = value[i].title;
        obj.value = value[i]._id;
        itemCategories.push(obj)
      }
      this.selectCategorie = value[0]._id;
      this.categories = itemCategories

    },

    profile(value){
      this.connect = true;
      this.onGetCollection();
    }
  }
}
</script>
