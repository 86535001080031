<template>
  <div>

    <CCard accent-color="success" >
      <CCardBody style="padding: 0 0" @click="onInfoMation">
        <div>
          <CButton v-if="this.$store.getters['gateway/objProfile']._id == item.onwer._id" @click="onEdit" color="secondary" class="pr-1 pl-1 text-white position-absolute " style="right: 5px; top:5px" >
            <CIcon name="cil-pencil" class="mr-1 ml-1"/>
          </CButton>
          <img class="w-100 bg-gray-500"  :src="item.featured" height="150px"/>
        </div>
        <div class="avatar-site2 text-center" style="margin-top: -40px">
          <img class="bg-gray-500" :src="item.logo"  alt="" width="80px" height="80px" style="border-radius: 40px; border-style: solid;">
        </div>
        <CRow class="mt-3">
          <CCol class="h5 text-center">{{ item.name }}</CCol>
        </CRow>
        <CRow>
          <CCol class="h6 text-center mr-2 ml-2">
            <div v-html="item.description" style="overflow-y: auto;  height: 8em;"></div>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol class="text-center">{{item.item.length}} ITEM</CCol>
        </CRow>

        <CRow class="mb-3">
          <CCol class=" text-center">Category : {{ item.categorie.title }}</CCol>
        </CRow>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>

import FlipCountdown from "@/project/containers/utils/FlipCountdown";
export default {
  name: 'ItemCollections',
  components: {FlipCountdown},
  props:{
    item: {},
    market: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }

  },
  data: function () {
    return {
      image:"https://image.shutterstock.com/mosaic_250/2389049/1365289022/stock-photo-a-surreal-image-of-an-african-elephant-wearing-black-and-white-zebra-stripes-1365289022.jpg",
      views:false
    }
  },
  mounted () {

  },

  created () {
  },

  beforeDestroy () {

  },

  methods: {

    onEdit(){
      this.$emit('update:value', this.item)
    },
    onInfoMation(){
      this.$emit('infomation:value', this.item)
    },
  },
}
</script>
