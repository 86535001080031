import Vue from "vue";
import Vuex from "vuex";
import Service from "@/service/api.js";
import router from "../../../router/index.js";
import store from "@/store/store";

const LoginModule = {
    namespaced: true,
    state: {
      lang:"th",
    },

    mutations: {
      setLang(state, obj) {
        state.lang = !obj.lang;
      },

    },

    actions: {


    },

    getters: {
      objLang(state) {
        return state.lang;
      },
    },
};

export default LoginModule;
