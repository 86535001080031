import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import Setting from './modules/Authentication/Setting.js'
import Login from './modules/Authentication/Login.js'
import DialogCreate from "./modules/DialogCreate/DialogCreate";
import DialogMessages from "./modules/DialogMessages";
import Calendars from "./modules/Management/Calendar"

import Course from './modules/course/Course.js'
import Instructors from './modules/Authentication/Instructors'


import Gateway from './modules/Gateway/Gateway'
import Metamask from './modules/Metamask/Metamask'
import ERC721 from './modules/Metamask/ERC721'
import ERC20 from "@/store/modules/Metamask/ERC20";
import ERCMarketPlace from './modules/Metamask/ERCMarketPlace'
import ERCMarkets from "@/store/modules/Metamask/ERCMarkets";

import NextArt from "@/store/modules/NextArt/NextArts";

//

import SelectCourseModule from "@/store/modules/course/SelectCourse";

const state = {
  sidebarShow: false,
  sidebarMinimize: false,
  asideShow: false,
  darkMode: true,
  xid:""
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  setXid(state, variable) {
    state.xid = variable
  }
}


export default new Vuex.Store({
  state,
  mutations,
  modules : {
    setting:Setting,
    dialog:DialogMessages,
    auth : Login,
    course : Course,
    dialogCreate : DialogCreate,
    calendars : Calendars,
    instructors : Instructors,

    setUp:SelectCourseModule,

    gateway:Gateway,
    nextArt:NextArt,
    metamask:Metamask,
    erc721:ERC721,
    erc20:ERC20,
    eRCMarkets:ERCMarkets
  }
});
