<template>
  <div>
    <CCard accent-color="success">
      <CCardHeader>
        <h5>Create new item</h5>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow>
            <CCol>
              <h6>Image, Video, Audio, or 3D Model</h6>
              <span>File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 40 MB</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="text-center">
              <label for="input-vjs" class="border-white">
                <div class="avatar-site2">
                  <img :src="url" class="img-avatar" alt="" width="100%">
                  <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                    <i class="icon-camera icons font-4xl d-block mt-4"></i>
                  </div>
                </div>
              </label>
              <input id="input-vjs" type="file" accept="image/*" @change=uploadImage style="display: none;"/>
            </CCol>
          </CRow>

          <CRow>
            <CCol col="12">
              <CInput label="Name" placeholder="Example: Treasures of the Sea" v-model="name" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CSelect label="Category" @update:value="onCategory" :options="categories" v-model="selectCategorie"/>
            </CCol>
          </CRow>
          <CRow >
            <CCol class="mb-sm-2">
              <h6> Decription</h6>
            </CCol>
          </CRow>
          <CRow >
            <CCol >
              <quill-editor class="mb-3" :content="description" @change="onEditorDecription"/>
            </CCol>
          </CRow>

        </div>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol>
            <CButton @click="onCreate" class="w-25 mr-3" size="md" color="success" variant="outline" >{{ (update == true)?'UPDATE':'CREATE'}}</CButton>
          </CCol>
          <CCol class="text-right">
            <CButton @click="onGoTo('list')" class="w-25" size="md" color="light" variant="outline">CANCEL</CButton>
            <CButton v-if="update" class="w-25 ml-3" size="md" color="danger" variant="outline">DELETE</CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)
export default {
  name: 'CreateItem',
  components: {},
  props:{
    item: Object
  },
  data: function () {
    return {
      update:false,

      url: "img/upload/upload.png",


      id:0,
      categories:[],
      name:"",
      description:"",
      percentage:0,
      address:"",
      selectCategorie:""
    }
  },
  mounted() {
    this.$store.dispatch("gateway/onCategorys")
  },

  created() {


    if(this.$route.params._id != undefined){
      console.log("item",this.$route.params)
      this.update = true;
      this.name = this.$route.params.name;
      this.description = this.$route.params.description;
      this.percentage = this.$route.params.royalties.percentage;
      this.address = this.$route.params.royalties.address;
    }


  },

  beforeDestroy() {

  },

  methods: {

    uploadImage(e) {
      this.image = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.url = e.target.result;
        this.uploadImageState = true;
      };
    },



    onGoTo(path){
      this.$router.push({name:path})
    },

    onCategory(value, e) {
      this.selectCategorie = value;
    },

    onEditorDecription(html, text, quill) {
      this.description = html.html;
    },

    onCreate(){
      var obj = {};
      obj.onwer = this.profile._id;
      obj.name = this.name;
      obj.description = this.description;
      obj.categorie = this.selectCategorie;

      var objRoyalties = {};
      objRoyalties.address = this.address;
      objRoyalties.percentage = this.percentage;
      obj.royalties = objRoyalties;

      if(this.update == true){
        obj._id = this.$route.params._id;
        this.$store.dispatch('gateway/onUpdateCollection', obj);
      }else{
        this.$store.dispatch('gateway/onCreateCollection', obj);
      }
    },

    onGetProfile(){
      var body = {};
      body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('gateway/onProfile',body)
    },

  },

  computed: {
    ...mapGetters({
      categorie: 'gateway/objCategorys',
      profile:'gateway/objProfile'
      // objMarketsById:'gateway/objMarketsById',
      // isApptoveBuyNft: "metamask/objisApptove",
    })
  },

  watch: {
    categorie(value) {

      var itemCategories = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {};
        obj.label = value[i].title;
        obj.value = value[i]._id;
        itemCategories.push(obj)
      }
      this.selectCategorie = value[0]._id;
      this.categories = itemCategories

    },

    profile(value){
      console.log(value)
    }
  }
}
</script>
