<template>
  <CCard accent-color="success"  >
    <CCardBody style="padding: 0 0" >
<!--      <item-nfts :item="item" :market="market" @click="onInfoMation"/>-->
      <div class="position-relative  overflow-auto" style="height: 300px;" >
        <CButtonGroup v-if="edit" class="pr-1 pl-1 text-white position-absolute" style="right: 5px; top:5px" @click="onEditNfts">
            <CButton  color="secondary" class="text-white" >
              <CIcon name="cil-pencil" class="mr-1 ml-1"/>
            </CButton>
        </CButtonGroup>
        <div class="overflow-auto m-auto text-center" @click="onInfoMation">
          <img class="w-100" :src="item.nfts.image" />
        </div>
      </div>
      <div class="p-4">
<!--        {{item.nfts.onwer._id}}-->
        <CRow >
          <CCol col="12" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
            <h5 class="text-nowrap font-weight-bold">{{ item.nfts.name }}</h5>
          </CCol>
        </CRow>
        <CRow>
          <CCol>

            <!--          <div class="mb-2">-->
            <!--            <span class="font-weight-bold"> Token ID :  </span>-->
            <!--            <span class="text-white">{{item.nfts.tokenId}}</span>-->
            <!--          </div>-->

            <!--          <div class="mb-2">-->
            <!--            <span class="font-weight-bold"> Create Date :  </span>-->
            <!--            <span class="text-white">{{moment(item.nfts.createDate).add('-7','hour').format('YYYY-MM-DD HH:mm:ss')}}</span>-->
            <!--          </div>-->

            <!--          <div class="mb-2">-->
            <!--            <span class="font-weight-bold"> Royalty :  </span>-->
            <!--            <span class="text-white"></span>-->
            <!--          </div>-->


            <CRow>
              <CCol style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><span class="font-weight-bold"> {{ (item.nfts.bidding == true)?"BID ":"Price" }} :  </span></CCol>
              <CCol class="text-right" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><span class="text-white">{{item.nfts.price}} {{ (item.nfts.bidding == true)?"WBNB":"BNB"}}</span></CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="4">
            <div class="c-avatars-stack mt-2">
              <div class="c-avatar c-avatar-xs" v-c-tooltip.hover.click="item.nfts.onwer.displayName">
                <img
                    @error="setAltImg"
                    width="40px" height="40px" style="border-radius: 20px; "
                    :src="item.nfts.onwer.userinfo.image"
                    alt="admin@bootstrapmaster.com"
                    loop=infinite
                >
              </div>
            </div>
          </CCol>

          <CCol class="text-right mt-2" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
            <div class="float-right">
              <CButton color="transparent p-0" class="float-left mr-2"  @click="onLike">
                <span class="mr-2 text-white">{{(item.nfts.like != null)?item.nfts.like.length:0}}</span>
                <CIcon  size="lg" name="cil-heart" class="text-white"/>
              </CButton>
              <div class="float-left" >
                <!--              <sharing :sharing="sharing"/>-->
              </div>
            </div>
          </CCol>
        </CRow>

      </div>
    </CCardBody>
  </CCard>
</template>

<script>

import Sharing from "@/project/views/util/sharing";
import FlipCountdown from "@/project/containers/utils/FlipCountdown";
import ItemNfts from "@/project/views/util/ItemNfts";
import {mapGetters} from "vuex";
import store from "@/store/store";
export default {
  name: 'ItemAssets',
  components: {ItemNfts, FlipCountdown, Sharing},
  props:{
    item: {},
    edit:false
    // sharing: {}
  },
  data: function () {
    return {
      dateTime:0,
      sharing: {
        url: 'https://news.vuejs.org/issues/180',
        title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        hashtags: 'vuejs,vite,javascript',
        // twitterUser: 'youyuxi'
      },
      isApprove:false,
      isCreateOrder:true
    }
  },
  mounted () {

  },

  created () {

  },

  beforeDestroy () {

  },

  methods: {
    setAltImg(event) {
      // event.target.src = "https://i.pinimg.com/originals/e9/34/47/e934478b9d5a4753bda195db8fb5eb8b.gif"
      event.target.src = "img/token/nextart,png"
    },
    onLike(){

      var obj = {};
      obj.id = this.item.nfts._id;

      var by = {}
      by.by = this.$store.getters['gateway/objProfile']._id;
      obj.like = by
      this.$store.dispatch('gateway/onNFTLikeById', obj);

      this.$emit('update:value', this.item)
    },
    onInfoMation(){
      this.$emit('infomation:value', this.item)
    },
    onEditNfts(){
      this.$emit('edit:value', this.item)
    }

  },


  computed: {
    ...mapGetters({

    })
  },

  watch: {
    'item.priceInWen'(value){
      this.onCheckIsApprove();
    },
    'item.expiresAt'(value){
      this.dateTime = new Date(value).getTime() / 1000;
      this.onCheckIsApprove();
    },
    isApproveId(value){
      console.log(value)
      if(value == this.item.tokenId){
        this.isCreateOrder = true
      }
    }


  }
}
</script>
