import router from "@/router/index.js";
import store from "@/store/store";

import getWeb3 from "@/store/modules/Metamask/getWeb3";
// import getContract from "@/store/modules/Metamask/getContract";
import web3 from "web3";
import {ABI, ABI721, address, address721, addressMaket} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
const ERC20 = {
    namespaced: true,
    state: {
        MetaMaskAddress:'',
        isApptove:false,
    },

    mutations: {
      // onSetMetaMaskAddress(state, data){
      //   state.MetaMaskAddress = data
      // },

        setisApptove(state, data){
            state.isApptove = data
        }
    },

    actions: {
        // onMetaMaskAddress({commit}){
        //     getWeb3.then(result => {
        //         console.log('committing result to registerWeb3Instance mutation')
        //         // commit('registerWeb3Instance', result)
        //         console.log(result)
        //
        //     }).catch(e => {
        //         console.log('error in action registerWeb3', e)
        //     })
        //
        // },
        // getContractInstance({ commit },obj) {
        //     let web3 = window.web3
        //     web3 = new Web3(web3.currentProvider)
        //
        //     let decimals = web3.utils.toBN(18);
        //     let amount = web3.utils.toBN(obj.tokenForm.total);
        //     let value = amount.mul(web3.utils.toBN(10).pow(decimals));
        //
        //     let contract = new web3.eth.Contract(ABI, obj.tokenForm.contactAddress);
        //
        //     contract.methods.approve(obj.tokenForm.contactAddress, value)
        //         .send({from: "0xe7c5E227cF0C1f95A8e2F63911BCaf66bD916708"})
        //         .on('error', function(error){
        //             console.log("-> error")
        //             console.log(error.message)
        //         })
        //         .on('transactionHash', function(transactionHash){
        //             console.log("-> transactionHash")
        //             console.log(transactionHash)
        //         })
        //         .on('receipt', function(receipt){
        //             console.log("-> receipt")
        //             console.log(receipt.contractAddress) // contains the new contract address
        //         })
        //         .on('confirmation', function(confirmationNumber, receipt){
        //             console.log("-> confirmation " + confirmationNumber)
        //         })
        //     ;
        // },
        onSwapToken({ commit },obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            let decimals = web3.utils.toBN(18);
            let amount = web3.utils.toBN(obj.tokenForm.total);
            let value = amount.mul(web3.utils.toBN(10).pow(decimals));

            let contract = new web3.eth.Contract(ABI, obj.tokenForm.contactAddress);

            contract.methods.swap(obj.tokenForm.contactAddress, value)
                .send({from: "0xe7c5E227cF0C1f95A8e2F63911BCaf66bD916708"})
                .on('transactionHash', function(hash){
                    console.log(hash);
                    // web3.eth.getTransaction(hash).then(console.log);
                    commit("setisApptove",true)
                })
            ;
        },


        onApptove({ commit },obj) {

            store.commit("dialog/onLoading", true);
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            var contract = new web3.eth.Contract(ABI, address);

            contract.methods.approve(addressMaket, web3.utils.toWei(obj.total, 'ether'))
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        commit("setisApptove",true)

                    }else{
                        store.commit("dialog/onLoading", false);
                    }
                }).catch((err) => {
                    store.commit("dialog/onLoading", false);
                })
        },
    },

    getters: {

      objisApptove(state){
          return state.isApptove
      }
    },
};

export default ERC20;
