import Service from "@/service/api.js";
import store from "@/store/store";

const InstructorModule = {
    namespaced: true,
    state: {
      instructor : []
    },

    mutations: {
      setInstructor(state, obj) {
        state.instructor = obj;
      },



    },

    actions: {
        onInstructor({commit}, body) {
            Service.instructor(body)
                .then((response) => {
                  var obj = response.data.data
                  commit("setInstructor", obj)
                }).catch((err) => {
                    store.dispatch("dialog/onHandleError", err)
                });
        },



    },

    getters: {
      objInstructor(state) {
        return state.instructor;
      },

    },
};

export default InstructorModule;
