<template>
  <CCard accent-color="secondary">
    <CCardHeader v-if="!icon">
      <slot name="header" >
        <CIcon v-if="icon" :name="icon"/> <span class="font-weight-bold">{{caption}}</span>
        <div class="card-header-actions">
          <CButton size="sm" color="success" shape="pill" variant="outline"> <CIcon name="cil-library-add"/> </CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody class="p-0">
      <CDataTable
        :hover="hover"
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >

        <template #no="{index,item}">
          <td>
            <div class="text-center">{{index+1}}</div>
          </td>
        </template>

        <template #by="{index,item}">
          <td>
            <div class="text-center">
              {{item.by.displayName}}

              <CButton @click="onCancelOffer(item)" v-if="onCheckOnwerByOffer(item.by._id)" class="ml-2" color="success"  shape="pill" variant="outline">
                <span class="text-white"> Cancel </span>
              </CButton>

              <CButton @click="onApprove(item)" v-if="onCheckOnwer(onwerNft)" class="ml-2" color="success"  shape="pill" variant="outline">
                <span class="text-white" > Approve</span>
              </CButton>
            </div>
          </td>
        </template>

        <template #event="{index,item}">
          <td>
            <div class="text-left"> <CIcon :name="onCheckIcon(item.event)" class="mr-1 ml-1"/> {{ item.event }}</div>
          </td>
        </template>

        <template #fromAddress="{index,item}">
          <td>
            <div class="text-center">
              {{ onCheckDisplayName(item.fromAddress) }}
            </div>
          </td>
        </template>

        <template #toAddress="{index,item}">
          <td>
            <div class="text-center">
              {{onCheckDisplayName(item.toAddress)}}
            </div>
          </td>
        </template>



        <template #price="{index,item}">
          <td>
            <div class="text-right">{{item.price}} {{onCheckType(item.event)}}</div>
          </td>
        </template>


        <template #createDate="{item}">
          <td>
            <div class="text-center">{{ moment(item.createDate).format('YYYY-MM-DD HH:mm:ss')}}</div>
          </td>
        </template>

<!--        <template #by="{item}">-->
<!--          <td>-->
<!--            <div>{{item}}</div>-->
<!--            <div>{{// moment(item.expiresAt).add('-7','hour').format('YYYY-MM-DD HH:mm:ss')}}</div>-->
<!--          </td>-->
<!--        </template>-->
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'TableListOffer',
  props: {
    items: Array,
    fields: {
      type: Array,
    },
    icon: {
      type: String,
      default: 'cil-user'
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    onwerNft: {
      type: String,
      default: 'Table'
    },
  },
  methods: {
    onCheckDisplayName(value){
      try {
        return value.displayName
      }catch (e) {
        return ""
      }

    },
    onCheckOnwerByOffer(value){
      if(value == this.$store.getters['gateway/objProfile']._id){
        if( this.onwerNft == value){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    },
    onCheckOnwer(value){
      if(value == this.$store.getters['gateway/objProfile']._id){
        return true;
      }else{
        return false;
      }
    },
    onCheckIcon(value){
      switch (value){
        case "MINTED":
          return "cil-satelite";
        case "BID":
          return "cil-hand-point-up";
        case "APPROVE":
            return "cil-hand-point-up";
        case "LIST":
          return "cil-tags";
        case "TRANSFER":
          return "cil-gift";
        case "SALE":
          return "cil-satelite";
        default:
          return "cil-infinity"

      }
    },
    onCheckType(value){
      switch (value){
        case "BID":
          return "WBNB";
        case "LIST":
          return "BNB";
        case "APPROVE":
          return "WBNB";
        case "TRANSFER":
            return "BNB";

      }
    },

    onCancelOffer(value){
      this.$emit("cancelOffer:value",value)
    },
    onApprove(value){
      this.$emit("approve:value",value)
    }
  }
}
</script>
