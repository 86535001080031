import router from "@/router/index.js";
import store from "@/store/store";

import getWeb3 from "@/store/modules/Metamask/getWeb3";
// import getContract from "@/store/modules/Metamask/getContract";
import web3 from "web3";
import { ABI721Maket,  addressMaket} from "@/store/modules/Metamask/constants/escrowContract";
import Web3 from "web3";
const ERCMarkets = {
    namespaced: true,
    state: {
        isMint:false,
        isReload:false

    },

    mutations: {
        setIsMint(state, data){
            state.isMint = data
        },

        setIsReload(state, data){
            state.isReload = data
        }
    },

    actions: {

        onTranfarApptoveOffer({ commit },obj) {
            commit("setIsReload", true)
            // let web3 = window.web3
            // web3 = new Web3(web3.currentProvider)
            //
            // var contract = new web3.eth.Contract(ABI721Maket, addressMaket);
            // contract.methods.AccpeOffer(obj.assertId, obj.offerId)
            //     .send({from: store.getters['metamask/onGetMetaMaskAddress']})
            //     .then((response) => {
            //         if(response.status == true){
            //             store.dispatch("dialog/onHandleError", 200)
            //         }else {
            //
            //             store.dispatch("dialog/onHandleError", 40402)
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
        },


        onExecuteOrderToMarkets({ commit },obj) {
            let web3 = window.web3
            web3 = new Web3(web3.currentProvider)

            let decimals = web3.utils.toBN(18);
            let amount = web3.utils.toBN(obj.priceInWen);
            let value = amount.mul(web3.utils.toBN(10).pow(decimals));

            var contract = new web3.eth.Contract(ABI721Maket, addressMaket);
            contract.methods.executeOrder(obj.nftAddress, obj.tokenId, value)
                .send({from: store.getters['metamask/onGetMetaMaskAddress']})
                .then((response) => {
                    if(response.status == true){
                        // commit("setIsMint",true)

                        store.dispatch("dialog/onHandleError", 200)
                    }else {

                        store.dispatch("dialog/onHandleError", 40402)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },


    },

    getters: {
        objIsMint(state){
            return state.isMint
        },
        objIsReload(state){
            return state.isReload
        }
    },
};

export default ERCMarkets;
