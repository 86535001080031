<template>
  <div>

    <CreateNFT v-if="connect"  :disable="connect" @update:value="onEditNFT" :collections="collection" :info="itemEdit" />
    <CRow>
      <CCol>
        <CCard accent-color="success" >
          <CCardBody style="padding: 0 0" >
            <div>
              <CButtonGroup style="left: 5px; top:5px" class="pr-1 pl-1 text-white position-absolute" >
                <CButton @click="onBack" color="secondary" class="pr-1 pl-1 text-white" >
                  <CIcon name="cil-chevron-left" class="mr-1 ml-1"/>
                </CButton>
              </CButtonGroup>

              <CButtonGroup v-if="this.collection.onwer._id == this.$store.getters['gateway/objProfile']._id" class="pr-1 pl-1 text-white position-absolute" style="right: 5px; top:5px">
                <CButton @click="onAddNFT"  color="secondary" class="text-white" >
                  <CIcon name="cil-library-add" class="mr-1 ml-1"/>
                </CButton>
<!--                <CButton  color="secondary" class="text-white" >-->
<!--                  <CIcon name="cil-pencil" class="mr-1 ml-1"/>-->
<!--                </CButton>-->
              </CButtonGroup>

              <img class="w-100 bg-gray-500"  :src="collection.banner" height="250px"/>
            </div>
            <div class="avatar-site2 text-center" style="margin-top: -60px">
              <img class="bg-gray-500" :src="collection.logo"  alt="" width="140px" height="140px" style="border-radius: 70px; border-style: solid;">
            </div>
            <div >
              <CRow class="mt-3">
                <CCol class="h3 text-center">{{ collection.name }}</CCol>
              </CRow>
              <CRow>
                <CCol class="h6  mr-3 ml-3">
                  <div v-html="collection.description" style="overflow-y: auto;  height: 8em;"></div>
                </CCol>
              </CRow>
              <CRow class="pl-4 pr-4">
                <CCol sm="6" md="4" lg="4" xl="3"  v-for="(item, index) in collection.item" v-bind:key="index">
                  <ItemAssets :item="item" :edit="onCheckOnwersNft(item.nfts.onwer._id,collection.onwer._id)" :market="true" :onwer="true" @infomation:value="onInfoMation" @update:value="onGetCollection" @edit:value="_editNFT" />
                </CCol>
              </CRow>
            </div>


          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ItemAssets from "@/project/views/util/ItemAssets";
import CreateNFT from "@/project/views/page/dialog/CreateNFT";

Vue.use(VueQuillEditor)
export default {
  name: 'MyCollectionLists',
  components: {CreateNFT, ItemAssets},
  data: function () {
    return {
      connect:false,
      itemEdit:{}
    }
  },
  mounted() {
    this.$store.dispatch('metamask/onMetaMaskAddress')

  },

  created() {
    if(this.$route.query.id == undefined){
      this.$router.push({name:"list"})
    }else{
      this.onGetCollection();
    }
  },

  beforeDestroy() {

  },

  methods: {

    onBack(){
      window.history.back();
      // this.$router.push({name:'list'})
    },

    onGetCollection(){
      var obj = {};
      obj.id = this.$route.query.id;
      this.$store.dispatch('gateway/onCollectionById', obj);
    },



    onCheckOnwersNft(val1, val2){
      if(val1 == val2 && val2 == this.$store.getters['gateway/objProfile']._id){
        return true;
      }else{
        return false
      }
    },

    onAddNFT() {
      this.itemEdit = {};
      this.connect = true;
      // this.$router.push({name:'AddNFT', query: {id:value._id} })
    },


    onEditNFT(value, e){
      this.connect = false;
      this.onGetCollection();
    },

    _editNFT(value){
      console.log("_editNFT",value)
      this.itemEdit = value;
      this.connect = true;

    },


    onInfoMation(value){
      this.$router.push({name:'Assets',  query: {id:this.$route.query.id,assets:value.nfts._id} })
    }



  },

  computed: {
    ...mapGetters({

      collection:'gateway/objCollectionById',
      profile:'gateway/objProfile'
      // objMarketsById:'gateway/objMarketsById',
      // isApptoveBuyNft: "metamask/objisApptove",
    })
  },

  watch: {
    profile(value){
      // this.connect = true
    }

  }
}
</script>
