import Vue from "vue";
import Vuex from "vuex";
import Service from "@/service/api.js";
import router from "../../../router/index.js";
import store from "@/store/store";

const GatewayModule = {
    namespaced: true,
    state: {
        profile:{
            _id:"",
            metamask:""
        },
        categories: [],
        markets: [],
        marketByOnwer: [],
        nfts: [],
        assets: [],
        MarketsById:{},

        collections:[],
        collectionById:{},
        collectionId:"",
        nftsId:"",

        complate:false,
        bnbusdt:0
    },

    mutations: {

        setBNBUSDT(state, obj){
            state.bnbusdt = obj.price;
        },

        setComplate(state, obj) {
            state.complate = obj
        },
        setProfile(state, obj) {
            state.profile = obj
        },
        setCategorys(state, obj) {
            state.categories = obj
        },
        setMarkets(state, obj) {
            state.markets = obj
        },
        setMarketByOnwer(state, obj) {
            state.marketByOnwer = obj
        },
        setNfts(state, obj) {
            state.nfts = obj
        },
        setAssets(state, obj) {
            state.assets = obj
        },

        setMarketsById(state, obj) {
            state.MarketsById = obj
        },

        setCollections(state, obj) {
            state.collections = obj
        },

        setCollectionById(state, obj) {
            state.collectionById = obj
        },

        setCollectionId(state, obj){
            state.collectionId = obj.collectionId;
            state.nftsId = obj.nftsId

        }

    },

    actions: {
        onBNBUSDT({commit}) {
            Service.onPriceBNB()
                .then((response) => {
                    commit('setBNBUSDT', response.data)
                }).catch((err) => {

            });
        },

        //
        onProfile({commit}, body) {

            // store.commit('setXid',"")
            // commit('setProfile', {})
            Service.onGetProfile(body)
                .then((response) => {
                    store.commit('setXid',response.data.data._id)
                    commit('setProfile', response.data.data)

                }).catch((err) => {

                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onUpdateProfile({commit}, body) {
            Service.onUpdateProfile(body)
                .then((response) => {
                    // commit('setProfile', response.data.data)
                }).catch((err) => {

                var obj = { color:"danger", title:"", message:"Update Profile" }
                store.commit("dialog/onDialogMessage", obj);
                // store.dispatch("dialog/onHandleError", err)
            });
        },
        onUpdateProfileImage({commit}, body) {
            Service.onUpdateProfileImage(body)
                .then((response) => {
                    commit('setProfile', response.data.data)
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        //
        onCategorys({commit}, body) {
            Service.onCategorys(body)
                .then((response) => {
                    commit('setCategorys', response.data.data)
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        onRegisterAddress({commit}, body) {
            Service.onRegisterAddress(body)
                .then((response) => {
                    var body = {};
                    body.metamask = response.data.data.metamask;
                    store.dispatch('gateway/onProfile',body)



                    // localStorage.setItem('info',JSON.stringify(response.data.data));
                    // store.dispatch('metamask/onMetaMaskAddress')
                    // commit('setCategorys', response.data.data)
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },
        onNFTsByTokenId({commit}, body) {
            Service.onNFTsByTokenId(body)
                .then((response) => {
                    var obj = response.data.data;
                    body.onwer = obj.onwer._id;
                    body.nfts = obj._id;

                    store.dispatch("gateway/onMarketsById",body)

                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },
        onNFTsByTokenIdCreateMarkey({commit}, body) {

            Service.onNFTsByTokenId(body)
                .then((response) => {
                    var obj = response.data.data;
                    body.onwer = obj.onwer._id;
                    body.nfts = obj._id;

                    store.dispatch("gateway/onMarketsCreate",body)

                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },





        onNFTsByOnwer({commit}, body) {
            Service.onNFTsByOnwer(body)
                .then((response) => {
                    // console.log("onNFTsByOnwer",response.data.data)
                    // commit('setMarkets', response.data.data)
                    commit('setNfts', response.data.data)
                    // commit('setAssets', response.data.data)
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        onCreateNFTs({commit}, body) {
            store.commit("dialog/onLoading", true);
            Service.onCreateNFTs(body)
                .then((response) => {
                    store.commit("dialog/onLoading", false);
                    commit('setComplate', true);

                    var obj = { color:"success", title:"", message:"Item is added" }
                    store.commit("dialog/onDialogMessage", obj);
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        onUpdateNFTs({commit}, body) {
            Service.onUpdateStatusNFTs(body)
                .then((response) => {

                    var obj = {};
                    obj.id = response.data.data._id;
                    obj.address = body.address;
                    obj.tokenId = body.tokenId;

                    console.log("onUpdateStatusNFTs",obj)

                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        onUpdateNFTsItem({commit}, body) {
            Service.onUpdateNFTs(body)
                .then((response) => {

                    commit('setComplate', true);
                    store.dispatch("dialog/onHandleError", 200)

                    var obj = { color:"success", title:"", message:"Item is update" }
                    store.commit("dialog/onDialogMessage", obj);


                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },

        onDeleteNFTsItem({commit}, body) {
            Service.onDeleteNFTs(body)
                .then((response) => {

                    commit('setComplate', true);
                    // store.dispatch("dialog/onHandleError", 200)
                    var obj = { color:"success", title:"", message:"Item is deleted" }
                    store.commit("dialog/onDialogMessage", obj);

                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        },


        // market

        onSearchMarketByCategorys({commit}, body) {
            // Service.onSearchMarketsByCategorie(body)
            //     .then((response) => {
            //         commit('setMarkets', response.data.data)
            //     }).catch((err) => {
            //     store.dispatch("dialog/onHandleError", err)
            // });
        },

        onMarketsByCategorie({commit}, body) {
            Service.onMarketsByCategorie(body)
                .then((response) => {
                    commit('setMarkets', response.data.data)
                    // console.log("setMarkets",response.data.data)

                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },
        onMarketsByOnwer({commit}, body) {
            Service.onMarketsByOnwer(body)
                .then((response) => {
                    commit('setMarketByOnwer', response.data.data)
                    // console.log("setMarkets",response.data.data)

                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },
        onMarketsById({commit}, body) {
            Service.onMarketsById(body)
                .then((response) => {
                    commit('setMarketsById', response.data.data)


                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },
        onMarketsCreate({commit}, body) {
            Service.onMarketsCreate(body)
                .then((response) => {



                    store.commit("eRCMarketPlace/setReload",true)
                    store.dispatch("dialog/onHandleError", 200)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },



        // collection //
        onCreateCollection({commit}, body) {
            Service.onCreateCollection(body)
                .then((response) => {
                    console.log(response.data.data._id)

                    router.push({name:'info',  query: {id:response.data.data._id} })

                    var obj = { color:"success", title:"", message:"Item is create collection" }
                    store.commit("dialog/onDialogMessage", obj);

                    // commit('setMarkets', response.data.data)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },

        onUpdateCollection({commit}, body) {
            Service.onUpdateCollection(body)
                .then((response) => {
                    // console.log("onUpdateCollection",response.data.data._id)
                    // console.log("response",response)
                    router.push({name:'info',  query: {id:response.data.data._id} })

                    var obj = { color:"success", title:"", message:"Item is update collection" }
                    store.commit("dialog/onDialogMessage", obj);

                    // commit('setMarkets', response.data.data)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },



        onCollectionByConnect({commit}, body) {
            Service.onGetCollectionByConnect(body)
                .then((response) => {
                    console.log(response.data.data)
                    commit('setCollections', response.data.data)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },

        onCollectionById({commit}, body) {
            Service.onCollectionById(body)
                .then((response) => {
                    commit('setCollectionById', response.data.data)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },

        onNFTLikeById({commit}, body) {
            Service.onNfFLikeById(body)
                .then((response) => {
                    console.log(response.data.data)
                    // commit('setCollectionById', response.data.data)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },


        onGetNFTOfferById({commit}, body) {
            Service.onGetNFTOfferById(body)
                .then((response) => {
                    console.log(response.data.data)
                    store.dispatch('eRCMarkets/onTranfarApptoveOffer',response.data.data)
                    // commit('setCollectionById', response.data.data)
                    store.dispatch("dialog/onHandleError", 200)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onNFTOfferById({commit}, body) {
            Service.onNfFOfferById(body)
                .then((response) => {
                    // console.log(response.data.data)
                    store.commit("dialog/onLoading", false);
                    var obj = { color:"success", title:"", message:"Item is make price" }
                    store.commit("dialog/onDialogMessage", obj);
                    // commit('setCollectionById', response.data.data)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onUnNFTOfferById({commit}, body) {
            Service.onUnNFTOfferById(body)
                .then((response) => {
                    console.log(response.data.data)
                    store.dispatch("dialog/onHandleError", 200)
                    // commit('setCollectionById', response.data.data)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },

        onNFTListPriceById({commit}, body) {
            Service.onNFTListPriceById(body)
                .then((response) => {
                    console.log(response.data.data)

                    var obj = { color:"success", title:"", message:"Your Item is listed on the market" }
                    store.commit("dialog/onDialogMessage", obj);
                    // commit('setCollectionById', response.data.data)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onNFTCancelListPriceById({commit}, body) {
            Service.onNFTCancelListPriceById(body)
                .then((response) => {
                    console.log(response.data.data)
                    // commit('setCollectionById', response.data.data)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onCollectionByNfts({commit}, body) {
            Service.onCollectionByNfts(body)
                .then((response) => {
                    var obj = {};
                    obj.nftsId = body.id;
                    obj.collectionId = response.data.data._id;

                    commit('setCollectionId', obj)
                }).catch((err) => {
                // store.dispatch("dialog/onHandleError", err)
            });
        },

        onTranfarNFT({commit}, body) {
            Service.onTranfarNFT(body)
                .then((response) => {
                   // console.log(response.data)

                    var obj = { color:"success", title:"", message:"Item has been sent" }
                    store.commit("dialog/onDialogMessage", obj);
                    // store.dispatch("dialog/onHandleError", 200)
                }).catch((err) => {
                store.dispatch("dialog/onHandleError", err)
            });
        },
        // nfts/:id/listprice
        // end collection //
    },

    getters: {
        objComplate(state, obj) {
            return state.complate;
        },
        objProfile(state) {
            return state.profile;
        },
        objCategorys(state) {
            return state.categories;
        },
        objMarkets(state, obj) {
            return state.markets;
        },
        objMarketByOnwer(state) {
            return state.marketByOnwer;
        },
        objNfts(state, obj) {
            return state.nfts;
        },
        objAssets(state, obj) {
            return state.assets;
        },
        objMarketsById(state) {
            return state.MarketsById;
        },

        objCollections(state) {
            return state.collections;
        },
        objCollectionById(state) {
            return state.collectionById;
        },

        objCollectionId(state){
            console.log(12)
            var obj = {};
            obj.collectionId = state.collectionId;
            obj.nftsId = state.nftsId

            return obj

        },
        objBNBUSDT(state){
            return state.bnbusdt;
        }
    },
};

export default GatewayModule;
