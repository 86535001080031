<template>
  <div>
    <sell-price v-if="show" :disable="show" :collections="collection"  @update:value="onGetCollection" />
    <tranfer-nfts v-if="tranfer" :disable="tranfer" @create:value="_tranfar"/>
    <CRow>
      <CCol md="5">
        <CCard accent-color="success"  >
          <CCardBody style="padding: 0 0" class="bg-white" >
            <img v-if="nfts.fullyFommercialRight" class="position-absolute text-success" style="right: 10px; top:10px" width="40px" height="40px" src="@/assets/logo/copyright.png">
            <img class="w-100" :src="nfts.image" />
          </CCardBody>
        </CCard>

        <CCard accent-color="success"  >
          <CCardHeader>
            <CRow>
              <CCol>
                <CIcon  size="lg" name="cil-clipboard" class="text-white mr-3"/>  DESCRIPTION
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody >
            <CRow>
              <CCol>CONTACT ADDRESS</CCol>
              <CCol class="text-right text-info" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{nfts.contactAddress}}</CCol>
            </CRow>
            <CRow>
              <CCol>TOKEN ID</CCol>
              <CCol class="text-right">{{nfts.tokenId}}</CCol>
            </CRow>
            <CRow>
              <CCol>BLOCKCHAIN</CCol>
              <CCol class="text-right">BSC</CCol>
            </CRow>
            <CRow v-if="collection.royalties.address">
              <CCol>ROYALTIES ADDRESS</CCol>
              <CCol class="text-right">{{collection.royalties.address}}</CCol>
            </CRow>
            <CRow v-if="collection.royalties.percentage">
              <CCol>ROYALTIES PERCENTANG</CCol>
              <CCol class="text-right">{{collection.royalties.percentage}} %</CCol>
            </CRow>

            <CRow>
              <CCol>METADATA</CCol>
              <CCol class="text-right">Editable</CCol>
            </CRow>

            <CRow v-if="nfts.fullyFommercialRight">
              <CCol>Fully commercial right is included</CCol>
              <CCol class="text-right">
                <img  class="text-success"  width="20px" height="20px" src="@/assets/logo/copyright.png">
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>



      </CCol>

      <CCol md="7">
        <CRow>
          <CCol class="h4"></CCol>
          <CCol class="p-0" style="height: 1.5rem">

            <CButtonGroup class="pr-1 pl-1 text-white position-absolute" style="right: 5px; top:5px">
              <CButton @click="this.onBack" color="secondary" class="text-white" >
                <CIcon name="cil-chevron-left" class="mr-1 ml-1"/>
              </CButton>
              <CButton @click="onGetCollection" color="secondary" class="text-white" >
                <CIcon name="cil-reload" class="mr-1 ml-1"/>
              </CButton>
              <CButton @click="onTranfar" color="secondary" v-if="this.onCheckOnwers()" class="text-white" >
                <CIcon name="cil-gift" class="mr-1 ml-1"/>
              </CButton>
              <CButton  @click="onShare" color="secondary" class="text-white" >
                <sharing :sharing="nfts.sharing"/>
              </CButton>
              <CButton @click="onSell" v-if="this.onCheckOnwers()" color="success" class="text-white" >
                <CIcon name="cil-basket" class="mr-1 ml-1"/>
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <CRow class="mt-4">
          <CCol class="h4">{{nfts.name}}</CCol>
          <CCol class="text-right">
            <CButton color="transparent p-0" class=" mr-2"  @click="onLike">
              <CIcon  size="lg" name="cil-heart" class="text-white"/>
              <span class="ml-2 mr-2 text-white">{{(nfts.like != null)?nfts.like.length:0}} FAVORITE</span>
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div v-html="nfts.description">  </div>
          </CCol>
        </CRow>
        <div v-if="reload">
          <div v-if="(nfts.bidding == false && nfts.price > 0)">
            <CRow class="mb-2" v-if="nfts.market == true">
              <CCol class="font-md">
                BNB {{nfts.price}} ($ {{nfts.price*parseFloat(this.$store.getters['gateway/objBNBUSDT'])}} USD )
              </CCol>
            </CRow>
            <CRow class="mb-1" v-if="nfts.market == true">
              <CCol>
                <CButton v-if="!this.onCheckOnwers()" @click="onBuyNow" size="md" color="success" class="text-white"  >BUY NOW</CButton>
              </CCol>
            </CRow>
          </div>

          <div v-if="(nfts.bidding != false && nfts.price > 0)">
            <CRow class="mb-2">
              <CCol class="font-md">
                WBNB {{nfts.price}} ($ 350 USD )
              </CCol>
            </CRow>
            <CRow class="mb-1">
              <CCol v-if="!this.onCheckOnwers()" >
                <CInput
                    v-model="makeOffer"
                    :horizontal="{ input: 'col-md-12 text-right'}"
                    type="Number"
                    :placeholder="nfts.price">
                  <template #append>
                    <CButton  @click="onMakeOffer" size="md" color="success" class="text-white" >Place BID</CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol>
                <flip-countdown :deadline="moment(nfts.expire).add('-7','hour').format('YYYY-MM-DD HH:mm:ss')"></flip-countdown>
              </CCol>
            </CRow>
          </div>
        </div>
        <CRow>
          <CCol md="12" class="mt-4">
            <CCard accent-color="success"  >
              <CCardHeader>
                <CRow>
                  <CCol>
                    <CIcon  size="lg" name="cil-tag" class="text-white mr-3"/>  LISTINGS
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody  >
                <CRow>
                  <CCol>
                    <!--                    <table-list-offer v-if="reload" caption="fields.offer" :items="nfts.listPrice" :fields="fields.list"/>-->
                    <table-list-offer v-if="reload" caption="fields.offer" :onwerNft="nfts.onwer._id"  :items="nfts.listPrice" :fields="fields.list"/>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol md="12">
            <CCard accent-color="success"  >
              <CCardHeader>
                <CRow>
                  <CCol>
                    <CIcon  size="lg" name="cil-speedometer" class="text-white mr-3"/>  OFFERS
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol>
                    <table-list-offer
                        v-if="reload"
                        caption="fields.offer"
                        :onwerNft="nfts.onwer._id"
                        :items="nfts.offerPrices"
                        :fields="fields.offer"
                        @cancelOffer:value="_cancelOffer"
                        @approve:value="_approveOffer"
                    />
                  </CCol>
                </CRow>
                <CRow>


                  <CCol md="12">
                    <CInput
                        v-model="makeOffer"
                        :horizontal="{ input: 'col-md-12 text-right'}"
                        type="Number"
                        min="0"
                        placeholder="0">
                      <template #prepend>
                        <CButton :disabled="false"  size="md" color="success" class="text-white" >WBNB</CButton>
                      </template>
                      <template #append>
                        <CButton @click="onMakeOffer" size="md" color="success" class="text-white"  >MAKE OFFER</CButton>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
              </CCardBody>

            </CCard>
          </CCol>
        </CRow>

      </CCol>
    </CRow>

    <CRow >
      <CCol>
        <CCard accent-color="success"  >
          <CCardHeader>
            <CRow>
              <CCol>
                <CIcon  size="lg" name="cil-layers" class="text-white mr-3"/>  HISTORYS
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody  >
            <CRow>
              <CCol>
                <table-list-offer class="p-0" v-if="reload" caption="fields.offer" :onwerNft="nfts.onwer._id"  :items="nfts.historys" :fields="fields.history"/>
              </CCol>
            </CRow>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import FlipCountdown from "@/project/containers/utils/FlipCountdown";
import TableList from "@/project/containers/sub/TableList";
import TableListOffer from "@/project/views/page/components/TableList";
import SellPrice from "@/project/views/page/dialog/SellPrice";
import Sharing from "@/project/views/util/sharing";
import TranferNfts from "@/project/views/page/dialog/TranferNfts";
import store from "@/store/store";

export default {
  name: 'Assets',
  components: {TranferNfts, Sharing, SellPrice, TableListOffer, TableList, FlipCountdown},
  props:{
    item: Object
  },

  // metaInfo: {
  //   title: 'My Example App',
  //   titleTemplate: '%s - Yay!',
  //   htmlAttrs: {
  //     lang: 'ens',
  //     amp: true
  //   }
  // },
  data: function () {
    return {
      reload:false,
      image:"http://localhost:8080/img/avatars/0.jpeg",
      makeOffer:0,
      message:"",
      nfts:{
        sharing: {
          url: 'https://news.vuejs.org/issues/180',
          title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
          description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
          quote: 'The hot reload is so fast it\'s near instant. - Evan You',
          hashtags: 'vuejs,vite,javascript',
          twitterUser: 'youyuxi'
        },
      },
      fields:{
        offer: [
            {key: 'no', label: 'NO', _style: 'width:5%;text-align: center'},
            {key: 'by', label: 'DISPLAY NAME', _style: 'width:35%; text-align: center'},
            {key: 'price', label: 'PRICE OFFER', _style: 'width:20%; text-align: center'},
            {key: 'createDate', label: 'CREATE DATE', _style: 'width:40%; text-align: center'}
        ],
        list: [
          {key: 'no', label: 'NO', _style: 'width:5%;text-align: center'},
          {key: 'event', label: 'EVENT', _style: 'width:20%;text-align: center'},
          {key: 'price', label: 'PRICE OFFER', _style: 'width:20%; text-align: center'},
          {key: 'createDate', label: 'CREATE DATE', _style: 'width:30%; text-align: center'}
        ],
        history: [
            {key: 'no', label: 'NO', _style: 'width:10%;text-align: center'},
            {key: 'event', label: 'EVENT', _style: 'width:15%;text-align: center'},
            {key: 'price', label: 'PRICE', _style: 'width:15%;text-align: center'},
            // {key: 'tokenAddress', label: 'TOKEN ADDRESS', _style: 'width:15%;text-align: center'},
            {key: 'fromAddress', label: 'FROM ADDRESS', _style: 'width:15%;text-align: center'},
            {key: 'toAddress', label: 'TO ADDRESS', _style: 'width:15%;text-align: center'},
            {key: 'createDate', label: 'CREATE DATE', _style: 'width:15%; text-align: center'}
        ]
      },

      fixprice:false,
      biding:false,
      minimunBid:0,
      reservePrice:0,
      expiresAt:0,
      show:false,
      sharing: {
        url: 'https://news.vuejs.org/issues/180',
        title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: 'The hot reload is so fast it\'s near instant. - Evan You',
        hashtags: 'vuejs,vite,javascript',
        twitterUser: 'youyuxi'
      },
      title:"1112",
      tranfer:false
    }
  },
  // metaInfo() {
  //   return {
  //     title: this.title,
  //     // ...
  //   };
  // },
  metaInfo() {
    return {
        meta: [
          {name: 'viewport', content: 'user-scalable=no'},
          // {vmid: 'og:title', property: 'og:title', content: this.nfts.sharing.title},
          {vmid: 'og:type',  property: 'og:type', content: 'website'},
          {vmid: 'og:url', property: 'og:url', content: window.location.href},// here it is just ngrok for my test
          // {vmid: 'og:description', property: 'og:description', content: this.nfts.sharing.description},
          {vmid: 'og:image', property: 'og:image', content: "https://lh3.googleusercontent.com/D5_lE1dlmuWNQqpy6vgPa0TI6Dy5ioX-HS-YvgQia9SxMeh_aS3d5K6mnLjFKfDzJMH9iFqpjVchggBouzFn1ebLj8nzEtG5RKWa=w1400-k"},
          // {property: 'twitter:image:src', content: 'https://firebasestorage.googleapis.com/v0/b/dev-blog-2503f.appspot.com/o/postsStorage%2F-KxXdvvLqDHBcxdUfLgn%2Fonfleck?alt=media&token=24a9bf5b-dce2-46e8-b175-fb63f7501c98'},
          // {property: 'og:image:width', content: '1000'},

          {property: 'og:type', content: 'website'},
          {property: 'og:site_name', content: 'nextart | nft'}

        ]
      }
  },


  mounted() {

    this.$store.dispatch('metamask/onMetaMaskAddress')

    var obj = {};
    obj.nftsId = 0;
    obj.collectionId = 0;

    this.$store.commit('gateway/setCollectionId', obj)

    console.log(this.$route)

    if(this.$route.query.id == undefined || this.$route.query.assets == undefined){
      this.$router.push({name:"list"})
    }else{
      this.onGetCollection();
    }
  },

  created() {
    this.$store.dispatch("gateway/onBNBUSDT")

  },

  beforeDestroy() {

  },

  methods: {
    onBack(){
      window.history.back();
      // this.$router.push({name:'info',  query: {id:this.$route.query.id} })
    },

    onGetProfile(){
      var body = {};
      body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('gateway/onProfile',body)
    },

    onMakeOffer(){
      var obj = {};
      obj.total = this.makeOffer;
      this.$store.dispatch('erc20/onApptove', obj);
    },

    _cancelOffer(value){
      var obj = {};
      obj.id = this.$route.query.assets;

      obj.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      obj.price = value.price;

      this.$store.dispatch('gateway/onUnNFTOfferById', obj);
      this.onGetCollection();
    },

    _approveOffer(item){
      var obj = {};
      obj.id = this.$route.query.assets;
      obj.offer = item._id;

      this.$store.dispatch('gateway/onGetNFTOfferById', obj);

      // obj.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      // obj.price = value.price;

      // this.$store.dispatch('gateway/onUnNFTOfferById', obj);
      this.onGetCollection();
    },

    onGetCollection(){
      this.show = false;
      this.reload = false;
      var obj = {};
      obj.id = this.$route.query.id;
      this.$store.dispatch('gateway/onCollectionById', obj);

    },

    onTranfar(){
      this.tranfer = true;
      // var obj = {};
      // obj.id = this.nfts._id;
      // obj.from = this.$store.getters['gateway/objProfile']._id;
      // obj.to = "610c0dfa5ff962f7d253069a";
      // this.$store.dispatch('gateway/onTranfarNFT', obj);
      // this.onGetCollection();
    },

    _tranfar(value){
      this.tranfer = false;
      var obj = {};
      obj.id = this.nfts._id;
      obj.from = this.$store.getters['gateway/objProfile']._id;
      obj.to = value.address;
      this.$store.dispatch('gateway/onTranfarNFT', obj);
      this.onGetCollection();
    },

    onLike(){
      var obj = {};
      obj.id = this.nfts._id;

      var by = {}
      by.by = this.$store.getters['gateway/objProfile']._id;
      obj.like = by
      this.$store.dispatch('gateway/onNFTLikeById', obj);
      this.onGetCollection();
    },

    onShare(){

    },

    onSell(){
      this.show = true;
    },

    onBuyNow(){

    },

    onCheckOnwers(){
      try{
        if(this.nfts.onwer._id != this.$store.getters['gateway/objProfile']._id){
          return false;
        }else{
          return true
        }
      }catch (e) {
        return false;
      }

    },



  },
  computed: {
    ...mapGetters({
      collection:'gateway/objCollectionById',
      profile:'gateway/objProfile',
      apptove:"erc20/objisApptove",
      reload:"eRCMarkets/objIsReload"
    })
  },
  // metaInfo: {
  //   title: 'Default Title',
  //   titleTemplate: '%s - site slogan'
  // },


  watch: {

    collection(value){
      // console.log(this.$route.query.assets);

      var item = value.item.filter((item) => {

        if(item.nfts._id == this.$route.query.assets ){
          item.nfts.historys.sort(function (a, b) { var dateA = new Date(a.createDate).getTime(); var dateB = new Date(b.createDate).getTime(); return dateA < dateB ? 1 : -1; })


          const matches = window.location.href.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);

          var sharing = {};
          sharing.url = matches[0]+"collection/assets/"+this.$route.query.id+"/"+this.$route.query.assets;
          sharing.title = item.nfts.name;
          sharing.description = item.nfts.description.replace(/<[^>]*>?/gm, '');
          sharing.quote = item.nfts.description.replace(/<[^>]*>?/gm, '');
          sharing.hashtags = "nft, nextart";

          item.nfts.sharing = sharing

          console.log(item)

          return item
        }
      });
      this.nfts = item[0].nfts;


      // metaInfo();
      this.reload = true;
    },

    apptove(value){
      // affter onMakeOffer
      var obj = {};
      obj.id = this.$route.query.assets;

      var by = {};
      by.by = this.profile._id;
      by.price = this.makeOffer
      obj.offerPrices = by;

      this.$store.dispatch('gateway/onNFTOfferById', obj);
      this.onGetCollection();
    }

  }
}
</script>
