<template>
  <div class="position-relative" style="height: 300px;" >
    <div class="overflow-auto m-auto text-center" >
      <img class="w-100" :src="'http://127.0.0.1/images/profiles/'+item.image" />
    </div>

    <div class="mt-2 mb-2 position-absolute" v-if="!market" style="bottom: 0px; right: 0px;">
      <flip-countdown :deadline="item.expires"></flip-countdown>
    </div>
  </div>
</template>

<script>

import FlipCountdown from "@/project/containers/utils/FlipCountdown";
export default {
  name: 'ItemNfts',
  components: {FlipCountdown},
  props:{
    item: {},
    market: {
      type: Boolean,
      default: false
    }

  },
  data: function () {
    return {
    }
  },
  mounted () {

  },

  created () {
  },

  beforeDestroy () {

  },

  methods: {

  },
}
</script>
