import axios from 'axios';
import store from '@/store/store'
const instance = axios.create();


// instance.defaults.baseURL = 'https://api.example.com';
// instance.defaults.baseURL = 'https://svq.mfu.ac.th/api/v1';
instance.defaults.baseURL = 'https://nextart.app/api/v1';
// instance.defaults.baseURL = 'http://127.0.0.1:8082/api/v1';
instance.defaults.timeout = 5000;

// const cryptoPrice = axios.create();
// cryptoPrice.defaults.baseURL = 'https://api.binance.com/api/v3';
// cryptoPrice.defaults.timeout = 5000;



instance.defaults.headers = {
  "Content-Type": "application/json",
  "company":"6080ec9480bcf039799aca39",
  // "publickey":store.state.xid
  // "api-key": "12345678900987654321",
  // "api-version": "1.0"
}


export default {
  // CONFIG

  onSetToken(){
    instance.defaults.headers = {
      "Content-Type": "application/json",
      "company":"6080ec9480bcf039799aca39",
      // "api-key": "12345678900987654321",
      // "api-version": "1.0",
      // "Authorization": `Bearer ${store.state.token}`,
      "xid" : `${store.state.xid}`
    }

  },

  onRegisterAddress(body) {
    return instance.post("/register",body)
  },

  onGetProfile(body) {
    return instance.get("/profile/"+body.metamask)
  },


  onCategorys(body) {
    return instance.get("/categories")
  },

  // market //
  onMarketsByCategorie(body){
    return instance.get("/markets/"+body.categorie)
  },

  // end market //

  onNFTAssets(){
    this.onSetToken();
    return instance.get("/nfts")
  }


}
