import router from "@/router/index.js";
import store from "@/store/store";

const DialogCreateModule = {
    namespaced: true,
    state: {
      dialogLessonPlan : false,
      dialogEvaluationPlans : false,
      dialogInstructors : false,
      dialogDevelopPlans : false,
      dialogConsistency : false,
      instructors : [],
      lessonPlan : []

    },

    mutations: {
      onDialogLessonPlan(state, data){
        state.dialogLessonPlan = data
      },

      onDialogEvaluationPlans(state, data){
        state.dialogEvaluationPlans = data
      },

      onDialogInstructors(state, data){
        state.dialogInstructors = data
      },

      onDialogDevelopPlans(state, data){
        state.dialogDevelopPlans = data

      },

      onDialogConsistency(state, data){
        state.dialogConsistency = data

      },

      onInstructors(state, data){
        state.instructors = data
      },

      onLessonPlan(state, data){
        var item = state.lessonPlan;
        item.push(data)

        state.lessonPlan = item
        // state.lessonPlan = data
      },

    },

    actions: {
      setDialogLessonPlan({commit}, data){
          commit('onDialogLessonPlan', data)
      },

      setDialogEvaluationPlans({commit}, data){
        commit('onDialogEvaluationPlans', data)
      },

      setDialogInstructors({commit}, data){
        commit('onDialogInstructors', data)
      },

      setDialogDevelopPlans({commit}, data){
        commit('onDialogDevelopPlans', data)
      },

      setDialogConsistency({commit}, data){
        commit('onDialogConsistency', data)
      },



      setLessonPlans({commit}, data){
        commit('onLessonPlan', data)

      },

    },

    getters: {
      onGetDialogLessonPlan(state){
          return state.dialogLessonPlan
      },

      onGetDialogEvaluationPlans(state){
        return state.dialogEvaluationPlans
      },

      onGetDialogInstructors(state){
        return state.dialogInstructors
      },

      onGetDialogDevelopPlans(state){
        return state.dialogDevelopPlans
      },

      onGetDialogConsistency(state){
        return state.dialogConsistency
      },

      onGetInstructors(state){
        return state.instructors
      },
      onGetLessonPlans(state){
        return state.lessonPlan
      }

    },
};

export default DialogCreateModule;
