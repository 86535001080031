import Vue from "vue";
import Vuex from "vuex";
import Service from "@/service/api.js";
import router from "../../../router/index.js";
import store from "@/store/store";

const LoginModule = {
    namespaced: true,
    state: {
      objJwt: {},
      dialog : {
        statue:false,
        title:"",
        message:""
      }

    },

    mutations: {
      setObjJwt(state, obj) {
        state.objJwt = obj;
      },
      setDialog(state, obj) {

        var dialog = {};
        dialog.statue = true
        dialog.title ="ข้อผิดพลาดในการเข้าสู่ระบบ"
        dialog.message = obj.msg

        state.dialog = dialog;
      },


    },

    actions: {
        onLogin({commit}, body) {
            Service.onLogin(body)
                .then((response) => {
                  var decodedString = atob(response.data.token.split('.')[1]);
                  var obj = JSON.parse(decodedString);
                  commit("setObjJwt", obj)
                }).catch((err) => {

                    store.dispatch("dialog/onHandleError", err)
                });
        },

        onKeepalive({commit},body){
            Service.keepAlive(body)
                .then((response) => {
                    var decodedString = atob(response.data.token.split('.')[1]);
                    var obj = JSON.parse(decodedString);
                    commit("setObjJwt", obj)
                }).catch((err) => {

                store.dispatch("dialog/onHandleError", err)
            });
        }

    },

    getters: {
      objJwt(state) {
        return state.objJwt;
      },
      dialogError(state) {
        return state.dialog;
      },
    },
};

export default LoginModule;
