import router from "@/router/index.js";
import store from "@/store/store";
import Service from "@/service/apis.js";

import web3 from "web3";
const NextArt = {
    namespaced: true,
    state: {
        profile:{
            _id:"",
        },
        nftAssets : [],
    },

    mutations: {
        setProfile(state, obj) {
            state.profile = obj
        },

        setNFTAssets(state, data){
            state.nftAssets = data
        }
    },

    actions: {

        onRegisterAddress({commit}, body) {
            Service.onRegisterAddress(body)
                .then((response) => {
                    // console.log("onRegisterAddress",response.data.data)
                    var body = {};
                    body.metamask = response.data.data.metamask;
                    store.dispatch('gateway/onProfile',body)
                })
                .catch((err) => {
                    store.dispatch("dialog/onHandleError", err)
                });
        },

        onProfile({commit}, body) {
            Service.onGetProfile(body)
                .then((response) => {
                    store.commit('setXid',response.data.data._id)
                    commit('setProfile', response.data.data)
                })
                .catch((err) => {
                });
        },



        onNFTAssets({ commit }) {
            Service.onNFTAssets()
                .then((response) => {
                    console.log("onNFTAssets",response.data.data)
                    commit('setNFTAssets', response.data.data)
                })
                .catch((err) => {

                });
            },



    },

    getters: {
        objProfile(state) {
            return state.profile;
        },

        objNFTAssets(state){
            return state.nftAssets
        }
    },
};

export default NextArt;
