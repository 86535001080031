import Service from "@/service/api.js";
import store from "@/store/store";

const LoginModule = {
  namespaced: true,
  state: {
    operations: [],
    verification: [],

    programs: [],
    categorys: [],

    instructor:[],

    lessonPlan : [],
    evaluationPlans :[],



    campus: [],
    courses: [],
    facultys: [],
    department: [],
    preRequest:[],
    studyRequest: []
  },

  mutations: {
    setCampus(state, obj) {
      state.campus = obj;
    },

    setCourses(state, obj) {
      state.courses = obj;
    },

    setFacultys(state, obj) {
      state.facultys = obj;
    },

    setDepartments(state, obj) {
      state.department = obj;
    },
    setPreRequests(state, obj) {
      state.preRequest = obj;
    },
    setStudyRequests(state, obj) {
      state.studyRequest = obj;
    },


    setOperations(state, obj) {
      state.operations = obj;
    },

    setVerifications(state, obj) {
      state.verification = obj;
    },

    setPrograms(state, obj) {
      state.programs = obj;
    },

    setCategorys(state, obj) {
      state.categorys = obj;
    },

    setInstructor(state, obj) {
      state.instructor = obj
    },

    setLessonPlan(state, obj) {
      state.lessonPlan = obj
    },
    setEvaluationPlans(state, obj) {
      state.evaluationPlans = obj
    }
  },

  actions: {
    onGetCampus({commit}) {
      Service.campus()
        .then((response) => {
          var obj = response.data.data


          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.label = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.label = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];
          commit("setCampus", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },
    onGetFacultys({commit},pram) {
      Service.facultys(pram)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.label = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.label = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];


          commit("setFacultys", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },
    onGetDepartment({commit}, pram) {
      Service.department(pram)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.label = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.label = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];


          commit("setDepartments", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetPreRequestByCourseId({commit},pram) {
      Service.preRequisCourse(pram)
        .then((response) => {
          commit("setPreRequests", response.data.data)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetStudyRequestByCourseId({commit},pram) {
      Service.studyRequisCourse(pram)
        .then((response) => {
          commit("setStudyRequests", response.data.data)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetCourses({commit},pram) {
      Service.courses(pram)
        .then((response) => {
          commit("setCourses", response.data.data)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetCoursesById({commit},pram) {
      Service.courseById(pram.campus,pram.codeId)
        .then((response) => {

          commit("setInstructor", response.data.data.instructor)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },



    onGetPrograms({commit},body) {
      Service.evaluations(body.campusId,body.facultyId,body.departmentId)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.text = obj[i].name[0].value;
            objItemTH.value = i;
            objItemTH.obj = obj[i];

            itemTH.push(objItemTH)

            var objItem = {};
            objItem.text = obj[i].name[1].value;
            objItem.value = i
            objItem.obj = obj[i];
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];
          commit("setPrograms", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetCategorys({commit},body) {
      Service.categorys(body.campusId)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.text = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.text = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];
          commit("setCategorys", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

    onGetOperations({commit},body) {
      Service.operations(body)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.text = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.text = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];
          commit("setOperations", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },
    onGetVerifications({commit},body) {
      Service.verification(body)
        .then((response) => {
          var obj = response.data.data

          var itemTH = [];
          var itemEn = [];
          for (var i = 0; i < obj.length; i++) {
            var objItemTH = {};
            objItemTH.text = obj[i].name[0].value;
            objItemTH.value = obj[i]
            itemTH.push(objItemTH)

            var objItem = {};
            objItem.text = obj[i].name[1].value;
            objItem.value = obj[i]
            itemEn.push(objItem)
          }

          var objGroup = [{
            key: "th",
            value: itemTH
          }, {
            key: "en",
            value: itemEn
          }];
          commit("setVerifications", objGroup)
        }).catch((err) => {

        store.dispatch("dialog/onHandleError", err)
      });
    },

  },

  getters: {
    objCampus(state) {
      return state.campus;
    },
    objCourses(state) {
      return state.courses;
    },
    objFacultys(state) {
      return state.facultys;
    },
    objDepartments(state) {
      return state.department;
    },
    objPreRequests(state, obj) {
      return state.preRequest;
    },
    objStudyRequests(state, obj) {
      return state.studyRequest;
    },

    objOperations(state) {
      return state.operations;
    },

    objVerification(state) {
      return state.verification;
    },

    objProgram(state) {
      return state.programs;
    },

    objCategory(state) {
      return state.categorys;
    },

    objInstructor(state) {
      return state.instructor;
    },

    objLessonPlan(state) {
      return state.lessonPlan
    },

    objsetEvaluationPlan(state) {
      return state.evaluationPlans
    }
  },
};

export default LoginModule;
