import router from "@/router/index.js";
import store from "@/store/store";

const DialogModule = {
    namespaced: true,
    state: {
        dialog:{
            color:"danger",
            title:"",
            message:""
        },
        loading:false
    },

    mutations: {
        onDialogMessage(state, data){
            state.dialog = data
        },
        onLoading(state, data) {
            state.loading = data
        },



    },

    actions: {
        setDialogMessage({commit}, data){
            commit('onDialogMessage', data)
        },

        onHandleError({commit}, data){
            // console.log(data.response.data)
            store.commit("dialog/onLoading", false);
            switch (data) {
                case 200 :
                    let objs = {
                        color:"success",
                        title:"",
                        message:"complete the list"
                    }
                    commit('onDialogMessage', objs)
                    break;
                default :
                    let obj = {
                        color:"danger",
                        title:"",
                        message:"An error occurred, try again."
                    }
                    commit('onDialogMessage', obj)
                    break
            }
        },

    },

    getters: {
        onGetMessageDialog(state){
            return state.dialog
        },
        onGetLoading(state) {
            return state.loading
        }
    },
};

export default DialogModule;
