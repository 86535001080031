<template>
  <div>
    <CCard accent-color="success">
      <CCardHeader>
        <h5>{{ title }} your collection</h5>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow>
            <CCol>
              <h6>Logo image *</h6>
              <span>This image will also be used for navigation. 350 x 350 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success" style="width: 150px; height: 150px;">
                <label for="input-vjs" class="border-white">
                  <div class="avatar-site2">
                    <img :src="urlLogo" class="img-avatar" alt="" width="100%" height="150px">
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-vjs" type="file" accept="image/*" @change=uploadImageLogo style="display: none;"/>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Featured image</h6>
              <span>(optional) This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of NextArt. 600 x 400 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success w-50" style=" height: 233px;">
                <label for="input-featured" class="border-white">
                  <div class="avatar-site2">
                    <img :src="urlFeatured" class="img-avatar" alt="" width="100%" height="233px">
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-featured" type="file" accept="image/*" @change=uploadImageFeatured style="display: none;"/>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Banner image</h6>
              <span>(optional) This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</span>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="p-4 text-center">
              <div class="border-success w-75" style="height: 157px;">
                <label for="input-banner" class="border-white">
                  <div class="avatar-site2">
                    <img :src="urlBanner" class="img-avatar" alt="" width="100%" height="157px">
                    <div class="image-upload" style="position: absolute; top: 0px; left: 0%; background-color: orange;">
                      <i class="icon-camera icons font-4xl d-block mt-4"></i>
                    </div>
                  </div>
                </label>
                <input id="input-banner" type="file" accept="image/*" @change=uploadImageBanner style="display: none;"/>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol col="12">
              <CInput label="Name" placeholder="Example: Treasures of the Sea" v-model="name" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CSelect label="Category" @update:value="onCategory" :options="categories" :value="selectCategorie"/>
            </CCol>
          </CRow>
          <CRow >
            <CCol class="mb-sm-2">
              <h6> Decription</h6>
            </CCol>
          </CRow>
          <CRow >
            <CCol >
              <quill-editor class="mb-3" :content="description" @change="onEditorDecription"/>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Royalties</h6>
              <span>Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.</span>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol col="12">
              <CInput label="Percentage fee" type="number" placeholder="0.00" v-model="percentage" min="0" />
            </CCol>
          </CRow>

          <CRow class="mt-3" v-if="percentage>0">
            <CCol col="12">
              <CInput
                  label="Your payout wallet address"
                  type="text"
                  placeholder="Please enter an address, e.g. 0x1ed3... or destination.eth"
                  v-model="address"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <h6>Payment tokens</h6>
              <span>These tokens can be used to buy and sell your items.</span>
            </CCol>
          </CRow>

          <CRow class="mt-3" >
            <CCol md="3">
              <CCard color="light">
                <CCardBody>
                  <div class="float-left mr-4">
                    <img src="https://bscscan.com/token/images/binance_32.png"/>
                  </div>
                  <div class="float-left cil-volume-high">
                    <h6>BNB</h6>
                    <span>Binance</span>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol md="3">
              <CCard color="light" >
                <CCardBody>
                  <div class="float-left mr-4">
                    <img src="https://bscscan.com/token/images/binance_32.png"/>
                  </div>
                  <div class="float-left cil-volume-high">
                    <h6>WBNB</h6>
                    <span>Binance</span>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>

          </CRow>
        </div>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol>
            <CButton @click="onCreate" class="mr-3" size="md" color="success" variant="outline"  >{{ (update == true)?'UPDATE':'CREATE'}}</CButton>
          </CCol>
          <CCol class="text-right">
            <CButton @click="onGoTo('list')" class="" size="md" color="light" variant="outline">CANCEL</CButton>
<!--            <CButton v-if="update" class=" ml-3" size="md" color="danger" variant="outline">DELETE</CButton>-->
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {address721} from "@/store/modules/Metamask/constants/escrowContract";

Vue.use(VueQuillEditor)
export default {
  name: 'Creates',
  components: {},
  props:{
    item: Object
  },
  data: function () {
    return {
      update:false,
      id:0,
      categories:[],
      name:"",
      description:"",
      percentage:0,
      address:"",
      selectCategorie:"60e1af7f8e5715d5e9c1c047",
      urlLogo: "img/upload/upload.png",
      urlFeatured: "img/upload/upload.png",
      urlBanner: "img/upload/upload.png",
    }
  },
  mounted() {
    this.$store.dispatch('metamask/onMetaMaskAddress')
    this.$store.dispatch("gateway/onCategorys")
  },

  created() {


    if(this.$route.params._id != undefined){
      console.log("item",this.$route.params)
      this.update = true;
      this.name = this.$route.params.name;
      this.description = this.$route.params.description;
      if(this.$route.params.logo != null){
        this.urlLogo = this.$route.params.logo;

      }
      if(this.$route.params.featured != null){
        this.urlFeatured = this.$route.params.featured;

      }
      if(this.$route.params.banner != null){
        this.urlBanner = this.$route.params.banner;

      }
      this.percentage = this.$route.params.royalties.percentage;
      this.address = this.$route.params.royalties.address;
      this.selectCategorie = this.$route.params.categorie._id
    }


  },

  beforeDestroy() {

  },

  methods: {

    onGoTo(path){
      this.$router.push({name:path})
    },

    onCategory(value, e) {
      console.log(value)
      this.selectCategorie = value;
    },

    onEditorDecription(html, text, quill) {
      this.description = html.html;
    },

    onCreate(){
      // var obj = {};
      // obj.onwer = this.profile._id;
      // obj.name = this.name;
      // obj.description = this.description;
      // obj.categorie = this.selectCategorie;
      //
      var objRoyalties = {};
      objRoyalties.address = this.address;
      objRoyalties.percentage = this.percentage;
      //
      // ///


      let data = new FormData();
      data.append("creator", this.profile._id);
      data.append("onwer", this.profile._id);
      data.append("name", this.name);
      data.append("description", this.description);
      data.append("categorie", this.selectCategorie);

      try{
        data.append('logo', this.imageLogo, this.imageLogo.fileName);
      }catch (e) {

      }
      try{
        data.append('featured', this.imageFeatured, this.imageFeatured.fileName);
      }catch (e) {

      }

      try{
        data.append('banner', this.imageBanner, this.imageBanner.fileName);
      }catch (e) {

      }


      data.append("royalties", objRoyalties);


      if(this.update == true){
        data.append("_id", this.$route.params._id);
        this.$store.dispatch('gateway/onUpdateCollection', data);
      }else{
        this.$store.dispatch('gateway/onCreateCollection', data);
      }
    },

    onGetProfile(){
      var body = {};
      body.metamask = this.$store.getters['metamask/onGetMetaMaskAddress'];
      this.$store.dispatch('gateway/onProfile',body)
    },


    uploadImageLogo(e) {
      this.imageLogo = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.urlLogo = e.target.result;
      };
    },

    uploadImageFeatured(e) {
      this.imageFeatured = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.urlFeatured = e.target.result;
      };
    },

    uploadImageBanner(e) {
      this.imageBanner = e.target.files[0];
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.urlBanner = e.target.result;
      };
    },
  },

  computed: {
    ...mapGetters({
      categorie: 'gateway/objCategorys',
      profile:'gateway/objProfile'
      // objMarketsById:'gateway/objMarketsById',
      // isApptoveBuyNft: "metamask/objisApptove",
    })
  },

  watch: {
    categorie(value) {

      var itemCategories = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {};
        obj.label = value[i].title;
        obj.value = value[i]._id.toString();
        itemCategories.push(obj)
      }
      // if(this.$route.params.categorie._id == undefined){
      //
      //   this.selectCategorie = value[0]._id;
      // }
      this.categories = itemCategories

    },

    profile(value){
      console.log(value)
    }
  }
}
</script>
