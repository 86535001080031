const en = {
  menu: {
    dashboard: 'Dashboards'
  },
  services: "รายละเอียดของรายวิชา ( มคอ )",
  campus: "ชื่อสถาบันอุดมศึกษา",
  facultys: "สำนักวิชา",
  departments: "สาขาวิชา",


  // btn
  tqf3:"สร้าง มคอ 3",
  tqf5:"สร้าง มคอ 5",

  tqf3Title:"รายละเอียด มคอ 3",
  tqf5Title:"รายละเอียด มคอ 5",




  section: "หมวดที่ {index} ข้อมูลทั่วไป",
  courseCode: "รหัสวิชา",
  courseTitle: "ชื่อรายวิชา",
  credit: "หน่วยกิต",

  programType: "หลักสูตรและประเภทของรายวิชา",
  typeProgram: "หลักสูตร",
  typeCourse: "ประเภทของรายวิชา",

  instructor: "อาจารย์ผู้ประสานงานรายวิชา อาจารย์ผู้รับผิดชอบรายวิชา และอาจารย์ผู้สอน",
  // table //
  tableInstructor: [
    {key: 'no', label: 'ลำดับ', _style: 'width:1%;text-align: center'},
    {key: 'fullname', label: 'ชื่อ-สกุล', _style: 'width:33%'},
    {key: 'instructor', label: 'อาจารย์ผู้ประสานงานรายวิชา', _style: 'width:13%; text-align: center'},
    {key: 'codication', label: 'อาจารย์ผู้รับผิดชอบรายวิชา', _style: 'width:13%; text-align: center'},
    {key: 'codication', label: 'อาจารย์ผู้สอน', _style: 'width:13%; text-align: center'},
    {key: '#', label: "#", _style: 'width:2%; text-align: center'}
  ],

  semeterStudent: "ภาคการศึกษา / ชั้นปีที่เรียน",
  academicYear: "ปีการศึกษา",
  semester: "ภาคการศึกษา",
  yearStudy: "ชั้นปีที่",
  preRequisite: "รายวิชาที่ต้องเรียนมาก่อน (ถ้ามี)",
  coRequisite: "รายวิชาที่ต้องเรียนพร้อมกัน (ถ้ามี)",


  courseSyllabus: "หมวดที่ {index} ลักษณะและการดำเนินการ",

  development: "หมวดที่ {index} การพัฒนาผลการเรียนรู้ของนักศึกษา",
  ethicsAndMorality: "Domains of Ethics and Morality",
  ethicsAndMoralitys: [
    {key: 'morality', label: 'Ethics and Morality',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  domainofAcademicKnowledge: "Domains of Ethics and Morality",
  domainofAcademicKnowledges: [
    {key: 'knowledge', label: 'Academic Knowledge',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  domainofCognitiveSkill: "Domain of Cognitive Skills",
  domainofCognitiveSkills: [
    {key: 'skills', label: 'Cognitive Skills',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  domainofInterpersonalSkill: "Domain of Interpersonal Skills and Responsibility",
  domainofInterpersonalSkills: [
    {key: 'ethics', label: 'Interpersonal Skills and Responsibility',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  domainofNumerical: "Domain of Interpersonal Skills and Responsibility",
  domainofNumericals: [
    {key: 'ethics', label: 'Interpersonal Skills and Responsibility',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  domainofPsychomotor: "Domain of Psychomotor Skills",
  domainofPsychomotors: [
    {key: 'ethics', label: 'Domain of Psychomotor Skills',_style:'width:33%'},
    {key: 'methodology', label: 'Teaching Methodology',_style:'width:33%'},
    {key: 'methods', label: 'Assessment Methods',_style:'width:33%'}
  ],
  remark: "Remark: In case there are more than five domains of learning outcomes, please specify additional domain.",

  lesson: "หมวดที่ {index} การพัฒนาผลการเรียนรู้ของนักศึกษา",
  references: "หมวดที่ {index} เอกสารอ้างอิง",
  evaluations: "หมวดที่ {index} การประเมิน",
  evaluation: "หมวดที่ {index} การประเมินและปรับปรุงการดำเนินการของวิชา",
  courseBetween: "หมวดที่ {index} การดำเนินการที่เปรียบเทียบกับแผนการเตรียมการ",
  summary: "หมวดที่ {index} ผลการดำเนินการ",
  improvement: "หมวดที่ {index} ผลการดำเนินการ",


  //

  // Reference
  reference:"Specify reading materials including textbooks, docments, suggested reading and or other resources (with standard referencing format)",
  sbn :"SBN",
  bookName :"BOOKNAME",

  //evaluation
  evaluationNote : "Please give more than one, if possible.",
  evaluationAssessment:"Assessment Strategy by Students",
  evaluationVerification:"Verification of Learning Outcomes",
  evaluationVerificationDetail:"To verify the constructive alignment model, it needs to consider Course Learning Outcome (CLOs) in terms of teaching method, assessment details (method and proportion, grading) and other teaching and learning problems.",
  evaluationInstructor: "Name of Course Instructor / Course Coordinator "

}


export default en

