import 'core-js/stable'
import Vue from 'vue'
import index from "./router/index";
//import CoreuiVuePro from '@coreui/vue-pro'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
// import store from './store'
import i18n from './i18n.js'
import store from "@/store/store";
import moment from 'moment'
Vue.prototype.moment = moment


Vue.config.devtools = false
Vue.config.debug = false
Vue.config.silent = false
Vue.config.productionTip = false

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

import IdleVue from 'idle-vue'
const eventsHub = new Vue();


import VueMeta from 'vue-meta'
Vue.use(VueMeta)


import VueHead from 'vue-head'
Vue.use(VueHead)

const host = window.location.host;
console.log("host",host)
// if(host != "localhost:8080"){
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 10000,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
  })

  new Vue({
    el: '#app',
    router,
    store,
    //CIcon component documentation: https://coreui.io/vue/docs/components/icon
    icons,
    i18n,
    template: '<App/>',
    components: {
      App
    }
  })

// }else{
//   index.
// }


