<template>
  <div>
    <CModal

      :show.sync="disable"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      color="success"
      size="lg"

    >

      <template #header-wrapper>
        <div></div>
<!--        <h6 class="modal-title">{{ (update == true)?'UPDATE ITEM':'CREATE ITEM'}}</h6>-->
<!--        <CButtonClose @click="onUpdatePlan" class="text-white"/>-->
      </template>

      <template #body-wrapper>
        <div class="p-2 pl-4 pr-4">

          <CRow class="mt-3 mb-3">
            <CCol>
              <CButton @click="onSetPrice(1)" :pressed="fixprice == true" size="md" color="success" class="text-white w-100" variant="outline" >
                Set Price<br>
                Sell at a fixed or declining price
              </CButton>
            </CCol>
            <CCol>
              <CButton @click="onSetPrice(2)" :pressed="biding == true" size="md" color="success" class="text-white w-100" variant="outline" >
                Highest Bid<br>
                Auction to the highest bidder
              </CButton>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="7">
              <div v-if="fixprice">
                <CRow class="mt-3">
                  <CCol>
                    <CInput
                        label="Price"
                        type="number"
                        min="0"
                        :horizontal="{label: 'col-sm-3', input: 'col-sm-9 text-right'}"
                        v-model="minimunBid">
                      <template #append>
                        <CButton :disabled="false"  size="md" color="success" class="text-white pl-4" >BNB</CButton>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol>
                    Will be on sale until you transfer this item or cancel it. Note: ㆔0.00 is the average price of Animals in literature this week (0.0% compared to last week).
                  </CCol>
                </CRow>
              </div>
              <div v-if="biding">
                <CRow class="mt-3">
                  <CCol>
                    <CInput
                        label="Minimum Bid"
                        type="number"
                        min="0"
                        :horizontal="{label: 'col-sm-3', input: 'col-sm-9 text-right'}"
                        v-model="minimunBid">

                      <template #append>
                        <CButton :disabled="false"  size="md" color="success" class="text-white" >WBNB</CButton>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    Set your starting bid price.
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol>
                    <CInput
                        label="Reserve Price"
                        type="number"
                        min="0"
                        :horizontal="{label: 'col-sm-3', input: 'col-sm-9 text-right'}"
                        v-model="reservePrice">
                      <template #append>
                        <CButton :disabled="false"  size="md" color="success" class="text-white" >WBNB</CButton>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol>
                    Create a hidden limit by setting a reserve price.
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol>
                    <CInput
                        label="Exprice Market"
                        type="datetime-local"
                        :horizontal="{label: 'col-sm-3', input: 'col-sm-9 text-right'}"
                        v-model="expiresAt"/>
                  </CCol>
                </CRow>
                <CRow class="mb-2">
                  <CCol>
                    Your auction will automatically end at this time and the highest bidder will win. No need to cancel it!
                  </CCol>
                </CRow>
              </div>
            </CCol>
            <CCol>
              <CCard>
                <CCardHeader>
                  <CRow>
                    <CCol>
                      <CIcon  size="lg" name="cil-description" class="text-white mr-3"/>  DESCRIPTION
                    </CCol>
                  </CRow>
                </CCardHeader>
                <CCardBody>
                  <CRow class="mb-3">
                    <CCol md="6" class="mb-2">
                      <CButton @click="onCancel" class="w-100 text-white" size="md" color="secondary" > CANCEL </CButton>
                    </CCol>
                    <CCol md="6">
                      <CButton @click="onListing" class="w-100 text-white" size="md" color="success" > LISTING </CButton>
                    </CCol>
                  </CRow>

                  <CRow class="mb-2">
                    <CCol>
                      <span class="font-weight-bold">Fees</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <span>Listing is free! At the time of the sale, the following fees will be deducted.</span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol class="font-weight-bold">To nextart</CCol>
                    <CCol class="text-right">2.5 %</CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol class="font-weight-bold">To {{ collections.name }}</CCol>
                    <CCol class="text-right">{{collections.royalties.percentage}} %</CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol class="font-weight-bold">Total</CCol>
                    <CCol class="text-right">{{parseFloat(collections.royalties.percentage)+parseFloat(2.5)}} %</CCol>
                  </CRow>
                  <CRow class="mt-2">
                    <CCol>
                      <CButton @click="onCancelListPrice" class="w-100 text-white" size="md" color="danger" > CANCEL LISTING</CButton>
                    </CCol>
                  </CRow>

                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </template>

      <template #footer-wrapper>
        <div></div>
<!--        <CButton size="md" color="light" >CANCEL</CButton>-->
<!--        <CButton @click="onCreate" class="mr-3 text-white" size="md" color="success" >{{ (update == true)?'UPDATE':'CREATE'}}</CButton>-->
<!--        <CButton v-if="update" class="ml-3" size="md" color="danger" >DELETE</CButton>-->
      </template>
    </CModal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import CreateItem from "@/project/views/page/sub/CreateItem";
import {address721} from "@/store/modules/Metamask/constants/escrowContract";

Vue.use(VueQuillEditor)

// import QuillBetterTable from 'quill-better-table'
// Quill.register({
//   'modules/better-table': QuillBetterTable
// }, true)
export default {
  name: 'SellPrice',
  components: {CreateItem},
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    onwer: {
      type: String,
      default: ""
    },
    collections: {
      type: Object,
      default: {}
    },
    info: {
      type: Object,
      default: {}
    }
  },
  data: function () {
    return {
      darkModal: true,
      edit:false,
      url: "img/upload/upload.png",

      name:"",
      description:"",

      fixprice:true,
      biding:false,
      minimunBid:0,
      reservePrice:0,
      expiresAt:0,
      dateTime:0


    }
  },
  mounted() {

  },

  created() {
    // this.content = this.onCheck(this.values.fields)

    try {
      if(this.info.index != null){
        this.edit = true
        this.data.domain = this.info.item.domain
        this.data.method = this.info.item.method
        this.data.assessor = this.info.item.assessor
        this.data.week = this.info.item.week
        this.data.proportion = this.info.item.proportion
      }

    }catch (e) {

    }
  },

  beforeDestroy() {

  },

  methods: {

    onSetPrice(value){
      switch (value) {
        case 1:
          this.fixprice = true;
          this.biding = false;
          break;
        case 2:
          this.fixprice = false;
          this.biding = true;
          break;

      }
    },

    onListing(){

      var item = this.collections.item.filter((item) => {

        if(item.nfts._id == this.$route.query.assets ){
          return item
        }
      });
      // this.nfts = item[0].nfts;



      var obj = {};
      obj.id = this.$route.query.assets;
      obj.onwer = item[0].nfts.onwer._id;
      if(this.fixprice == true){
        obj.bidding = false;
        obj.price = this.minimunBid;
        obj.reservePrice = 0;
        obj.expire = 0;

      }else{
        obj.bidding = true;
        obj.price = this.minimunBid;
        obj.reservePrice = this.reservePrice;
        obj.expire = this.expiresAt;
      }

      this.$store.dispatch('gateway/onNFTListPriceById', obj);
      this.onCancel();

    },

    onCancelListPrice(){

      var obj = {};
      obj.id = this.$route.query.assets;

      this.$store.dispatch('gateway/onNFTCancelListPriceById', obj);
      this.onCancel();
    },

    onCancel(){
      this.disable = false;
      this.$emit("update:value",false)
    },


    onUpdatePlan() {
      this.disable = false;
      this.$emit("update:value",false)
    },

    onUpdatePlanSubmit() {

      if(this.edit != true){
        this.$emit("onCreate",this.data)
      }else{

        this.info.item = this.data
        this.$emit("onUpdate",this.info)

      }

    },

  },


  computed: {
    ...mapGetters({
      lang: "setting/objLang",
      // stateDialog: "dialogCreate/onGetDialogLessonPlan",
      // editinfo : "dialogCreate/onGetDialogLessonPlanEdit"
    })
  },

  watch: {
    // expiresAt(value) {
    //   // var date = new Date(value).getTime()/1000
    //   this.dateTime = new Date(value).getTime() / 1000;
    // },
    // editinfo(value){
    //   console.log("value",value)
    //   if(value != null){
    //     this.edit = true
    //     this.data = value.item
    //
    //
    //   }
    //
    // },
    // 'reserve.topics'(value){
    //   this.onCheckIndex(this.data.topics,value)
    // }
  }
}
</script>
