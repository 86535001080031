const en = {
  menu: {
    dashboard: 'Dashboards'
  },
  services: "รายละเอียดของรายวิชา ( มคอ )",
  campus: "ชื่อสถาบันอุดมศึกษา",
  facultys: "สำนักวิชา",
  departments: "สาขาวิชา",


  // btn
  tqf3:"สร้าง มคอ 3",
  tqf5:"สร้าง มคอ 5",

  //

  tqf3Title:"รายละเอียด มคอ 3",
  tqf5Title:"รายละเอียด มคอ 5",




  section: "หมวดที่ {index} ข้อมูลทั่วไป",
  courseCode: "รหัสวิชา",
  courseTitle: "ชื่อรายวิชา",
  credit: "หน่วยกิต",

  programType: "หลักสูตรและประเภทของรายวิชา",
  typeProgram: "หลักสูตร",
  typeCourse: "ประเภทของรายวิชา",

  instructor: "อาจารย์ผู้ประสานงานรายวิชา อาจารย์ผู้รับผิดชอบรายวิชา และอาจารย์ผู้สอน",
  // table //
  tableInstructor: [
    {key: 'no', label: 'ลำดับ', _style: 'width:1%;text-align: center'},
    {key: 'fullname', label: 'ชื่อ-สกุล', _style: 'width:33%'},
    {key: 'instructor', label: 'อาจารย์ผู้ประสานงานรายวิชา', _style: 'width:13%; text-align: center'},
    {key: 'ืยท', label: 'อาจารย์ผู้รับผิดชอบรายวิชา', _style: 'width:13%; text-align: center'},
    {key: 'codication', label: 'อาจารย์ผู้สอน', _style: 'width:13%; text-align: center'},
    {key: '#', label: "#", _style: 'width:2%; text-align: center'}
  ],

  semeterStudent: "ภาคการศึกษา / ชั้นปีที่เรียน",
  academicYear: "ปีการศึกษา",
  semester: "ภาคการศึกษา",
  yearStudy: "ชั้นปีที่",
  preRequisite: "รายวิชาที่ต้องเรียนมาก่อน (ถ้ามี)",
  coRequisite: "รายวิชาที่ต้องเรียนพร้อมกัน (ถ้ามี)",


  courseSyllabus: "หมวดที่ {index} ลักษณะและการดำเนินการ",

  description: "คำอธิบายรายวิชา",
  objectives:"วัตถุประสงค์ของรายวิชา",
  reason: "เหตุผลในการพัฒนา / ปรับปรุงรายวิชา (ถ้ามี)",
  hourse: {
    title:"จำนวนชั่วโมงที่ใช้ต่อภาคการศึกษา",
    lecture:"ทฤษฎี",
    lab: "การฝึกปฎิบัติ",
    selfstudy: "ศึกษาด้วยตนเอง",
  },

  development: "หมวดที่ {index} การพัฒนาผลการเรียนรู้ของนักศึกษา",

  ethicsAndMorality: "ทักษะด้านคุณธรรม จริยธรรม",
  ethicsAndMoralitys: [
    {key: 'morality', label: 'คุณธรรม จริยธรรม',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],
  domainofAcademicKnowledge: "ความรู้",
  domainofAcademicKnowledges: [
    {key: 'knowledge', label: 'ความรู้',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],
  domainofCognitiveSkill: "ทักษะทางปัญญา",
  domainofCognitiveSkills: [
    {key: 'skills', label: 'ทักษะทางปัญญา',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],

  domainofInterpersonalSkill: "ทักษะความสัมพันธ์ระหว่างบุคคลและความรับผิดชอบ",
  domainofInterpersonalSkills: [
    {key: 'ethics', label: 'ทักษะความสัมพันธ์ระหว่างบุคคลและความรับผิดชอบ',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],

  domainofNumerical: "ทักษะความสัมพันธ์ระหว่างบุคคลและความรับผิดชอบ",
  domainofNumericals: [
    {key: 'ethics', label: 'ทักษะการวิเคราะห์เชิงตัวเลข',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],

  domainofPsychomotor:"ทักษะการปฎิบัติ",
  domainofPsychomotors: [
    {key: 'ethics', label: 'ทักษะการปฎิบัติ',_style:'width:33%'},
    {key: 'methodology', label: 'วิธีการสอน',_style:'width:33%'},
    {key: 'methods', label: 'วิธีการประเมินผล',_style:'width:33%'}
  ],
  remark: "หมายเหตุ : กรณีที่หลักสูตรใดที่มีผลการเรียนรู้มากกว่า 5 ด้านให้หลักสูตรนั้นกำหนดเพิ่มเติมได้",





  lesson: "หมวดที่ {index} การพัฒนาผลการเรียนรู้ของนักศึกษา",
  references: "หมวดที่ {index} เอกสารอ้างอิง",
  evaluations: "หมวดที่ {index} การประเมิน",
  evaluation: "หมวดที่ {index} การประเมินและปรับปรุงการดำเนินการของวิชา",
  courseBetween: "หมวดที่ {index} การดำเนินการที่เปรียบเทียบกับแผนการเตรียมการ",
  summary: "หมวดที่ {index} ผลการดำเนินการ",
  improvement: "หมวดที่ {index} ผลการดำเนินการ",


  //
  defualt:{
    semester:[
      {
        label:"ภาคเรียน 1",
        value:1
      },{
        label:"ภาคเรียน 2",
        value:2
      },{
        label:"ภาคเรียน 3",
        value:3
      }
    ],
    yearStudy:[
      {
        label:"ชั้นปีที่ 1",
        value:1
      },
      {
        label:"ชั้นปีที่ 2",
        value:2
      },
      {
        label:"ชั้นปีที่ 3",
        value:3
      },
      {
        label:"ชั้นปีที่ 4",
        value:4
      },
      {
        label:"ชั้นปีที่ 5",
        value:5
      },
      {
        label:"ชั้นปีที่ 6",
        value:6
      },
      {
        label:"ชั้นปีที่ 7",
        value:7
      },
      {
        label:"ชั้นปีที่ 8",
        value:8
      }
    ]
  },

  // search
  lessonPlan:"รหัสพนักงาน",
  firstName: "ชื่อ",
  lastName: "นามสกุล",

  // action //
  search: "ค้นหา",
  save:"บันทึก",
  cancel:"ยกเลิก",
  //end action //


  // Reference
  reference:"ระบุตำราเอกสารหลักที่ใช้ในการเรียนการสอน และ หรือ ตำราเอกสารสำคัญที่แนะนำให้นักศึกษาไปอ่านเพิ่มเติม (เขียนตามระบบ)",
  sbn :"รหัส",
  bookName :"ชื่อหนังสือ",

  //evaluation
  evaluationNote : "**สามารถระบุได้มากกว่า 1 ข้อ **",
  evaluationAssessment:"กลยุทธ์การประเมินประสิทธิผลโดยนักศึกษา",
  evaluationVerification:"กระบวนการทวนสอบผลสัมฤทธิ์การเรียนรู้/ การทวนสอบการประเมินผลการเรียนรู้",
  evaluationVerificationDetail:"(ทวนสอบในรูปแบบ Constructive alignment โดยพิจารณาแต่ละผลลัพธ์การเรียนรู้ระดับรายวิชา (Course Learning Outcome) สอนอย่างไร ประเมินอย่างไร สัดส่วนการประเมิน การกระจายเกรด การออกข้อสอบ         ที่สอดคล้องกับ ผลลัพธ์การเรียนรู้ระดับรายวิชาที่กำหนด ตลอดจนประเด็นปัญหาต่าง ๆ ที่อาจพบในกระบวน การเรียนการสอน) ",
  evaluationInstructor:"ชื่ออาจารย์ผู้สอน หรืออาจารย์ผู้ประสานงานรายวิชา / อาจารย์ผู้รับผิดชอบรายวิชา",
}


export default en

