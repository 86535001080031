import Vue from "vue";
import Vuex from "vuex";
import Service from "@/service/api.js";
import router from "../../../router/index.js";
import store from "@/store/store";

const LoginModule = {
  namespaced: true,
  state: {
    calendars: [],
  },

  mutations: {
    setCalendars(state, obj) {
      state.calendars = obj;
    },

  },

  actions: {
    onGetCalendars({commit}) {
      Service.calendarEducation()
        .then((response) => {
          var obj = response.data.data
          commit("setCalendars", obj)
        }).catch((err) => {
          store.dispatch("dialog/onHandleError", err)
        });
    },

    onCreateCalendars({commit}, body) {
      Service.onCalendarEducation(body)
        .then((response) => {
          // var obj = response.data.data
          // commit("setCalendars", obj)
          store.dispatch("calendars/onGetCalendars")

        })
        .catch((err) => {
          store.dispatch("dialog/onHandleError", err)
        });
    },

  },

  getters: {
    objCalendars(state) {
      return state.calendars;
    },
  },
};

export default LoginModule;
