import axios from 'axios';
import store from '@/store/store'
const instance = axios.create();


// instance.defaults.baseURL = 'https://api.example.com';
// instance.defaults.baseURL = 'https://svq.mfu.ac.th/api/v1';
instance.defaults.baseURL = 'https://nextart.app/api/v1';
// instance.defaults.baseURL = 'http://127.0.0.1:8082/api/v1';
instance.defaults.timeout = 5000;

const cryptoPrice = axios.create();
cryptoPrice.defaults.baseURL = 'https://api.binance.com/api/v3';
cryptoPrice.defaults.timeout = 5000;



instance.defaults.headers = {
  "Content-Type": "application/json",
  "company":"6080ec9480bcf039799aca39",
  // "publickey":store.state.xid
  // "api-key": "12345678900987654321",
  // "api-version": "1.0"
}


export default {
  // CONFIG

  onSetToken(){
    instance.defaults.headers = {
      "Content-Type": "application/json",
      "company":"6080ec9480bcf039799aca39",
      // "api-key": "12345678900987654321",
      // "api-version": "1.0",
      // "Authorization": `Bearer ${store.state.token}`,
      "xid" : `${store.state.xid}`
    }

  },

  onGetProfile(body) {
    return instance.get("/profile/"+body.metamask)
  },
  onUpdateProfile(body) {
    return instance.put("/profile",body)
  },

  onUpdateProfileImage(body) {
    return instance.put("/profile/image",body)
  },

  onCategorys(body) {
    return instance.get("/categories")
  },

  onRegisterAddress(body) {
    return instance.post("/register",body)
  },

  onNFTsByTokenId(body) {
    return instance.get("/nft/token/"+body.tokenId)
  },



  onUpdateStatusNFTs(body) {
    return instance.put("/nft/change/status/"+body.id,body)
  },

  // nfts //

  onCreateNFTs(body) {
    return instance.post("/nfts",body)
  },
  onUpdateNFTs(body) {
    return instance.put("/nfts",body)
  },
  onDeleteNFTs(body) {
    return instance.delete("/nfts/"+body.id)
  },



  onNFTsByOnwer(body) {
    return instance.get("/nfts/onwer/"+body.onwer)
  },

  // end nfts //

  // market //

  onMarketsByCategorie(body){
    return instance.get("/markets/"+body.categorie)

  },

  onSearchMarketsByCategorie(body){
    // return instance.get("/market/categorie/"+body.categorie)
  },

  onMarketsByOnwer(body){
    return instance.get("/markets/onwer/"+body.onwer)

  },
  onMarketsById(body) {
    return instance.get("/market/"+body.id)
  },

  onMarketsCreate(body) {
    return instance.post("/market", body)
  },

  // end market //


  // collection //
  onCreateCollection(body) {
    return instance.post("/collection",body)
  },

  onUpdateCollection(body) {
    return instance.put("/collection",body)
  },


  onGetCollectionByConnect(body) {
    return instance.get("/collections/"+body.onwer)
  },

  onCollectionById(body){
    return instance.get("/collection/"+body.id)
  },

  onNfFLikeById(body){
    return instance.put("/nfts/"+body.id+"/like",body)
  },

  onGetNFTOfferById(body) {
    this.onSetToken();
    return instance.get("/nfts/"+body.id+"/offer/approve/"+body.offer)
  },

  onNfFOfferById(body){
    return instance.put("/nfts/"+body.id+"/offer",body)
  },
  onUnNFTOfferById(body){
    return instance.put("/nfts/"+body.id+"/unoffer",body)
  },

  onNFTListPriceById(body){
    this.onSetToken();
    return instance.put("/nfts/"+body.id+"/listprice",body)
  },

  onNFTCancelListPriceById(body){
    this.onSetToken();
    return instance.put("/nfts/"+body.id+"/cancel/listprice",body)
  },


  onCollectionByNfts(body){
    return instance.get("/nfts/"+body.id+"/collection",body)
  },

  onTranfarNFT(body){
    return instance.put("/nfts/"+body.id+"/onwer",body)
  },

  // nfts/:id/like
  // end collection //

  onPriceBNB(){
    return cryptoPrice.get("/ticker/price?symbol=BNBUSDT")
  }

}
